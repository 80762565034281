import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { uniqBy } from 'lodash';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
  type ResponderProvided,
} from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';

import { hideColumnCheck } from '../../../utils/table';
import { type TableConfigType } from '../../table/types';

import ColumnItem from './ColumnItem';

// we create a div for our portal. This is in order to render draggable items in a modal
const portal = document.createElement('div');
document.body.appendChild(portal);

const DEFAULT_TYPE = { type: 'DEFAULT' };

const SelectedColumns = ({
  customColumns,
  handleOnDragEndCallback,
  handleColumnSelect,
  activeColumns,
  columnLimit,
  selectedColumnsTitle,
  dragToReorder,
  getCustomColumnsSelectedLabel,
}: {
  customColumns?: TableConfigType<any>[];
  handleOnDragEndCallback: (
    result: DropResult,
    provided: ResponderProvided,
  ) => void;
  handleColumnSelect: (metricItemData: any) => void;
  activeColumns?: number;
  columnLimit?: number;
  selectedColumnsTitle: string;
  dragToReorder: string;
  getCustomColumnsSelectedLabel?: (
    activeColumns: number | undefined,
    columnLimit: number | undefined,
  ) => string;
}) => {
  const theme = useTheme();

  // we need to add an index as this is needed for the draggable component
  const customColumnsWithIndex =
    customColumns?.map((d, i) => ({ ...d, index: i })) || [];

  const droppableTypes = uniqBy(
    customColumnsWithIndex.map(
      ({ droppableType }) => droppableType || DEFAULT_TYPE,
    ),
    'type',
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Stack justifyContent="center" direction="row">
          <Typography variant="h4">{selectedColumnsTitle}</Typography>
          <Typography
            sx={{ ml: 'auto', color: theme.palette.grey[400] }}
            variant="subtitle1"
          >
            {`(${dragToReorder})`}
          </Typography>
        </Stack>
        {columnLimit && (
          <Typography variant="body1">
            {getCustomColumnsSelectedLabel
              ? getCustomColumnsSelectedLabel(activeColumns, columnLimit)
              : //TODO: remove english translation when all translation work is done
                `${activeColumns} outOf ${columnLimit} selected`}
          </Typography>
        )}
      </Box>
      <Box sx={{ width: '100%', overflow: 'scroll' }}>
        <DragDropContext onDragEnd={handleOnDragEndCallback}>
          {droppableTypes.map(({ type }, i) => (
            <React.Fragment key={type}>
              <Box sx={{ width: '100%' }}>
                <Droppable droppableId={type} type={type}>
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        className={type}
                        {...provided.droppableProps}
                      >
                        {(customColumnsWithIndex || [])
                          .filter((d) => !hideColumnCheck(d))
                          .filter(
                            (d) =>
                              (d?.droppableType?.type || DEFAULT_TYPE.type) ===
                              type,
                          )
                          .map((d) => {
                            return (
                              <Draggable
                                key={d.header}
                                draggableId={d.header || ''}
                                index={d.index}
                              >
                                {(provided, snapshot) => {
                                  // destructure header and active
                                  const { header, active, locked } = d;

                                  // if dragging - use portal
                                  const usePortal = snapshot.isDragging;

                                  // column item
                                  const child = (
                                    <ColumnItem
                                      header={header}
                                      disabled={locked}
                                      handleColumnSelect={handleColumnSelect}
                                      provided={provided}
                                      columnData={d}
                                    />
                                  );

                                  // if active we render an empty div, including the draggable props to preserve the expected draggable order
                                  if (!active) {
                                    return (
                                      <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      />
                                    );
                                  }

                                  // if not dragging - just return the item
                                  if (!usePortal) {
                                    return child;
                                  }

                                  // if dragging - put the item in a portal
                                  return createPortal(child, portal);
                                }}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </Box>
              {droppableTypes.length !== i + 1 && <Divider sx={{ my: 2 }} />}
            </React.Fragment>
          ))}
        </DragDropContext>
      </Box>
    </>
  );
};

export default SelectedColumns;
