import makeStyles from '@mui/styles/makeStyles';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import React, { Fragment, Suspense, useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import LoadingScreen from './loading-screen/LoadingScreen';

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
      },
    },
  },
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return <Route {...props} />;
}

const AppRoutes = ({ routes }) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, idx) => {
        const Component = route.component;
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;

        return (
          <RouteProgress
            key={`routes-${idx}`}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    <AppRoutes routes={route.routes} />
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

AppRoutes.propTypes = {
  routes: PropTypes.array,
};

export default AppRoutes;
