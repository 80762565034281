import { atom, useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';

import useUrlParams from '../../hooks/useUrlParams';
import {
  type AdvancedFiltersStorage,
  getSessionStorageAtom,
} from '../../utils/storage/session-storage-atom';

import {
  type AdvancedFiltersProps,
  type FiltersProps,
  type GeneralFiltersProps,
} from './Filters.types';

const empty = atom<AdvancedFiltersStorage | null>(null);

export const useGetGeneralFiltersCount = (
  filters?: GeneralFiltersProps['filters'] | null,
) => {
  const [searchParams, searchParamsBuilder] = useUrlParams();
  const selectedFilters = useMemo(
    () => searchParamsBuilder.getFilters(),
    [searchParams],
  );

  const totalGeneralFilters = Object.keys(selectedFilters || {}).reduce(
    (acc, key) => {
      if (
        !selectedFilters ||
        !filters ||
        !filters.some((filter) => filter.dimension_id === key)
      ) {
        return acc;
      }

      const selection = selectedFilters[key];
      return (acc += selection.length);
    },
    0,
  );

  return totalGeneralFilters;
};

export const useGetAdvancedFiltersCount = (
  storageKey?: AdvancedFiltersProps['storageKey'],
) => {
  const advancedFiltersAtom = useMemo(
    () => (storageKey ? getSessionStorageAtom(storageKey) : empty),
    [storageKey],
  );
  const advancedFiltersStorage = useAtomValue(advancedFiltersAtom);
  const totalAdvancedFilters = advancedFiltersStorage?.conditions.length || 0;

  return totalAdvancedFilters;
};

export const useGetTotalFiltersCount = ({
  filters,
  advancedFiltersStorageKey,
}: {
  filters: FiltersProps['filters'];
  advancedFiltersStorageKey: FiltersProps['advancedFiltersStorageKey'];
}) => {
  const totalGeneralFilters = useGetGeneralFiltersCount(filters);
  const totalAdvancedFilters = useGetAdvancedFiltersCount(
    advancedFiltersStorageKey,
  );

  return totalAdvancedFilters + totalGeneralFilters;
};

export const useAdvancedFiltersAtom = (
  storageKey?: AdvancedFiltersProps['storageKey'],
) => {
  const advancedFiltersAtom = useMemo(
    () => (storageKey ? getSessionStorageAtom(storageKey) : empty),
    [storageKey],
  );

  const [advancedFiltersStorage, updateAdvancedFiltersStorage] =
    useAtom(advancedFiltersAtom);

  return [advancedFiltersStorage, updateAdvancedFiltersStorage] as const;
};

export const useAdvancedFiltersValue = (
  storageKey: AdvancedFiltersProps['storageKey'],
) => {
  const [advancedFiltersStorage] = useAdvancedFiltersAtom(storageKey);
  return advancedFiltersStorage;
};
