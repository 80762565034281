import { useAutoneTranslation } from '@autone/translations';
import { Trash } from 'lucide-react';
import { useRef } from 'react';

import {
  TailwindButton,
  TailwindNumberInput,
  TailwindSelect,
  TailwindSelectContent,
  TailwindSelectItem,
  TailwindSelectTrigger,
  TailwindSelectValue,
} from '../../../tailwind';

import {
  useAdvancedFiltersContext,
  useAdvancedFiltersOptionsContext,
} from './AdvancedFilters.context';

interface AdvancedFiltersConditionCombineModeProps {
  rowIdx: number;
}

export const AdvancedFiltersConditionCombineMode = ({
  rowIdx,
}: AdvancedFiltersConditionCombineModeProps) => {
  const { common } = useAutoneTranslation();
  const [state, dispatch] = useAdvancedFiltersContext();
  const { conditionCombineMode } = state;

  if (rowIdx === 0) {
    return common('advanced-filters.where');
  } else if (rowIdx === 1) {
    return (
      <TailwindSelect
        value={conditionCombineMode}
        onValueChange={(val: 'and' | 'or') =>
          dispatch({ type: 'CHANGE_COMBINE_MODE', payload: { condition: val } })
        }
      >
        <TailwindSelectTrigger>
          <TailwindSelectValue />
        </TailwindSelectTrigger>
        <TailwindSelectContent>
          <TailwindSelectItem value="and">
            {common('advanced-filters.and')}
          </TailwindSelectItem>
          <TailwindSelectItem value="or">
            {common('advanced-filters.or')}
          </TailwindSelectItem>
        </TailwindSelectContent>
      </TailwindSelect>
    );
  } else {
    return conditionCombineMode === 'and'
      ? common('advanced-filters.and')
      : common('advanced-filters.or');
  }
};

interface AdvancedFiltersConditionRowProps {
  rowId: string;
}

export const AdvancedFiltersConditionRow = ({
  rowId,
}: AdvancedFiltersConditionRowProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { common } = useAutoneTranslation();
  const { columns, conditions } = useAdvancedFiltersOptionsContext();
  const [state, dispatch] = useAdvancedFiltersContext();
  const condition = state.conditions[rowId];

  const { filterId, filterConditionId, value } = condition;

  const handleUpdateColumn = (val: string) => {
    dispatch({
      type: 'PATCH_CONDITION',
      payload: {
        rowId,
        condition: {
          filterId: val,
        },
      },
    });
  };

  const handleUpdateColumnCondition = (val: string) => {
    dispatch({
      type: 'PATCH_CONDITION',
      payload: {
        rowId,
        condition: {
          filterConditionId: val,
        },
      },
    });
  };

  const handleUpdateValue = (val?: number) => {
    dispatch({
      type: 'PATCH_CONDITION',
      payload: {
        rowId,
        condition: {
          value: val,
        },
      },
    });
  };

  // render booleans
  const shouldAllowRemove = Object.keys(state.conditions).length > 1;

  return (
    <div className="flex flex-row gap-2 w-full" ref={containerRef}>
      {/* column selection */}
      <TailwindSelect value={filterId} onValueChange={handleUpdateColumn}>
        <TailwindSelectTrigger>
          <TailwindSelectValue
            placeholder={common('advanced-filters.column')}
          />
        </TailwindSelectTrigger>
        <TailwindSelectContent
          container={containerRef.current}
          data-testid="advanced-filters-column-select-content"
        >
          {columns.map((column) => (
            <TailwindSelectItem key={column.id} value={column.id}>
              {column.translatedDisplayName}
            </TailwindSelectItem>
          ))}
        </TailwindSelectContent>
      </TailwindSelect>

      {/* column condition selection */}
      <TailwindSelect
        value={filterConditionId}
        onValueChange={handleUpdateColumnCondition}
      >
        <TailwindSelectTrigger>
          <TailwindSelectValue
            placeholder={common('advanced-filters.condition')}
          />
        </TailwindSelectTrigger>
        <TailwindSelectContent
          container={containerRef.current}
          data-testid="advanced-filters-condition-select-content"
        >
          {conditions.map((condition) => (
            <TailwindSelectItem key={condition.id} value={condition.id}>
              {condition.translatedDisplayName}
            </TailwindSelectItem>
          ))}
        </TailwindSelectContent>
      </TailwindSelect>

      {/* value input */}
      <TailwindNumberInput
        className="p-2"
        placeholder={common('advanced-filters.value')}
        value={value ?? null}
        onChange={(val) => handleUpdateValue(val ?? undefined)}
      />
      {shouldAllowRemove && (
        <TailwindButton
          data-testid={`${rowId}-advanced-filter-remove-button`}
          size="icon"
          variant="ghost"
          color="error"
          className="self-stretch aspect-square rounded-full"
          onClick={() =>
            dispatch({ type: 'REMOVE_CONDITION', payload: { rowId } })
          }
        >
          <Trash size={18} />
        </TailwindButton>
      )}
    </div>
  );
};
