import { Stack, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { generateFilterTitle } from '../../utils';

export default function Panel({ label, children, sx }) {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="stretch"
      sx={{
        m: 0,
        borderRadius: 1,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        ...sx,
      }}
    >
      <Stack
        component="span"
        direction="row"
        alignItems="center"
        sx={{
          px: 1,
          typography: 'subtitle1',
          color: 'text.secondary',
          bgcolor: 'background.neutral',
          borderTopLeftRadius: `${theme.shape.borderRadius}px`,
          borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
          borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        {generateFilterTitle(label)}
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ p: 0.2 }}>
        {children}
      </Stack>
    </Stack>
  );
}

Panel.propTypes = {
  sx: PropTypes.object,
  label: PropTypes.string,
  children: PropTypes.node,
};
