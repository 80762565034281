import { Box, type SxProps, Typography } from '@mui/material';

const NoResultsMessage = ({
  message = 'No results',
  sx,
}: {
  message?: string;
  sx?: SxProps;
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Typography
        sx={{
          color: 'grey.500',
          mt: 2,
          mb: 3,
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default NoResultsMessage;
