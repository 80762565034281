import { type FilterFieldWithSku } from '@autone/openapi-replenishment';
import { useAutoneTranslation } from '@autone/translations';
import { Box, Slide, Stack } from '@mui/material';
import { type FC, forwardRef, useEffect, useRef, useState } from 'react';

import { getDimensionTitle } from '../../../utils';
import { type GeneralFiltersProps } from '../Filters.types';

import DimensionList from './DimensionList/DimensionList';
import DimensionItem from './DimensionList/DimentionItem';
import useGeneralFilters from './useGeneralFilters';

type Props = Pick<GeneralFiltersProps, 'filters' | 'onFilterUpdate' | 'labels'>;

const BottomBlur = forwardRef<HTMLDivElement, Record<string, unknown>>(
  (_, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '30px',
          bottom: 0,
          left: 0,
          background:
            'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
        }}
      />
    );
  },
);

const GeneralFiltersContent: FC<Props> = ({
  filters,
  onFilterUpdate,
  labels,
}) => {
  const { common } = useAutoneTranslation();
  const {
    selectedFilters,
    currentDimension,
    setCurrentDimension,
    selectedCurrentDimensionFilters,
    currentDimensionFilters,
    filterList,
    handleOnFilterClick,
    handleOnSelectAllClick,
  } = useGeneralFilters({ filters, onFilterUpdate });

  const [isAtBottomList, setIsAtBottomList] = useState(false);
  const dimenstionListRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsAtBottomList(entry.isIntersecting);
        });
      },
      { threshold: 0.95 },
    );

    if (lastItemRef.current) {
      observer.observe(lastItemRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={'flex'}>
      <Box
        ref={dimenstionListRef}
        px={3}
        width="360px"
        borderRight="1px solid"
        borderColor="grey.300"
        sx={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Stack
          height="289px"
          py={2}
          gap={1}
          sx={{
            overflowY: 'auto',
          }}
        >
          {filterList.map((filter, idx) => (
            <DimensionItem
              ref={idx === filterList.length - 1 ? lastItemRef : null}
              key={filter.dimension_id}
              name={getDimensionTitle(
                filter.dimension_id as FilterFieldWithSku,
                common,
              )}
              count={selectedFilters?.[filter.dimension_id]?.length || 0}
              isActive={currentDimension === filter.dimension_id}
              handleOnClick={() => setCurrentDimension(filter.dimension_id)}
            />
          ))}
        </Stack>
        <Slide
          direction="up"
          in={!isAtBottomList}
          container={dimenstionListRef.current}
          timeout={{
            exit: 500,
          }}
        >
          <BottomBlur />
        </Slide>
      </Box>
      <DimensionList
        key={currentDimension}
        id={currentDimension}
        options={currentDimensionFilters.options}
        selectedFilters={selectedCurrentDimensionFilters}
        handleOnSelectAll={handleOnSelectAllClick}
        handleOnSelectFilter={handleOnFilterClick}
        labels={{
          clearAll: labels.clearAll,
          selectAll: labels.selectAll,
          searchPlaceholder: labels.searchPlaceholder,
        }}
      />
    </div>
  );
};

export default GeneralFiltersContent;
