// ----------------------------------------------------------------------

const newThemeColor = 'secondary';

function buildGhostButton(theme, color) {
  let primaryPaletteVariant = 100;
  let secondaryPaletteVariant = 700;

  if (color === 'grey') {
    secondaryPaletteVariant = 500;
  }

  if (color === 'purple') {
    secondaryPaletteVariant = 900;
  }

  if (color === newThemeColor) {
    primaryPaletteVariant = 10;
    secondaryPaletteVariant = 120;
  }

  const palette = color === newThemeColor ? theme.palette.new : theme.palette;

  return {
    props: { variant: 'ghost', color },
    style: {
      textTransform: 'none',
      backgroundColor: palette[color][primaryPaletteVariant],
      borderRadius: theme.spacing(0.5),
      fontWeight: 600,
      color: palette[color][secondaryPaletteVariant],
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      '&:not(.active):hover': {
        backgroundColor: palette[color][primaryPaletteVariant],
        color: palette[color][secondaryPaletteVariant],
      },
      '&.active, &.active:hover': {
        color: '#fff',
        backgroundColor: palette[color][secondaryPaletteVariant],
      },
      '&:focus-visible': {
        outlineOffset: '2px',
        outline: `2px solid ${palette[color][secondaryPaletteVariant]}`,
      },
    },
  };
}

export default function Button(theme) {
  return {
    MuiButton: {
      variants: [
        buildGhostButton(theme, 'primary'),
        buildGhostButton(theme, newThemeColor),
        buildGhostButton(theme, 'grey'),
        buildGhostButton(theme, 'warning'),
        buildGhostButton(theme, 'success'),
        buildGhostButton(theme, 'purple'),
        buildGhostButton(theme, 'error'),
      ],
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          fontSize: theme.typography.body1.fontSize,
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.z8,
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
