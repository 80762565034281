import { createContext, useContext } from 'react';

import { type GeneralFiltersContextValue } from './types';

export const GeneralFiltersContext =
  createContext<GeneralFiltersContextValue | null>(null);

export const useGeneralFiltersContext = () => {
  const context = useContext(GeneralFiltersContext);
  if (!context) {
    throw new Error(
      'useGeneralFiltersContext must be used within a GeneralFiltersContextProvider',
    );
  }
  return context;
};
