import { capitalizeFirstLetter } from '@autone/utils';
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CardItem from './CardItem';

const CardItemWrapper = ({
  dataItem,
  cardIdx,
  config,
  align = 'left',
  groupingKey,
  // rest props used to enable and custom function to be passed from the app level
  ...rest
}) => {
  // state for tracking whether the row is hovered. is only set if hover prop is passed
  const [hoveredRow, setHoveredRow] = useState();

  return (
    <>
      {dataItem && (
        <Stack
          align={align}
          sx={{ py: 0.5 }}
          spacing={2}
          onMouseLeave={() => setHoveredRow()}
          onMouseEnter={() => setHoveredRow({ rowNum: cardIdx })}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {Array.isArray(config) ? (
              <>
                <Box>{capitalizeFirstLetter(groupingKey)}</Box>
                {config.map((configItem, idx) => (
                  <CardItem
                    idx={idx}
                    cardIdx={cardIdx}
                    key={configItem.dataKey}
                    hoveredRow={hoveredRow}
                    dataItem={dataItem}
                    config={configItem}
                    {...rest}
                  />
                ))}
              </>
            ) : (
              <>
                <Box>{capitalizeFirstLetter(config?.header)}</Box>
                <CardItem
                  cardIdx={cardIdx}
                  hoveredRow={hoveredRow}
                  dataItem={dataItem}
                  config={config}
                />
              </>
            )}
          </Box>
        </Stack>
      )}
    </>
  );
};

export default CardItemWrapper;

CardItemWrapper.propTypes = {
  dataItem: PropTypes.object,
  groupingKey: PropTypes.string,
  align: PropTypes.string,
  config: PropTypes.object.isRequired,
  cardIdx: PropTypes.number,
};
