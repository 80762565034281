import { fPercent } from '@autone/utils';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';

const ProgressItem = ({ title, value, percentage, variant }) => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Typography variant="subtitle1">{value}</Typography>
        {percentage && (
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            &nbsp;({fPercent(percentage)})
          </Typography>
        )}
      </Stack>

      <LinearProgress
        variant="determinate"
        value={percentage * 100}
        sx={{
          backgroundColor: alpha(variant, 0.3),
          '& .MuiLinearProgress-bar': {
            backgroundColor: variant,
          },
        }}
      />
    </Stack>
  );
};

ProgressItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.string,
  percentage: PropTypes.number.isRequired,
};

export default ProgressItem;
