import CssBaseline from '@mui/material/CssBaseline';
import {
  type BreakpointsOptions,
  createTheme,
  type Shadows,
  StyledEngineProvider,
  type ThemeOptions,
  ThemeProvider,
} from '@mui/material/styles';
import { type TypographyOptions } from '@mui/material/styles/createTypography';

import avatar, { type AvatarSize } from './avatar';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import icons from './icons';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows, type CustomShadows } from './shadows';
import shape from './shape';
import typography from './typography';

// ----------------------------------------------------------------------

type AutoneThemeOptions = ThemeOptions & {
  customShadows: CustomShadows['light'];
  icons: { [key: string]: number };
  breakpoints: BreakpointsOptions;
  avatar: { [key: string]: AvatarSize };
  typography: TypographyOptions;
  shadows: Shadows;
  palette: (typeof palette)['light'];
  shape: typeof shape;
};

export default function ThemeConfig({
  children,
}: {
  children: React.ReactNode;
}) {
  const themeOptions: AutoneThemeOptions = {
    palette: palette.light,
    shape,
    typography,
    avatar,
    icons,
    breakpoints,
    shadows: shadows.light,
    customShadows: customShadows.light,
  };

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
