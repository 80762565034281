import {
  type CustomTheme,
  TableCell as MaterialTableCell,
  type SxProps,
  useTheme,
} from '@mui/material';
import { forwardRef, useRef } from 'react';

import { useTableContext } from '../contexts/TableContext';
type Props = {
  id?: string;
  children?: React.ReactNode;
  sx?: SxProps<CustomTheme>;
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLTableCellElement>,
    positionId?: string,
  ) => void;
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement>,
    positionId?: string,
  ) => void;
  columnNum?: number;
  rowNum?: number;
  sticky?: 'left' | 'right';
  focusable?: boolean;
  align?: 'left' | 'right' | 'center';
  className?: string;
  colSpan?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};
const TableCell = forwardRef<HTMLTableCellElement, Props>(
  (
    {
      id,
      children,
      sx,
      onKeyDown = () => {},
      onClick = () => {},
      columnNum,
      rowNum,
      sticky,
      focusable = false,
      align,
      className,
      colSpan,
      onMouseEnter,
      onMouseLeave,
    },
    ref,
  ) => {
    const theme = useTheme();
    const {
      hideBorders,
      tableProps: { tableClasses },
    } = useTableContext();

    const positionId = `${rowNum}-${columnNum}`;
    const focusRef = useRef();

    let stickyClasses;
    if (sticky === 'right') {
      stickyClasses = tableClasses.stickyRight;
    } else if (sticky === 'left') {
      stickyClasses = tableClasses.stickyLeft;
    }

    return (
      <MaterialTableCell
        align={align}
        className={className}
        colSpan={colSpan}
        id={id || positionId}
        onClick={(e) => onClick(e, positionId)}
        onKeyDown={(e) => onKeyDown(e, positionId)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref || focusRef}
        tabIndex={0}
        autoFocus={focusable}
        // @ts-ignore
        sx={{
          ...(sticky ? stickyClasses : {}),
          ...(!hideBorders && {
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
          }),
          ...(focusable && {
            '&:focus': {
              borderRadius: '2px',
              outline: '2px solid -webkit-focus-ring-color',
              boxShadow: `inset 0 0 8px ${theme.palette.primary.main}`,
            },
          }),
          ...sx,
        }}
      >
        {children}
      </MaterialTableCell>
    );
  },
);

export default TableCell;
