import { generatePath } from './generate-paths';

export const REBALANCING_ROOT = '/rebalancing';

export const REBALANCING_PATH_APP: {
  overview: string;
  newRebalance: string;
  editRebalancing: string;
  loading: string;
  error: string;
  confirmedRebalancing: string;
  editSchedule: string;
} = {
  overview: generatePath(REBALANCING_ROOT, '/overview'),
  newRebalance: generatePath(REBALANCING_ROOT, '/new-rebalance'),
  editRebalancing: generatePath(REBALANCING_ROOT, '/batch/:batchId'),
  loading: generatePath(REBALANCING_ROOT, '/batch/:batchId/loading'),
  error: generatePath(REBALANCING_ROOT, '/error'),
  confirmedRebalancing: generatePath(
    REBALANCING_ROOT,
    '/batch/:batchId/confirmation',
  ),
  editSchedule: generatePath(REBALANCING_ROOT, '/schedule/:scheduleId/edit'),
};
