'use client';

import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';

import { AutoneTooltip, AutoneTooltipArrow } from './Tooltip';
import { cn } from './utils';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    showTooltip?: boolean;
    startLabel?: string;
    endLabel: string;
    displayValue?: string;
  }
>(
  (
    { className, showTooltip, startLabel, endLabel, displayValue, ...props },
    ref,
  ) => (
    <div className="relative pt-1 pb-6">
      <SliderPrimitive.Root
        ref={ref}
        className={cn(
          'relative flex w-full touch-none select-none items-center',
          className,
        )}
        {...props}
      >
        <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary">
          <SliderPrimitive.Range className="absolute h-full bg-primary" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-primary bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
          {showTooltip && (
            <AutoneTooltip
              text={displayValue ?? props.value}
              asChild
              alwaysOpen
              className="bg-primary"
              arrow={<AutoneTooltipArrow className="fill-primary" />}
            />
          )}
        </SliderPrimitive.Thumb>
      </SliderPrimitive.Root>
      {startLabel && (
        <span className="absolute bottom-0 left-0 text-sm text-muted-foreground -translate-x-2 translate-y-1 text-grey-40">
          {startLabel}
        </span>
      )}
      {endLabel && (
        <span className="absolute bottom-0 right-0 text-sm text-muted-foreground translate-x-2 translate-y-1 text-grey-40">
          {endLabel}
        </span>
      )}
    </div>
  ),
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
