import { Box, Card, Divider, Stack } from '@mui/material';
import React from 'react';

const CardLayout = ({
  header,
  contentLeft,
  contentRight,
  footer,
}: {
  header: React.ReactNode;
  contentLeft: React.ReactNode;
  contentRight: React.ReactNode;
  footer: React.ReactNode;
}) => {
  const contentLeftRightStyles = {
    p: 3,
    py: 3,
    px: 5,
    width: '50%',
    height: '100%',
  };

  return (
    <Card sx={{ height: '100%', width: '100%' }}>
      <Stack sx={{ height: '100%', width: '100%' }}>
        <Stack justifyContent="center" sx={{ width: '100%', height: '15%' }}>
          <Box sx={{ px: 3 }}>{header}</Box>
        </Stack>
        <Divider flexItem />
        <Stack direction="row" spacing={0} sx={{ height: '70%' }}>
          <Stack
            spacing={2}
            alignItems="start"
            sx={{ ...contentLeftRightStyles }}
          >
            {contentLeft}
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack
            spacing={2}
            alignItems="start"
            sx={{
              ...contentLeftRightStyles,
            }}
          >
            {contentRight}
          </Stack>
        </Stack>
        <Divider flexItem />
        <Stack
          sx={{ height: '15%', px: 4 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          {footer}
        </Stack>
      </Stack>
    </Card>
  );
};

export default CardLayout;
