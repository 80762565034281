import { useAutoneTranslation } from '@autone/translations';
import { createContext, type ReactNode, useContext, useMemo } from 'react';

import { useUserToken } from '../hooks';
import { CanAccess } from '../utils';

type AccessControlContextProps = {
  hasReadOnlyAccess: boolean;
  disabledText: string;
};

type Permissions = string[];

const AccessContext = createContext<AccessControlContextProps | undefined>(
  undefined,
);

const useCanAccess = (
  editPermissions: Permissions,
): AccessControlContextProps => {
  const { token } = useUserToken();
  const { common } = useAutoneTranslation();

  const hasReadOnlyAccess = useMemo(() => {
    return token ? !CanAccess(token, editPermissions) : false;
  }, [token]);

  return {
    hasReadOnlyAccess,
    disabledText: common('tooltips.no-access-text'),
  };
};

export const AccessProvider = ({
  children,
  editPermissions,
}: {
  children: ReactNode;
  editPermissions: string[];
}) => {
  const access = useCanAccess(editPermissions);

  return (
    <AccessContext.Provider value={access}>{children}</AccessContext.Provider>
  );
};

export const useAccessProvider = () => {
  const context = useContext(AccessContext);
  if (context === undefined) {
    throw new Error('useAccessControl must be used within an AccessProvider');
  }
  return context;
};
