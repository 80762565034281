import {
  forwardRef,
  type ReactNode,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';

import { cn } from '../utils';

import { Input, type InputProps } from './Input';

interface InputWithIconProps extends InputProps {
  icon: ReactNode;
  align?: 'left' | 'right';
}

export const InputWithIcon = forwardRef<HTMLInputElement, InputWithIconProps>(
  ({ icon, align = 'left', className, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const defaultInputPadding = useRef<number | null>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    // save the expected default padding on the side without the icon
    // for the input on first render
    useLayoutEffect(() => {
      if (inputRef.current) {
        if (align === 'left') {
          defaultInputPadding.current = parseFloat(
            window.getComputedStyle(inputRef.current).paddingRight,
          );
        } else {
          defaultInputPadding.current = parseFloat(
            window.getComputedStyle(inputRef.current).paddingLeft,
          );
        }
      }
    }, []);

    // make sure input padding is updated if `align` changes
    useLayoutEffect(() => {
      if (inputRef.current && defaultInputPadding.current) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const inputHeight = inputRect.height;

        if (align === 'left') {
          inputRef.current.style.paddingLeft = `${inputHeight}px`;
          inputRef.current.style.paddingRight = `${defaultInputPadding.current}px`;
        } else {
          inputRef.current.style.paddingLeft = `${defaultInputPadding.current}px`;
          inputRef.current.style.paddingRight = `${inputHeight}px`;
        }
      }
    }, [align]);

    return (
      <div className={cn('relative w-fit h-fit', className)}>
        <Input className={'px-3 h-full w-full'} {...props} ref={inputRef} />
        <div
          className={cn(
            'absolute top-0 grid place-items-center h-full aspect-square',
            align === 'left' ? 'left-0' : 'right-0',
          )}
        >
          {icon}
        </div>
      </div>
    );
  },
);
