import { forwardRef, type HTMLAttributes } from 'react';

import { cn } from '../utils';

export const DataTableContainer = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        'relative flex flex-col w-full rounded-md border border-grey-5 overflow-y-scroll shadow-sm bg-white',
        className,
      )}
      {...props}
      ref={ref}
    ></div>
  );
});

DataTableContainer.displayName = 'DataTableContainer';

export const DataTableContainerFooter = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      'flex flex-row justify-end p-2 border-t border-grey-10',
      className,
    )}
    {...props}
    ref={ref}
  ></div>
));

DataTableContainerFooter.displayName = 'DataTableContainerFooter';

export const DataTableContainerContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn('flex flex-col w-full', className)}
    {...props}
    ref={ref}
  ></div>
));

DataTableContainerContent.displayName = 'DataTableContainerContent';
