import * as React from 'react';

import { cn } from '../utils';

interface DataTableProps extends React.HTMLAttributes<HTMLTableElement> {
  showScrollbar?: boolean;
}

const DataTable = React.forwardRef<HTMLTableElement, DataTableProps>(
  ({ className, showScrollbar = true, ...props }, ref) => (
    <div
      className={cn('w-full overflow-auto', !showScrollbar && 'no-scrollbar')}
    >
      <table
        ref={ref}
        className={cn(
          'w-full caption-bottom text-sm border-separate border-spacing-0',
          className,
        )}
        {...props}
      />
    </div>
  ),
);
DataTable.displayName = 'Table';

const DataTableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      '[&_tr]:border-b bg-grey-10 text-xs [&>tr]:first:hover:bg-grey-10 sticky top-0 shadow-sm',
      className,
    )}
    {...props}
  />
));
DataTableHeader.displayName = 'TableHeader';

const DataTableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('[&_tr:last-child]:border-0', className)}
    {...props}
  />
));
DataTableBody.displayName = 'TableBody';

const DataTableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <div
    role="rowgroup"
    ref={ref}
    className={cn('text-xs py-2 px-4', className)}
    {...props}
  />
));
DataTableFooter.displayName = 'TableFooter';

const DataTableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn('transition-colors hover:bg-grey-5 bg-paper', className)}
    {...props}
  />
));
DataTableRow.displayName = 'TableRow';

const DataTableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'h-12 px-6 text-left align-middle font-medium bg-grey-10 [&:has([role=checkbox])]:pr-0',
      className,
    )}
    {...props}
  />
));
DataTableHead.displayName = 'TableHead';

const DataTableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'py-4 px-6 align-middle [&:has([role=checkbox])]:pr-0 bg-inherit border-b border-grey-5',
      className,
    )}
    {...props}
  />
));
DataTableCell.displayName = 'TableCell';

const DataTableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn('mt-4 text-sm text-grey-40', className)}
    {...props}
  />
));
DataTableCaption.displayName = 'TableCaption';

export {
  DataTable,
  DataTableHeader,
  DataTableBody,
  DataTableFooter,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableCaption,
};
