export const GbFlag = () => (
  <svg
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="GB">
      <rect
        id="Mask Copy"
        x="0.299988"
        width="22.4"
        height="16"
        rx="3"
        fill="#0A17A7"
      />
      <mask
        id="mask0_892_127"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="23"
        height="16"
      >
        <rect
          id="Mask Copy_2"
          x="0.299988"
          width="22.4"
          height="16"
          rx="3"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_892_127)">
        <path
          id="Rectangle 2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.726001 -1.53314L8.83331 4.91469V-1.06665H14.1666V4.91469L23.7259 -1.53314L24.9189 0.235471L17.361 5.33335H22.7V10.6667H17.361L24.9189 15.7646L23.7259 17.5332L14.1666 11.0853V17.0667H8.83331V11.0853L-0.726 17.5332L-1.91895 15.7646L5.63897 10.6667H0.299975V5.33335H5.63897L-1.91895 0.235472L-0.726001 -1.53314Z"
          fill="white"
        />
        <path
          id="Line"
          d="M15.2344 5.06575L25.3667 -1.60001"
          stroke="#DB1F35"
          strokeWidth="0.666667"
          strokeLinecap="round"
        />
        <path
          id="Line Copy 2"
          d="M16.3102 10.958L25.3933 17.0803"
          stroke="#DB1F35"
          strokeWidth="0.666667"
          strokeLinecap="round"
        />
        <path
          id="Line Copy"
          d="M6.70439 5.04836L-2.77002 -1.33679"
          stroke="#DB1F35"
          strokeWidth="0.666667"
          strokeLinecap="round"
        />
        <path
          id="Line Copy 3"
          d="M7.732 10.8839L-2.77002 17.8484"
          stroke="#DB1F35"
          strokeWidth="0.666667"
          strokeLinecap="round"
        />
        <path
          id="Rectangle 2 Copy 3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.299988 9.6H9.89999V16H13.1V9.6H22.7V6.4H13.1V0H9.89999V6.4H0.299988V9.6Z"
          fill="#E6273E"
        />
      </g>
    </g>
  </svg>
);
