import { Box, Fade, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const InActiveSelectionPrompt = ({ message, Icon, loading, sx }) => {
  const theme = useTheme();

  return (
    <Fade in>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: loading ? '10%' : '30%',
          flexFlow: 'column',
          ...sx,
        }}
      >
        {loading ? (
          <Skeleton height={250} width="80%" />
        ) : (
          <>
            <Box sx={{ mb: 2 }}>
              <Icon style={{ fontSize: 70, fill: theme.palette.grey[500] }} />
            </Box>
            <Typography>{message}</Typography>
          </>
        )}
      </Box>
    </Fade>
  );
};

InActiveSelectionPrompt.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  Icon: PropTypes.any,
  loading: PropTypes.bool,
  sx: PropTypes.object,
};

export default InActiveSelectionPrompt;
