import ArrowForward from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const NextStepArrow = ({ sx, arrowSize = 'medium' }) => {
  const NextStepArrow = styled(ArrowForward)(({ theme }) => ({
    fill: theme.palette.grey[400],
    pd: 2,
  }));

  return (
    <Box sx={{ ...sx }}>
      <NextStepArrow fontSize={arrowSize} />
    </Box>
  );
};

NextStepArrow.propTypes = {
  sx: PropTypes.object,
  arrowSize: PropTypes.string,
};

export default NextStepArrow;
