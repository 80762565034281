import { Box, IconButton } from '@mui/material';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui

// ----------------------------------------------------------------------

const IconButtonAnimate = forwardRef(
  ({ children, sx, size = 'medium', ...other }, ref) => (
    <AnimateWrap size={size}>
      <IconButton
        size={size === 'tiny' ? 'small' : size}
        ref={ref}
        {...other}
        sx={{
          ...sx,
          ...(size === 'tiny' && { padding: '2px' }),
        }}
      >
        {children}
      </IconButton>
    </AnimateWrap>
  ),
);

IconButtonAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'inherit',
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
  ]),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  sx: PropTypes.object,
};

export default IconButtonAnimate;

// ----------------------------------------------------------------------

const varTiny = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

AnimateWrap.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
};

function AnimateWrap({ size, children }) {
  const isTiny = size === 'tiny';
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={
        (isTiny && varTiny) ||
        (isSmall && varSmall) ||
        (isLarge && varLarge) ||
        varMedium
      }
      sx={{
        display: 'inline-flex',
      }}
    >
      {children}
    </Box>
  );
}
