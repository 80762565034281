import { URL_PREFIX } from '@autone/utils';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { type FallbackProps } from 'react-error-boundary';

const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  const theme = useTheme();
  const logo = `${URL_PREFIX}/autone-logos/autone-icon-new.svg`;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Box
          component="img"
          alt="logo"
          src={logo}
          sx={{
            height: '70px',
          }}
        />
        <Box>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Oops, something went wrong on our side
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            Please contact us, and we will resolve this issue as quickly as
            possible
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            textTransform: 'none',
            borderRadius: '8px',
            boxShadow: `0 4px 8px -2px ${alpha('#788298', 0.2)}`,
            '&:hover': {
              boxShadow: 'none',
            },
          }}
          onClick={() => resetErrorBoundary()}
        >
          Reload page
        </Button>
      </Stack>
    </Box>
  );
};

export default ErrorFallback;
