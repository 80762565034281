import { cva } from 'class-variance-authority';
import * as React from 'react';
import { createContext, useContext } from 'react';

import { Badge } from '../../tailwind/Badge';
import { TailwindCard as Card } from '../../tailwind/Card';
import { cn } from '../../tailwind/utils';
import { EventAvatar } from '../event-avatar/EventAvatar';

type EventColor = 'primary' | 'secondary';
type EventAvatarProps = {
  source: 'autone' | 'user';
  username?: string | null;
};

const eventCardVariants = cva(
  'relative flex w-full rounded-lg p-2 shadow-none',
  {
    variants: {
      color: {
        primary: '',
        secondary: '',
      },
      variant: {
        filled: 'border-l-2 border-r-0 border-t-0 border-b-0',
        outlined: '',
      },
    },
    compoundVariants: [
      {
        color: 'primary',
        variant: 'filled',
        class: 'bg-primary-10 text-primary-100 border-primary-100',
      },
      {
        color: 'secondary',
        variant: 'filled',
        class: 'bg-secondary-20 text-secondary-120 border-secondary-100',
      },
      {
        color: 'primary',
        variant: 'outlined',
        class: 'border-primary-100 text-primary-100',
      },
      {
        color: 'secondary',
        variant: 'outlined',
        class: 'border-secondary-100 text-secondary-120',
      },
    ],
    defaultVariants: {
      variant: 'filled',
      color: 'primary',
    },
  },
);

const EventCardContext = createContext<{ color: EventColor } | null>(null);

const EventCard = ({
  variant,
  color,
  className,
  children,
  onClick,
}: {
  variant: 'outlined' | 'filled';
  color: EventColor;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <EventCardContext.Provider value={{ color }}>
      <Card
        onClick={onClick}
        className={cn(eventCardVariants({ variant, color }), className)}
      >
        {children}
      </Card>
    </EventCardContext.Provider>
  );
};

const EventTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'text-xs leading-tight font-medium overflow-hidden whitespace-nowrap text-ellipsis max-w-[85%]',
      className,
    )}
    {...props}
  >
    {children}
  </h3>
));

const EventDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      'text-xxs leading-tight overflow-hidden whitespace-nowrap text-ellipsis max-w-[80%]',
      className,
    )}
    {...props}
  />
));

const EventCardAvatar = ({ source, username }: EventAvatarProps) => {
  const context = useContext(EventCardContext);
  if (!context) {
    throw new Error('EventAvatar must be used within an EventCard');
  }
  const { color } = context;

  return (
    <EventAvatar
      source={source}
      username={username}
      color={color}
      className="absolute right-2 top-2 h-6 w-6 text-xs"
    />
  );
};

const EventBadge = ({ children }: { children: React.ReactNode }) => (
  <Badge variant="grey" className="text-xxs mb-0.5 py-0 px-1 leading-snug">
    {children}
  </Badge>
);

export { EventCard, EventTitle, EventDescription, EventBadge, EventCardAvatar };
