import { Box, Button, Divider } from '@mui/material';
import { type SxProps } from '@mui/system';
import { useMemo } from 'react';

type SelectAllOption = {
  id?: string | null;
  title?: string | null;
  active?: boolean | null;
};

// component that renders a button to select or clear options
const SelectAll = <T extends SelectAllOption>({
  options,
  selectAllText,
  clearAllText,
  handleSelectAll,
  handleClearAll,
  showDivider = true,
  sx,
  areAllItemsSelected,
}: {
  options: T[];
  selectAllText?: string;
  clearAllText?: string;
  handleSelectAll?: (options: T[]) => void;
  handleClearAll?: (options: T[]) => void;
  showDivider?: boolean;
  sx?: SxProps;
  areAllItemsSelected?: boolean;
}) => {
  // check if all options are selected
  const allSelected = useMemo(
    () => areAllItemsSelected || options.every(({ active }) => active),
    [areAllItemsSelected, options],
  );

  // set button text depending on if all options are selected
  const buttonText = allSelected
    ? (clearAllText ?? 'Clear all options')
    : (selectAllText ?? `Select all ${options.length} options`);

  // set callback function depending on if all options are selected
  const onClickCallback = (allSelected: boolean) =>
    allSelected ? handleClearAll?.(options) : handleSelectAll?.(options);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Button
        sx={{
          textTransform: 'none',
          m: 0.5,
          mb: 1,
        }}
        onClick={() => {
          onClickCallback(allSelected);
        }}
      >
        {buttonText}
      </Button>
      {showDivider && <Divider />}
    </Box>
  );
};

export default SelectAll;
