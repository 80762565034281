import { useAutoneTranslation } from '@autone/translations';
import { Tabs, TabsList, type TabsProps } from '@radix-ui/react-tabs';
import { forwardRef, type PropsWithChildren } from 'react';

import { type ButtonProps, cn, TailwindButton } from '../../../tailwind';

import {
  GeneralFiltersGroupOptions,
  GeneralFiltersGroupTrigger,
} from './GeneralFilters.components';
import {
  GeneralFiltersContext,
  useGeneralFiltersContext,
} from './GeneralFilters.context';
import { type GeneralFilterGroup, type SelectedGeneralFilters } from './types';

export interface GeneralFiltersViewProps extends PropsWithChildren {
  filterGroups: GeneralFilterGroup[];
  selectedFilters: SelectedGeneralFilters;
  onSelectedFiltersChange: (selectedFilters: SelectedGeneralFilters) => void;
}

export const GeneralFiltersView = ({
  children,
  filterGroups,
  selectedFilters,
  onSelectedFiltersChange,
}: GeneralFiltersViewProps) => {
  const toggleOption = (groupId: string, optionId: string, value: boolean) => {
    const newSelectedFilters = {
      ...selectedFilters,
      [groupId]: {
        ...selectedFilters[groupId],
        [optionId]: value,
      },
    };
    onSelectedFiltersChange(newSelectedFilters);
  };

  const toggleFilterGroup = (groupId: string, value: boolean) => {
    const filterGroup = filterGroups.find((group) => group.id === groupId);
    if (filterGroup) {
      const newSelectedFilters = {
        ...selectedFilters,
        [groupId]: Object.fromEntries(
          filterGroup.options.map((option) => [option.id, value]),
        ),
      };
      onSelectedFiltersChange(newSelectedFilters);
    }
  };

  return (
    <GeneralFiltersContext.Provider
      value={{
        filterGroups,
        toggleOption,
        toggleFilterGroup,
        setToggledOptions: onSelectedFiltersChange,
        toggledOptions: selectedFilters,
      }}
    >
      {children}
    </GeneralFiltersContext.Provider>
  );
};

type GeneralFilterContentProps = TabsProps;

export const GeneralFiltersContent = forwardRef<
  HTMLDivElement,
  GeneralFilterContentProps
>(({ className, ...props }, ref) => {
  const { common } = useAutoneTranslation();
  const { filterGroups } = useGeneralFiltersContext();
  const hasFilterGroups = filterGroups.length > 0;

  return (
    <Tabs
      className={cn(
        'flex flex-row bg-white min-h-0 min-w-0 h-full self-stretch grow overflow-hidden',
        className,
      )}
      defaultValue={hasFilterGroups ? filterGroups[0].id : ''}
      {...props}
      ref={ref}
    >
      {hasFilterGroups ? (
        <>
          <TabsList className="flex flex-col px-5 py-3 basis-1/3 min-h-0 self-stretch border-e border-e-grey-20 overflow-y-scroll no-scrollbar">
            <div className="flex flex-col gap-1 self-stretch">
              {filterGroups.map((filterGroup) => (
                <GeneralFiltersGroupTrigger
                  key={filterGroup.id}
                  filterGroup={filterGroup}
                />
              ))}
            </div>
          </TabsList>
          <div className="flex flex-col grow">
            {filterGroups.map((group) => (
              <GeneralFiltersGroupOptions key={group.id} filterGroup={group} />
            ))}
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center self-stretch w-full gap-1">
          <p className="text-primary-100 text-lg font-medium">
            {common('filters.general-filters-no-options.header')}
          </p>
          <p className="text-grey-60">
            {common('filters.general-filters-no-options.subtitle')}
          </p>
        </div>
      )}
    </Tabs>
  );
});

export const GeneralFiltersClearAllButton = forwardRef<
  HTMLButtonElement,
  ButtonProps
>(({ className, ...props }, ref) => {
  const { setToggledOptions } = useGeneralFiltersContext();

  return (
    <TailwindButton
      onClick={() => setToggledOptions({})}
      className={className}
      variant={'outlined'}
      color={'secondary'}
      {...props}
      ref={ref}
    />
  );
});
