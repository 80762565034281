export const FrFlag = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.399994" width="22.4" height="16" rx="3" fill="white" />
    <mask
      id="mask0_875_71"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="23"
      height="16"
    >
      <rect x="0.399994" width="22.4" height="16" rx="3" fill="white" />
    </mask>
    <g mask="url(#mask0_875_71)">
      <rect x="15.6" width="7.2" height="16" fill="#F31830" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.399994 16H7.59999V0H0.399994V16Z"
        fill="#00267F"
      />
    </g>
  </svg>
);
