import { generatePath } from './generate-paths';

export const REPLEN_ROOT = '/replenishment';

export const REPLEN_PATH_APP: {
  overview: string;
  newReplenishment: string;
  editReplenishment: string;
  summary: string;
  loading: string;
  error: string;
  confirmedReplenishment: string;
  editSchedule: string;
} = {
  overview: generatePath(REPLEN_ROOT, '/overview'),
  newReplenishment: generatePath(REPLEN_ROOT, '/new-replenishment'),
  editReplenishment: generatePath(
    REPLEN_ROOT,
    '/batch/:batchId/dist-point/:fromLocationId',
  ),
  summary: generatePath(
    REPLEN_ROOT,
    '/batch/:batchId/dist-point/:fromLocationId/summary',
  ),
  loading: generatePath(REPLEN_ROOT, '/batch/:batchId/loading'),
  error: generatePath(
    REPLEN_ROOT,
    '/error/batch/:batchId/reasonCode/:reasonCode',
  ),
  confirmedReplenishment: generatePath(
    REPLEN_ROOT,
    '/batch/:batchId/dist-point/:fromLocationId/confirmation',
  ),
  editSchedule: generatePath(REPLEN_ROOT, '/schedule/:scheduleId/edit'),
};
