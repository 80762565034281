import { uniqueId } from 'lodash';

import {
  type AdvancedFiltersCondition,
  type AdvancedFiltersInternalConditions,
  type AdvancedFiltersState,
} from './types';

export const assignUuidToAdvancedFilterConditions = (
  conditions: Partial<AdvancedFiltersCondition>[],
): AdvancedFiltersInternalConditions => {
  const advancedFilterInternal: AdvancedFiltersInternalConditions = {};
  for (const condition of conditions) {
    const uuid = uniqueId();
    advancedFilterInternal[uuid] = condition;
  }

  return advancedFilterInternal;
};

export const advancedFilterConditionIsComplete = ({
  filterId,
  filterConditionId,
  value,
}: Partial<AdvancedFiltersCondition>): boolean => {
  return (
    filterId !== undefined &&
    filterConditionId !== undefined &&
    value !== undefined
  );
};

export const initialiseAdvancedFiltersReducer = (
  defaultAdvancedFilters: AdvancedFiltersState | undefined,
) => {
  if (
    !defaultAdvancedFilters ||
    defaultAdvancedFilters.conditions.length === 0
  ) {
    // initialize with a single empty condition row
    return {
      conditionCombineMode: 'and' as const,
      conditions: assignUuidToAdvancedFilterConditions([{}]),
    };
  } else {
    // initialize with the provided defaults
    return {
      conditionCombineMode: defaultAdvancedFilters.conditionCombineMode,
      conditions: assignUuidToAdvancedFilterConditions(
        defaultAdvancedFilters.conditions,
      ),
    };
  }
};
