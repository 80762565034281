import { generatePath } from './generate-paths';

export const REORDER_ROOT = '/reorder';

export const REORDER_PATH_APP = {
  root: REORDER_ROOT,
  overview: generatePath(REORDER_ROOT, '/overview'),
  newReorder: generatePath(REORDER_ROOT, '/new-reorder'),
  reorder: generatePath(REORDER_ROOT, '/batch/:batchId'),
  loading: generatePath(REORDER_ROOT, '/batch/:batchId/loading'),
  error: generatePath(REORDER_ROOT, '/error'),
  errorCode: generatePath(REORDER_ROOT, '/error/batch/:batchId/:reasonCode'),
  summary: generatePath(REORDER_ROOT, '/batch/:batchId/summary'),
  confirmedReorder: generatePath(REORDER_ROOT, '/batch/:batchId/confirmation'),
  editSchedule: generatePath(REORDER_ROOT, '/schedule/:scheduleId/edit'),
};
