import { LEGACY_FILTER_APPS } from '@autone/utils';
import { List, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

import FilterItems from './FilterItems';

export default function FilterList({
  filterOptions,
  handleFilterClickCallback,
  handleSelectDeselectAllByDimension,
  numOptionsDefaultOpen = 0,
  appName,
  labels,
}) {
  // if filters are undefined then show skeleton placeholders
  if (!filterOptions) {
    return Array.from({ length: 3 }, (v, k) => k + 1).map((item) => (
      <Skeleton key={item} sx={{ ml: 3, mr: 3 }} height={60} />
    ));
  }

  return (
    <List sx={{ mt: 1 }}>
      {filterOptions.map(
        ({
          dimension,
          dimension_id: dimensionId,
          title,
          name,
          options,
          icon,
        }) =>
          options?.length > 0 && (
            <FilterItems
              key={
                LEGACY_FILTER_APPS.includes(appName) ? dimension : dimensionId
              }
              dimension={
                LEGACY_FILTER_APPS.includes(appName) ? dimension : dimensionId
              }
              title={LEGACY_FILTER_APPS.includes(appName) ? title : name}
              options={options}
              labels={labels}
              activeFilters={options.filter((item) => item.active).length}
              handleFilterClickCallback={handleFilterClickCallback}
              handleSelectDeselectAllByDimension={
                handleSelectDeselectAllByDimension
              }
              icon={icon}
              numOptionsDefaultOpen={numOptionsDefaultOpen}
            />
          ),
      )}
    </List>
  );
}

FilterList.propTypes = {
  filterOptions: PropTypes.array,
  handleFilterClickCallback: PropTypes.func,
  handleSelectDeselectAllByDimension: PropTypes.func,
  numOptionsDefaultOpen: PropTypes.number,
  appName: PropTypes.string,
  labels: PropTypes.shape({
    searchPlaceholder: PropTypes.string,
    selectAll: PropTypes.func,
    clearAll: PropTypes.string,
    loadMore: PropTypes.string,
  }),
};
