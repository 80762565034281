import { capitalizeFirstLetter } from '@autone/utils';
import EditIcon from '@mui/icons-material/Edit';
import { Box, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * Note with this component, we have ran into the problem described below:
 * https://stackoverflow.com/questions/28922275/in-reactjs-why-does-setstate-behave-differently-when-called-synchronously/28922465#28922465
 *
 * Using value directly from redux causes the cursor to jump to the end of the input field.
 *
 */

export default function EditText({
  value,
  postEditCallback,
  theme,
  placeholder,
  updateValueCallback = () => {},
  disabled,
}) {
  // state for whether or not the input is being edited
  const [isEditing, setIsEditing] = useState(false);

  // state for showing the edit icon on hover
  const [hover, setHover] = useState(false);

  // workaround for cursor jumping (described above), we set this state and also keep redux in sync
  const [editedValue, setEditedValue] = useState(value);

  // function used to trap an enter key, so we can stop editing mode
  const handleKeyDown = (e) => {
    // if enter key is pressed, stop editing and post the data to the backend
    if (e.keyCode === 13) {
      postEditCallback(editedValue);
      setIsEditing(false);
    }
  };

  // function used to trap clicking off the component, so we can stop editing mode and post the data to the backend
  const handleOnBlur = (textValue) => {
    if (isEditing) {
      postEditCallback(textValue);
      setIsEditing(false);
    }
  };

  // here we update state in the component (which will be the value presented to the user) and keep redux in sync.
  // not ideal but a workaround to the issue described that the top of the component.
  const handleChange = (e) => {
    setEditedValue(e.target.value);
    updateValueCallback(e.target.value);
  };

  // on click of the field we enter editing mode
  const handleSetEditing = () => {
    setIsEditing(true);
  };

  const boxStyles = {
    padding: 0.5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  return (
    <>
      {!disabled ? (
        <Box
          onMouseLeave={() => setHover(false)}
          onMouseOver={() => setHover(true)}
        >
          {isEditing ? (
            <TextField
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  padding: 0.5,
                },
              }}
              multiline
              hiddenLabel
              type="text"
              onKeyDown={handleKeyDown}
              onBlur={() => handleOnBlur(editedValue)}
              value={editedValue}
              onChange={(e) => handleChange(e)}
              autoFocus
            />
          ) : (
            <Box
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.grey[100],
                  borderRadius: `${theme.shape.borderRadius}px`,
                },
                ...boxStyles,
              }}
              onClick={() => handleSetEditing()}
            >
              {value && value.length !== 0 ? (
                <Typography variant="body1">
                  {capitalizeFirstLetter(value)}
                </Typography>
              ) : (
                placeholder
              )}
              {hover && (
                <EditIcon sx={{ fontSize: theme.typography.body2.fontSize }} />
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ ...boxStyles }}>
          <Typography variant="body1">
            {capitalizeFirstLetter(value)}
          </Typography>
        </Box>
      )}
    </>
  );
}

EditText.propTypes = {
  value: PropTypes.string,
  postEditCallback: PropTypes.func,
  theme: PropTypes.object,
  placeholder: PropTypes.node,
  updateValueCallback: PropTypes.func,
  disabled: PropTypes.bool,
};
