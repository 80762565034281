import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Stack, type SxProps } from '@mui/material';
import { type PopoverOrigin } from '@mui/material/Popover/Popover';
import React, { useEffect, useState } from 'react';

import MenuPopover from '../../../../popover/Popover';

type ActionsPopoverProps = {
  id?: string;
  children: React.ReactNode | null;
  popoverProps?: {
    anchorOrigin: PopoverOrigin;
    transformOrigin: PopoverOrigin;
    renderArrow: boolean | undefined;
  };
  popoverStyles?: SxProps;
};

// TODO: refactor to tailwind and remove clone element props
const ActionsPopover = ({
  id,
  children,
  popoverProps,
  popoverStyles,
}: ActionsPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const tableRowId = open ? 'table-row-actions-popover' : undefined;

  useEffect(() => {
    document.addEventListener(
      'table-row-popover-close',
      (e) => e.detail.id === id && handleClose(),
    );
  }, []);

  return (
    <>
      <IconButton
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        aria-label="options"
        size="small"
        aria-describedby={tableRowId}
        data-testid="table-row-actions-popover"
      >
        <MoreVert />
      </IconButton>

      <MenuPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={popoverStyles}
        {...popoverProps}
      >
        <Stack alignItems={'flex-end'} spacing={1}>
          {React.Children.map(children, (child) =>
            child
              ? React.cloneElement(child as React.ReactElement, {
                  handlePopoverClose: handleClose,
                })
              : null,
          )}
        </Stack>
      </MenuPopover>
    </>
  );
};

export default ActionsPopover;
