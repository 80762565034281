import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PropTypes from 'prop-types';

export const DashboardIcon = ({ size = 22, color = '#01172F' }) => (
  <GridViewOutlinedIcon sx={{ color, fontSize: size + 2 }} />
);

DashboardIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
