import { useMediaQuery, useTheme } from '@mui/material';
import { createContext, type ReactNode, useState } from 'react';

// ----------------------------------------------------------------------

type CollapseDrawerContextType = {
  isCollapse: boolean;
  collapseClick: boolean;
  collapseHover: boolean;
  onToggleCollapse: () => void;
  onHoverEnter: () => void;
  onHoverLeave: () => void;

  setToggleCollapse: (click: boolean) => void;
};

const CollapseDrawerContext = createContext<CollapseDrawerContextType>({
  isCollapse: false,
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => {},
  onHoverEnter: () => {},
  onHoverLeave: () => {},
  setToggleCollapse: () => {},
});

// ----------------------------------------------------------------------

function CollapseDrawerProvider({ children }: { children: ReactNode }) {
  const [collapse, setCollapse] = useState<{ click: boolean; hover: boolean }>({
    click: true,
    hover: false,
  });

  const theme = useTheme();
  const isOnSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleToggleCollapse = (): void => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  // function used to set click to true or false
  const handleSetToggleCollapse = (click: boolean): void => {
    setCollapse({ ...collapse, click });
  };

  const handleHoverEnter = (): void => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const handleHoverLeave = (): void => {
    setCollapse({ ...collapse, hover: false });
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: isOnSmallScreen ? false : collapse.click && !collapse.hover,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave,
        setToggleCollapse: handleSetToggleCollapse,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };
