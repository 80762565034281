import { fData } from '@autone/utils';
import { Box, Paper, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { getFileData } from '../../utils';

export default function RejectionFiles({
  fileRejections,
}: {
  fileRejections: any[];
}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {/* eslint-disable-next-line react/no-unused-prop-types */}
      {fileRejections.map(({ file, errors }: { file: any; errors: any[] }) => {
        const { path, size } = getFileData(file);

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size ? fData(size) : ''}
            </Typography>

            {errors.map((error) => (
              <Box
                key={error.code}
                component="li"
                sx={{ typography: 'caption' }}
              >
                {error.message}
              </Box>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}
