import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';
import { useMemo } from 'react';

import {
  initialSessionStorageData,
  type SessionStorageKeys,
  storage,
} from '../storage/batch-session-storage-atom';

import { type NormalizedFilters } from './normalizeFilters.types';

export type GeneralFiltersStorageKey = Extract<
  SessionStorageKeys,
  `${string}.${string}.generalFilters`
>;

const emptyAtom = atom<null | NormalizedFilters>(null);
/**
 * Use this atom to store general filters in session storage.
 * @param batchId - The id of the batch.
 * @param key - The key of the general filters storage.
 * @returns The atom with the general filters storage.
 */
export const useGeneralFiltersStorageAtom = (
  batchId: string,
  key?: GeneralFiltersStorageKey,
) => {
  const baseAtom = useMemo(
    () =>
      atomWithStorage(`batch-${batchId}`, initialSessionStorageData, storage, {
        getOnInit: true,
      }),
    [batchId],
  );

  const focusedAtom = useMemo(
    () => (key ? focusAtom(baseAtom, (optic) => optic.path(key)) : emptyAtom),
    [baseAtom, key],
  );

  return useAtom(key ? focusedAtom : emptyAtom);
};
