import { Box, Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui

// ----------------------------------------------------------------------

const DropDownButton = forwardRef(
  ({ children, isSelected, buttonSx, ...other }, ref) => {
    const theme = useTheme();
    return (
      <Button
        ref={ref}
        variant="soft"
        color="inherit"
        sx={{
          py: theme.spacing(0.9),
          border: `1px solid ${theme.palette.grey[500_32]}`,
          color: 'text.secondary',
          textTransform: 'none',
          width: { xs: 1, md: 'auto' },
          height: 'fit-content',
          justifyContent: 'flex-start',
          fontWeight: 'fontWeightMedium',
          ...(isSelected && {
            color: 'text.primary',
          }),
          ...buttonSx,
          '&:hover': {
            borderColor: theme.palette.grey[800],
            backgroundColor: 'transparent',
          },
        }}
        {...other}
      >
        {children}
        <Box sx={{ flexGrow: 1 }} />
      </Button>
    );
  },
);

export default DropDownButton;

DropDownButton.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  buttonSx: PropTypes.object,
};
