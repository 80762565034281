import { pxToRem } from '@autone/utils';
import {
  type TypographyOptions,
  type TypographyStyle,
  type Variant,
} from '@mui/material/styles/createTypography';

// ----------------------------------------------------------------------

const lineHeight = (fontSize: number): string => `${fontSize + 5}px`;

// const FONT_PRIMARY = 'Be Vietnam, sans-serif'; // Google Font
const FONT_PRIMARY = 'CircularStd, sans-serif'; // Local FontInter

const typographyCssProperties: {
  fontFamily: React.CSSProperties['fontFamily'];
  fontWeightRegular: React.CSSProperties['fontWeight'];
  fontWeightMedium: React.CSSProperties['fontWeight'];
  fontWeightBold: React.CSSProperties['fontWeight'];
} = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 500,
};

const typographyClasses: Partial<{ [key in Variant]: TypographyStyle }> = {
  h1: {
    fontWeight: 500,
    lineHeight: lineHeight(32),
    fontSize: pxToRem(32),
    letterSpacing: pxToRem(-0.72),
  },
  h2: {
    fontWeight: 500,
    lineHeight: lineHeight(24),
    fontSize: pxToRem(24),
    letterSpacing: pxToRem(-0.6),
  },
  h3: {
    fontWeight: 500,
    lineHeight: lineHeight(20),
    fontSize: pxToRem(20),
    letterSpacing: pxToRem(-0.48),
  },
  h4: {
    fontWeight: 700,
    lineHeight: lineHeight(18),
    fontSize: pxToRem(18),
    letterSpacing: pxToRem(-0.42),
  },
  h5: {
    fontWeight: 500,
    lineHeight: lineHeight(16),
    fontSize: pxToRem(16),
    letterSpacing: pxToRem(-0.42),
  },
  h6: {
    fontWeight: 500,
    lineHeight: lineHeight(14),
    fontSize: pxToRem(14),
    letterSpacing: pxToRem(-0.42),
  },
  // default text size
  body1: {
    fontWeight: 400,
    lineHeight: lineHeight(14),
    fontSize: pxToRem(14),
    letterSpacing: pxToRem(-0.42),
  },
  body2: {
    fontWeight: 400,
    lineHeight: lineHeight(12),
    fontSize: pxToRem(12),
    letterSpacing: pxToRem(-0.36),
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: lineHeight(14),
    fontSize: pxToRem(14),
    letterSpacing: pxToRem(-0.42),
  },
  subtitle2: {
    fontWeight: 500,
    lineHeight: lineHeight(12),
    fontSize: pxToRem(12),
    letterSpacing: pxToRem(-0.36),
  },
  caption: {
    lineHeight: lineHeight(12),
    fontSize: pxToRem(12),
    letterSpacing: pxToRem(-0.36),
  },
  button: {
    fontWeight: 500,
    lineHeight: lineHeight(14),
    fontSize: pxToRem(14),
    letterSpacing: pxToRem(-0.42),
    textTransform: 'capitalize',
  },
};

const typography: TypographyOptions = {
  ...typographyCssProperties,
  ...typographyClasses,
};

export default typography;
