export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '75%',
  width: '50%',
  display: 'flex',
  outline: 'none',
  zIndex: 1500,
};
