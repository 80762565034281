import { type LocationMetrics as RebalLocationMetrics } from '@autone/openapi-rebalancing';
import { type LocationMetrics as ReplenLocationMetrics } from '@autone/openapi-replenishment';
import { DEFAULT_DRAWER_WIDTH } from '@autone/utils';
import { atomWithStorage } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';

import {
  type PivotDimension,
  type SelectedAssortmentProduct,
} from './storage-atom.types';

export type EventsLocalStorageType = {
  selectedTab: 'calendar' | 'list';
  eventDateFilter: { from: Date | undefined; to: Date | undefined } | undefined;
};

export type StockAnalysisTableView = 'before' | 'after' | 'mixed';

export const REPLEN_KEY_METRIC_NAMES = [
  'replenishment-units',
  'replenishment-value',
  'stockout-reduction',
  'auto-submit-breakdown-chart',
  'sub-kpis',
] as const;

export type ReplenKeyMetricName = (typeof REPLEN_KEY_METRIC_NAMES)[number];

export const DEFAULT_MINIMUM_SKU_LIFE = 30;
export const DEFAULT_SHIPPING_TIME = 7;

export type LocalStorage = {
  // TODO properly type the rest of the local storage keys
  [key: string]: any;
  stickyKpis: {
    sidepanelWidth: number;
    open: boolean;
    visible: boolean;
  };
  initialAllocationAtom: SelectedAssortmentProduct[] | null;
  editMatrixAtom: SelectedAssortmentProduct[] | null;
  replenPivotDimensions: {
    primary: PivotDimension;
    secondary: PivotDimension | null;
  };
  replenPivotTableKpis: (keyof ReplenLocationMetrics)[];
  replenIncludeZeroReplenUnits: {
    includeZeroReplenUnits: boolean;
  };
  rebalPivotKpis: (keyof RebalLocationMetrics)[];
  replenLocationSidepanel: {
    showPreviousVersion: boolean;
  };
  replenPivotOptions: {
    showProductImages: boolean;
    showEntireAssortment: boolean;
  };
  replenAutoSubmitRules: {
    hidePromptAddAutoSubmitRuleMessage: boolean;
  };
  rebalPivotTableActions: {
    showProductImages: boolean;
  };
  rebalPivotOptions: {
    showEntireAssortment: boolean;
  };
  replenKeyMetricKpis: {
    visibleKpis: ReplenKeyMetricName[];
  };
  reorderShowWholesaleChannelData: boolean;
  rebalLocationSidepanel: {
    showPreviousVersion: boolean;
  };
  reorderShowDoubleAxis: boolean;
  rebalancingStockAnalysisView: StockAnalysisTableView;
  rebalProductSidepanel: {
    selectedTab: 'stock-analysis' | 'product-location-sizes';
  };
  eventsLocalStorage: EventsLocalStorageType;
  stockAnalysisTableTabs: {
    selectedTab:
      | 'before-auto-submit'
      | 'after-auto-submit'
      | 'after-all-replen';
  };
  rebalancingStrategyForm: {
    minimumSkuLifeDays: number;
    shippingTimeDays: number;
  };
};

export type LocalStorageKeys = keyof LocalStorage;

const localStorageInitialData: LocalStorage = {
  productSidePanelTableView: 'location-drilldown',
  replenProductsTable: null,
  replenLocationsTable: null,
  replenProductLocationsSkuTable: null,
  replenProductLocationsTable: null,
  replenProductLocationsInnerTable: null,
  replenLocationDepartmentsTable: null,
  replenLocationDepartmentsInnerTable: null,
  replenLocationProductsTable: null,
  replenLocationProductsSkuTable: null,
  replenAutoSubmitRules: {
    hidePromptAddAutoSubmitRuleMessage: false,
  },
  reorderProductsTable: null,
  reorderProductsTableSort: null,
  reorderSummaryTableSort: null,
  insightsRetailTables: null,
  insightsExplorerTable: null,
  rebalProductsTable: {
    showProductImages: true,
  },
  rebalLocationsDetailTable: {
    showProductImages: true,
    includeNilTransfers: false,
  },
  rebalTripDetailTable: {
    showProductImages: true,
  },
  rebalProductDetailTable: {
    includeNilTransfers: false,
  },
  stockAnalysisTableTabs: {
    selectedTab: 'before-auto-submit',
  },
  reorderSalesInventoryChart: {
    granularity: null,
    range: null,
  },
  reorderAssortmentTable: {
    dimension: null,
  },
  reorderStockOuts: {
    dimension: null,
  },
  reorderLast90DaySales: {
    dimension: null,
  },
  stickyKpis: {
    sidepanelWidth: DEFAULT_DRAWER_WIDTH,
    open: true,
    visible: false,
  },
  buyingStickyKpiPopover: { open: true },
  initialAllocationAtom: null,
  editMatrixAtom: null,
  replenLocationSidepanel: {
    showPreviousVersion: false,
  },
  replenPivotDimensions: {
    primary: 'department',
    secondary: 'product',
  },
  rebalPivotDimensions: {
    primary: 'department',
    secondary: 'product',
  },
  replenPivotOptions: {
    showProductImages: true,
    showEntireAssortment: false,
  },
  replenKeyMetricKpis: {
    visibleKpis: [
      'replenishment-units',
      'replenishment-value',
      'stockout-reduction',
      'auto-submit-breakdown-chart',
    ],
  },
  rebalPivotOptions: {
    showEntireAssortment: false,
  },
  rebalPivotTableActions: {
    showProductImages: true,
  },
  replenPivotTableKpis: [
    'l90d_sales_quantity',
    'l7d_sales_quantity',
    'auto_submitted_quantity',
    'manual_replenishment_quantity',
    'current_location_stock',
    'current_coverage_vs_target',
    'current_stock_out_pc',
    'current_coverage_weeks',
    'forecasted_weekly_sales_quantity',
    'forecasted_weekly_sales_vs_l30d',
    'proposed_coverage_vs_target',
    'proposed_stock_out_pc',
    'proposed_coverage_weeks',
    'current_warehouse_coverage_weeks',
    'proposed_warehouse_coverage_weeks',
  ],
  replenIncludeZeroReplenUnits: {
    includeZeroReplenUnits: false,
  },
  rebalPivotKpis: [
    'current_location_stock',
    'l30d_sales_quantity',
    'need_quantity',
    'current_coverage_weeks',
    'receiving_quantity',
    'proposed_coverage_weeks',
    'proposed_coverage_vs_target',
  ],
  reorderShowWholesaleChannelData: true,
  rebalLocationSidepanel: {
    showPreviousVersion: false,
  },
  reorderShowDoubleAxis: false,
  rebalancingStockAnalysisView: 'before',
  rebalProductSidepanel: {
    selectedTab: 'product-location-sizes',
  },
  eventsLocalStorage: {
    selectedTab: 'calendar',
    eventDateFilter: undefined,
  },
  rebalancingStrategyForm: {
    minimumSkuLifeDays: DEFAULT_MINIMUM_SKU_LIFE,
    shippingTimeDays: DEFAULT_SHIPPING_TIME,
  },
  selectedBudgetPeriod: null,
};

const localStorageAtom = atomWithStorage(
  'autone-store',
  localStorageInitialData,
  {
    getItem: (key) => {
      const value = localStorage.getItem(key);

      if (!value) {
        return localStorageInitialData;
      }

      // in order to always initialise new keys with their defaults, we add keys from localStorageInitialData if they don't exist
      const parsedValue = JSON.parse(value);

      for (const [defaultKey, defaultValue] of Object.entries(
        localStorageInitialData,
      )) {
        if (!(defaultKey in parsedValue)) {
          parsedValue[defaultKey] = defaultValue;
        }
      }

      return parsedValue as LocalStorage;
    },
    setItem: <K extends LocalStorageKeys>(key: K, data: LocalStorage[K]) => {
      const value = data ? JSON.stringify(data) : null;
      if (value) {
        localStorage.setItem(key as string, value);
      } else {
        // eslint-disable-next-line no-console
        console.error('Error setting localStorage');
      }
    },
    removeItem: () => {},
  },
  { getOnInit: true },
);

export const getLocalStorageAtom = <K extends LocalStorageKeys>(key: K) =>
  focusAtom(localStorageAtom, (optic) => optic.prop(key));
