import { capitalizeFirstLetter } from '@autone/utils';
import CloseIcon from '@mui/icons-material/Close';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { forwardRef, type ReactNode } from 'react';

import { useSearchField } from '../../../hooks';
import LightTooltip from '../../LightTooltip';
import {
  type FullScreenContainer,
  type TableConfigType,
} from '../../table/types';
import useCustomColumns from '../hooks/useCustomColumns';
import { CardLayout, modalStyle } from '../layout';

import AvailableMetrics from './AvailableMetrics';
import SelectedColumns from './SelectedColumns';

export type CustomColumnsLabels = {
  title: string;
  tooltip: string;
  confirm: string;
  cancel: string;
  metricsTitle: string;
  metricsErrorText: string;
  selectedColumnsTitle: string;
  dragToReorder: string;
  search: string;
  wrapperLabel: string;
};

const Wrapper = forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode;
    handleOpen: () => void;
    renderLabel?: boolean;
    wrapperLabel: string;
    wrapperTooltip: string;
  }
>(
  (
    { children, handleOpen, renderLabel, wrapperLabel, wrapperTooltip },
    ref,
  ) => {
    if (renderLabel) {
      return (
        <Button color="inherit" ref={ref} onClick={handleOpen}>
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={0.5}
          >
            {children}
            <Typography>{wrapperLabel}</Typography>
          </Stack>
        </Button>
      );
    }

    return (
      <LightTooltip title={wrapperTooltip}>
        <IconButton ref={ref} onClick={handleOpen}>
          {children}
        </IconButton>
      </LightTooltip>
    );
  },
);

const CustomColumns = ({
  labels = {
    title: 'Customise Columns',
    tooltip: 'customise columns',
    confirm: 'Confirm',
    cancel: 'Cancel',
    metricsTitle: 'Metrics',
    metricsErrorText: 'Metric limit reached',
    selectedColumnsTitle: 'Selected Columns',
    dragToReorder: 'Drag to Reorder',
    search: 'Search',
    wrapperLabel: 'Adjust custom columns',
  },
  getCustomColumnsSelectedLabel,
  customColumns,
  columnLimit,
  handleSetCustomColumns,
  isFullScreen,
  fullScreenContainer,
  renderLabel,
  clearText = 'clear text',
  noResultsMessage = 'No results',
}: {
  labels?: CustomColumnsLabels;
  getCustomColumnsSelectedLabel?: (
    activeColumns: number | undefined,
    columnLimit: number | undefined,
  ) => string;
  customColumns: TableConfigType<any>[];
  columnLimit?: number;
  handleSetCustomColumns: (customColumns: TableConfigType<any>[]) => void;
  isFullScreen?: boolean;
  fullScreenContainer?: FullScreenContainer;
  renderLabel?: boolean;
  clearText?: string;
  noResultsMessage?: string;
}) => {
  const {
    metricsList,
    open,
    handleOpen,
    handleClose,
    columnsAreDisabled,
    handleOnDragEndCallback,
    handleConfirmColumnSettings,
    handleCancelColumnSettings,
    handleColumnSelect,
    activeColumns,
    draggedCustomColumns,
  } = useCustomColumns({
    customColumns,
    columnLimit,
    handleSetCustomColumns,
  });

  const { searchInput, handleSearchInputChange, handleClearSearchInput } =
    useSearchField();

  return (
    <>
      <Wrapper
        handleOpen={handleOpen}
        renderLabel={renderLabel}
        wrapperLabel={labels.wrapperLabel}
        wrapperTooltip={labels.tooltip}
      >
        <ViewWeekOutlinedIcon />
      </Wrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-custom-columns"
        aria-describedby="modalcustom-columns"
        container={
          isFullScreen && fullScreenContainer
            ? fullScreenContainer.current
            : undefined
        }
      >
        <Box sx={modalStyle}>
          <CardLayout
            header={
              <Stack direction="row" alignItems="center">
                <Typography variant="h3">
                  {capitalizeFirstLetter(labels.title)}
                </Typography>
                <IconButton
                  sx={{ ml: 'auto' }}
                  onClick={handleCancelColumnSettings}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            }
            contentLeft={
              <AvailableMetrics
                metricsList={metricsList}
                handleSearchInputChange={handleSearchInputChange}
                handleClearSearchInput={handleClearSearchInput}
                searchInput={searchInput}
                columnsAreDisabled={columnsAreDisabled}
                handleColumnSelect={handleColumnSelect}
                metricsTitle={labels.metricsTitle}
                metricsErrorText={labels.metricsErrorText}
                searchPlaceholder={labels.search}
                clearText={clearText}
                noResultsMessage={noResultsMessage}
              />
            }
            contentRight={
              <SelectedColumns
                customColumns={draggedCustomColumns}
                handleOnDragEndCallback={handleOnDragEndCallback}
                handleColumnSelect={handleColumnSelect}
                activeColumns={activeColumns}
                columnLimit={columnLimit}
                selectedColumnsTitle={labels.selectedColumnsTitle}
                dragToReorder={labels.dragToReorder}
                getCustomColumnsSelectedLabel={getCustomColumnsSelectedLabel}
              />
            }
            footer={
              <>
                <Button
                  onClick={handleConfirmColumnSettings}
                  variant="contained"
                >
                  {labels.confirm}
                </Button>
                <Button onClick={handleCancelColumnSettings} variant="outlined">
                  {labels.cancel}
                </Button>
              </>
            }
          />
        </Box>
      </Modal>
    </>
  );
};

export default CustomColumns;
