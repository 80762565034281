import PropTypes from 'prop-types';

const InsightsIcon = ({ size = 22, color = '#01172F' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 28"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.194 17.5447C20.7734 17.5447 21.243 18.0143 21.243 18.5936C21.243 19.1246 20.8484 19.5635 20.3364 19.633L20.194 19.6425H11.8026C11.2233 19.6425 10.7537 19.1729 10.7537 18.5936C10.7537 18.0626 11.1483 17.6237 11.6603 17.5543L11.8026 17.5447H20.194Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.67961 9.82901C7.31947 5.72039 10.73 2.44725 14.8664 1.96275C20.5334 1.2976 25.443 5.69529 25.443 11.3358C25.443 14.4372 23.9315 17.2843 21.4428 19.0428C21.3116 19.1354 21.2473 19.2501 21.2473 19.3539V23.2237C21.2473 25.7345 19.2126 27.7691 16.7019 27.7691H15.3033C12.7926 27.7691 10.758 25.7345 10.758 23.2237V19.3595C10.758 19.2513 10.692 19.1336 10.5596 19.0399C7.64932 16.9834 6.1121 13.4792 6.67961 9.82901ZM20.231 17.3296C22.1698 15.9598 23.344 13.748 23.344 11.3361C23.344 6.94764 19.5265 3.52813 15.1095 4.04656C11.9074 4.42163 9.246 6.97583 8.75139 10.1518C8.30793 13.0041 9.50121 15.7242 11.7697 17.3272C12.4389 17.8007 12.8547 18.5435 12.8547 19.3597V23.2239C12.8547 24.5761 13.9501 25.6715 15.3022 25.6715H16.7008C18.0529 25.6715 19.1483 24.5761 19.1483 23.2239V19.3541C19.1483 18.5412 19.5634 17.8013 20.231 17.3296Z"
      fill={color}
    />
    <path
      d="M28.1125 1.93653C28.5222 1.52689 29.1863 1.52689 29.5959 1.93653C29.9683 2.30892 30.0022 2.89166 29.6975 3.30229L29.5959 3.41994L27.9316 5.08425C27.522 5.49388 26.8578 5.49388 26.4482 5.08425C26.0758 4.71185 26.042 4.12912 26.3467 3.71848L26.4482 3.60083L28.1125 1.93653Z"
      fill={color}
    />
    <path
      d="M4.06906 17.5863C4.4787 17.1766 5.14284 17.1766 5.55248 17.5863C5.92487 17.9587 5.95872 18.5414 5.65404 18.952L5.55248 19.0697L3.88817 20.734C3.47854 21.1436 2.81439 21.1436 2.40476 20.734C2.03237 20.3616 1.99851 19.7789 2.3032 19.3682L2.40476 19.2506L4.06906 17.5863Z"
      fill={color}
    />
    <path
      d="M3.80478 0.538121C4.17718 0.165727 4.75991 0.131873 5.17055 0.436559L5.2882 0.538121L6.9525 2.20243C7.36213 2.61206 7.36213 3.27621 6.9525 3.68584C6.58011 4.05823 5.99737 4.09209 5.58673 3.7874L5.46909 3.68584L3.80478 2.02153C3.39515 1.6119 3.39515 0.947754 3.80478 0.538121Z"
      fill={color}
    />
    <path
      d="M26.4482 17.5863C26.8206 17.2139 27.4033 17.18 27.814 17.4847L27.9316 17.5863L29.5959 19.2506C30.0056 19.6602 30.0056 20.3244 29.5959 20.734C29.2235 21.1064 28.6408 21.1402 28.2302 20.8356L28.1125 20.734L26.4482 19.0697C26.0386 18.6601 26.0386 17.9959 26.4482 17.5863Z"
      fill={color}
    />
    <path
      d="M30.9504 10.2854C31.5297 10.2854 31.9993 10.7551 31.9993 11.3344C31.9993 11.8654 31.6047 12.3043 31.0927 12.3737L30.9504 12.3833H28.5868C28.0075 12.3833 27.5378 11.9137 27.5378 11.3344C27.5378 10.8033 27.9325 10.3645 28.4444 10.295L28.5868 10.2854H30.9504Z"
      fill={color}
    />
    <path
      d="M3.41326 10.2854C3.99256 10.2854 4.46219 10.7551 4.46219 11.3344C4.46219 11.8654 4.06757 12.3043 3.55559 12.3737L3.41326 12.3833H1.04966C0.470355 12.3833 0.000732422 11.9137 0.000732422 11.3344C0.000732422 10.8033 0.395346 10.3645 0.90733 10.295L1.04966 10.2854H3.41326Z"
      fill={color}
    />
    <path
      d="M20.0122 21.4759C20.5915 21.4759 21.0612 21.9455 21.0612 22.5248C21.0612 23.0558 20.6665 23.4947 20.1546 23.5641L20.0122 23.5737H11.8026C11.2233 23.5737 10.7537 23.1041 10.7537 22.5248C10.7537 21.9938 11.1483 21.5549 11.6603 21.4854L11.8026 21.4759H20.0122Z"
      fill={color}
    />
  </svg>
);

InsightsIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default InsightsIcon;
