import { Box, Skeleton, Stack } from '@mui/material';
import * as PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

export default function SidebarLogo({
  logo,
  sx,
  align = 'center',
}: {
  logo?: string;
  sx?: any;
  align?: string;
}) {
  const history = useHistory();

  return (
    <>
      {logo ? (
        <Stack
          sx={{
            display: 'flex',
            alignItems: align,
            justifyContent: 'center',
          }}
        >
          <Box
            component="img"
            alt="logo"
            src={logo}
            sx={{
              cursor: 'pointer',
              height: 'auto',
              ...sx,
            }}
            onClick={() => history.push('/')}
          />
        </Stack>
      ) : (
        <Skeleton sx={{ height: '25px' }} />
      )}
    </>
  );
}

SidebarLogo.propTypes = {
  sx: PropTypes.object,
  logo: PropTypes.string,
  align: PropTypes.string,
};
