import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import Icon from '@iconify/react';
import { Box, Hidden, IconButton, useTheme } from '@mui/material';

type Props = {
  setOpen: (open: boolean) => void;
};

const MenuIconButton = ({ setOpen }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        left: theme.spacing(1.5),
        top: theme.spacing(1.5),
      }}
    >
      <Hidden lgUp>
        <IconButton
          onClick={() => setOpen(true)}
          sx={{ mr: theme.spacing(1), color: 'text.primary', zIndex: 100 }}
          size="large"
        >
          <Icon icon={menu2Fill} />
        </IconButton>
      </Hidden>
    </Box>
  );
};

export default MenuIconButton;
