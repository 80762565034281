import { AUTH_CHECK_TIMEOUT } from '@autone/utils';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';

import { UPDATE_USER } from '../redux/user';

const useCheckIsUserAuthenticated = ({
  setAuthCheck,
  setToken,
}: {
  setAuthCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const dispatch = useDispatch();

  const checkUserAuthentication = async () => {
    try {
      const { tokens } = await fetchAuthSession();
      if (!tokens) return;
      const attributes = await fetchUserAttributes();
      const idToken = tokens?.idToken?.toString();
      const accessToken = tokens?.accessToken?.toString();

      // update redux store with user attributes and token
      // if attributes already exist we use them otherwise we use the federated login attributes object
      dispatch(
        UPDATE_USER({
          attributes,
          idToken,
          accessToken,
        }),
      );

      // set token in component for app level permissions check
      setToken(idToken);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    } finally {
      // wait for a second until dispatch has completed update
      setTimeout(() => {
        setAuthCheck(true);
      }, AUTH_CHECK_TIMEOUT);
    }
  };

  return { checkUserAuthentication };
};

export default useCheckIsUserAuthenticated;
