import type * as PopoverPrimitive from '@radix-ui/react-popover';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import * as React from 'react';
import { type Matcher } from 'react-day-picker';

import { Button } from './Button';
import { DatePickerCalendar } from './DatePickerCalendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { cn } from './utils';

export type DisabledDays = Matcher;

interface DatePickerProps {
  date?: Date;
  placeholderText: string;
  onChange: (...event: any) => void;
  className?: string;
  inputClassName?: string;
  disabledInput?: boolean;
  disabledDays?: DisabledDays;
  defaultMonth?: Date;
  id?: string;
}

const DatePicker = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  DatePickerProps & PopoverPrimitive.PopoverProps
>(
  ({
    id,
    date,
    placeholderText,
    onChange,
    className,
    inputClassName,
    disabledInput,
    disabledDays,
    defaultMonth,
    ...props
  }) => {
    return (
      <Popover {...props}>
        <PopoverTrigger asChild>
          <Button
            id={id}
            data-testid={`${id}-trigger`}
            variant="outlined"
            color="secondary"
            disabled={disabledInput}
            className={cn(
              'w-[340px] justify-between text-left font-normal text-sm h-[52px]',
              !date && 'text-grey-60',
              inputClassName,
            )}
          >
            {date ? format(date, 'dd/MM/yyyy') : placeholderText}
            <CalendarIcon className="mr-2 h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <DatePickerCalendar
            data-testid={`${id}-calendar`}
            mode="single"
            selected={date}
            onSelect={onChange}
            showOutsideDays={true}
            className={className}
            disabled={disabledDays}
            defaultMonth={defaultMonth}
          />
        </PopoverContent>
      </Popover>
    );
  },
);
DatePicker.displayName = 'DatePicker';

export { DatePicker as TailwindDatePicker };
