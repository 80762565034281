import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const LoadMoreButton = ({ isLoading, loadMoreItems, label = 'Load More' }) => (
  <Box
    sx={{
      mt: 1,
      mr: 1,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Button loading={isLoading} variant="outlined" onClick={loadMoreItems}>
      {label}
    </Button>
  </Box>
);

export default LoadMoreButton;

LoadMoreButton.propTypes = {
  isLoading: PropTypes.bool,
  loadMoreItems: PropTypes.func,
  label: PropTypes.string,
};
