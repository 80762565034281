import { createContext, type PropsWithChildren, useContext } from 'react';

type MultiSelectContextProps = {
  toggleSelected: (selected: string) => void;
  handleClear: () => void;
  selected: string[];
  toggleAll: (optionIds: string[]) => void;
  onSelectedChange: (selected: string[]) => void;
};

export const MultiSelectContext = createContext<MultiSelectContextProps | null>(
  null,
);

export const MultiSelectContextProvider = ({
  children,
  selected,
  onSelectedChange,
}: PropsWithChildren<{
  selected: string[];
  onSelectedChange: (selected: string[]) => void;
}>) => {
  const toggleSelected = (selectedValue: string) => {
    const newSelectedValues = selected.includes(selectedValue)
      ? selected.filter((value) => value !== selectedValue)
      : [...selected, selectedValue];
    onSelectedChange(newSelectedValues);
  };

  const handleClear = () => {
    onSelectedChange([]);
  };

  const toggleAll = (optionIds: string[]) => {
    if (selected.length === optionIds.length) {
      handleClear();
    } else {
      onSelectedChange(optionIds);
    }
  };

  return (
    <MultiSelectContext.Provider
      value={{
        toggleSelected,
        handleClear,
        selected,
        toggleAll,
        onSelectedChange,
      }}
    >
      {children}
    </MultiSelectContext.Provider>
  );
};

export const useMultiSelectContext = () => {
  const context = useContext(MultiSelectContext);
  if (!context) {
    throw new Error(
      'useMultiSelectContext must be used within a MultiSelectContextProvider',
    );
  }
  return context;
};
