import { fNumberWithCommas } from '@autone/utils';
import { Box, Card, CardHeader, Skeleton, Stack } from '@mui/material';
import PropTypes from 'prop-types';

import ProgressItem from './ProgressItem';
import SimpleKPIGroup from './SimpleKPIGroup';

const ProgressCard = ({
  height,
  title,
  progressData,
  kpiData,
  headingSize = 'h2',
  progressItemSpacing,
  cardSX,
}) => (
  <Card
    sx={{
      width: '100%',
      height: height || 400,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
  >
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'h4' }}
        sx={{ mb: 2 }}
      />
      {progressData ? (
        <>
          <Box sx={{ p: 1, height: '100%' }}>
            <Stack
              spacing={progressItemSpacing}
              sx={{ px: 3, pb: 3, height: '100%', justifyContent: 'center' }}
            >
              {progressData.map(
                ({ title, value, percentage, variant }, index) => (
                  <ProgressItem
                    key={index}
                    title={title}
                    value={Number.isNaN(value) ? 0 : fNumberWithCommas(value)}
                    percentage={Number.isNaN(percentage) ? 0 : percentage}
                    variant={variant}
                  />
                ),
              )}
            </Stack>
          </Box>
          <Box sx={{ mb: 0.3 }}>
            <SimpleKPIGroup
              kpiData={kpiData}
              headingSize={headingSize}
              cardSX={cardSX}
              showDivider
            />
          </Box>
        </>
      ) : (
        <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
          {Array(8)
            .fill(1)
            .map((_num, i) => (
              <Skeleton key={i} height={40} animation="wave" />
            ))}
        </Box>
      )}
    </Box>
  </Card>
);

ProgressCard.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  progressData: PropTypes.array,
  kpiData: PropTypes.array,
  headingSize: PropTypes.string,
  progressItemSpacing: PropTypes.number,
  cardSX: PropTypes.object,
};

export default ProgressCard;
