import { type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../utils';

import { inputVariants } from './inputVariants';

export interface NumberInputProps
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'type' | 'inputMode' | 'pattern' | 'onChange' | 'value'
    >,
    VariantProps<typeof inputVariants> {
  onChange: (
    value: number | null,
    e?: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  value: number | null;
}

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ className, variant, onChange, value, ...props }, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return;
      }
      onChange(value === '' ? null : numberValue);
    };

    return (
      <input
        {...props}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        onChange={handleChange}
        value={value ?? ''}
      />
    );
  },
);
NumberInput.displayName = 'NumberInput';

export { NumberInput };
