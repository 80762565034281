import chevronDown from '@iconify/icons-eva/chevron-down-fill';
import chevronUp from '@iconify/icons-eva/chevron-up-fill';
import { Box, Button, FormControl, Skeleton, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import Iconify from '../Iconify';
import LightTooltip from '../LightTooltip';
import MenuPopover from '../popover';

const Dropdown = ({
  children,
  title,
  value,
  currentLabel,
  isFullScreen,
  sx,
  popoverSx,
}) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ minWidth: 60 }}>
      {children ? (
        <FormControl fullWidth>
          <LightTooltip title={title}>
            <Button
              ref={anchorRef}
              variant="soft"
              color="inherit"
              onClick={(event) => handleOpen(event.currentTarget)}
              endIcon={
                <Iconify
                  color={theme.palette.grey[600]}
                  icon={open ? chevronUp : chevronDown}
                />
              }
              sx={{
                py: theme.spacing(0.95),
                textTransform: 'none',
                border: `1px solid ${theme.palette.grey[500_32]}`,
                '&:hover': {
                  borderColor: theme.palette.grey[800],
                },
                ...sx,
              }}
            >
              {currentLabel || value}
            </Button>
          </LightTooltip>

          <MenuPopover
            anchorEl={anchorRef.current}
            container={isFullScreen && anchorRef.current}
            open={Boolean(open)}
            onClose={handleClose}
            sx={{
              width: 'auto',
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
              overflow: 'scroll',
              ...popoverSx,
            }}
          >
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                onClick: () => {
                  child?.props?.onClick();
                  handleClose();
                },
              }),
            )}
          </MenuPopover>
        </FormControl>
      ) : (
        <Skeleton />
      )}
    </Box>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentLabel: PropTypes.string,
  isFullScreen: PropTypes.bool,
  sx: PropTypes.object,
  popoverSx: PropTypes.object,
};
