import { generatePath } from './generate-paths';

export const INSIGHTS_ROOT = '/insights';
// ----------------------------------------------------------------------

export const INSIGHTS_PATH_APP: { [key: string]: string } = {
  root: generatePath(INSIGHTS_ROOT, '/retail'),
  bestsellers: generatePath(INSIGHTS_ROOT, '/bestsellers'),
  explorer: generatePath(INSIGHTS_ROOT, '/explorer'),
};
