import { NO_ACCESS_TEXT, SUBMITTED_TEXT } from '@autone/utils';
import { createContext, useContext } from 'react';

import { usePermissions } from '../hooks';
import { type Disabled } from '../types';

type Props = {
  children: React.ReactNode;
  standardPermission: string;
  adminPermission: string;
  isSubmitted?: boolean;
  noAccessText?: string;
  submittedText?: string;
};

type PermissionsContextType = {
  userReadOnlyPermission: Disabled;
  userNotAnAdminPermission: Disabled;
  userNotAnAdminExclSubmissionPermission?: Disabled;
  isSubmitted?: boolean;
};

// define context with default values
export const PermissionsContext = createContext<PermissionsContextType>({
  userReadOnlyPermission: { disabled: true, disabledText: undefined },
  userNotAnAdminPermission: { disabled: true, disabledText: undefined },
  userNotAnAdminExclSubmissionPermission: {
    disabled: true,
    disabledText: undefined,
  },
});

export const PermissionsContextWrapper = ({
  children,
  standardPermission,
  adminPermission,
  isSubmitted = false,
  noAccessText = NO_ACCESS_TEXT,
  submittedText = SUBMITTED_TEXT,
}: Props) => {
  const { isReadOnly, isNotAnAdmin, isAdminExclSubmission } = usePermissions({
    isSubmitted,
    standardPermission,
    adminPermission,
    noAccessText,
    submittedText,
  });

  return (
    <PermissionsContext.Provider
      value={{
        userReadOnlyPermission: isReadOnly,
        userNotAnAdminPermission: isNotAnAdmin,
        userNotAnAdminExclSubmissionPermission: isAdminExclSubmission,
        isSubmitted,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error(
      'usePermissionsContext must be used within the PermissionsContextWrapper',
    );
  }

  return context;
};
