import { capitalizeFirstLetter } from '@autone/utils';
import { Checkbox, MenuItem, Stack, Typography } from '@mui/material';
import { CircleHelp } from 'lucide-react';
import type { HTMLAttributes, ReactElement } from 'react';

import { AutoneTooltip } from '../../tailwind';

import { type Option } from './MultiSelect.types';

type FormOption<P> = {
  option: P;
  optionProps: HTMLAttributes<HTMLLIElement>;
  selected: boolean;
  showId?: boolean;
  itemSlot?: ({ option }: { option: P }) => ReactElement;
  disabled?: boolean;
  disabledReason?: string;
};

const MultiSelectFormOption = <P extends Option>({
  option,
  optionProps,
  selected,
  showId,
  itemSlot,
  disabled,
  disabledReason,
}: FormOption<P>) => {
  const { id, description } = option;
  const sharedSx = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
  };

  if (itemSlot)
    return (
      <MenuItem
        key={`${id}-${description}`}
        selected={selected}
        {...optionProps}
        className={'py-0'}
      >
        {itemSlot({
          option,
        })}
      </MenuItem>
    );

  return (
    <MenuItem
      key={`${id}-${description}`}
      selected={selected}
      disabled={disabled}
      {...optionProps}
    >
      <Checkbox sx={{ p: 0, mr: 1 }} checked={selected} />
      <Stack sx={{ width: '90%' }}>
        <Typography noWrap sx={sharedSx}>
          {capitalizeFirstLetter(description)}
        </Typography>
        {showId && (
          <Typography color="text.secondary" noWrap sx={sharedSx}>
            {id}
          </Typography>
        )}
      </Stack>
      {disabled && disabledReason && (
        <div
          className="pointer-events-auto"
          // Ensure that clicking on the tooltip icon
          // does not select the MenuItem
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <AutoneTooltip
            text={disabledReason}
            side="right"
            sideOffset={20}
            className="w-40"
            asChild
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <CircleHelp className="h-4 w-4" />
          </AutoneTooltip>
        </div>
      )}
    </MenuItem>
  );
};

export default MultiSelectFormOption;
