import { type CommonTranslationFunction } from '@autone/translations';
import { type VisibilityState } from '@tanstack/react-table';
import { atom } from 'jotai';

export const overviewTabAtom = atom<OverviewTabType>('ongoing');

export type BatchStatuses =
  | 'created'
  | 'running'
  | 'ready'
  | 'failed'
  | 'archived_ready'
  | 'submitted'
  | 'archived_submitted'
  | 'deleted'
  | 'ready_to_view';

export type OverviewTabType = 'ongoing' | 'submitted' | 'upcoming' | 'failed';
type TabConfig =
  | {
      value: Exclude<OverviewTabType, 'upcoming'>;
      label: string;
      statuses: ('ready' | 'submitted' | 'failed')[];
    }
  | { value: 'upcoming'; label: string; statuses: null };

export const getOverviewTableTabs = (
  common: CommonTranslationFunction,
): TabConfig[] => [
  {
    value: 'ongoing',
    label: common('tabs.ongoing'),
    statuses: ['ready'],
  },
  {
    value: 'failed',
    label: common('tabs.failed'),
    statuses: ['failed'],
  },
  {
    value: 'upcoming',
    label: common('tabs.upcoming'),
    statuses: null,
  },
  {
    value: 'submitted',
    label: common('tabs.submitted'),
    statuses: ['submitted'],
  },
];

const ongoingBatches = new Set(['created', 'running', 'ready']);

export const hasOngoingStatus = (statuses: BatchStatuses[]) =>
  statuses?.some((s) => ongoingBatches.has(s));

export const hasSubmittedStatus = (statuses: BatchStatuses[]) =>
  statuses.includes('submitted');

export const hasArchivedSubmittedStatus = (statuses: BatchStatuses[]) =>
  statuses.includes('archived_submitted');

export const hasArchivedReadyStatus = (statuses: BatchStatuses[]) =>
  statuses.includes('archived_ready');

export const hasFailedStatus = (statuses: BatchStatuses[]) =>
  statuses.includes('failed');

export const getVisibleOverviewTableColumns = (
  statuses: BatchStatuses[],
): VisibilityState => {
  if (hasArchivedReadyStatus(statuses)) {
    return {
      submitted_at: false,
      created_at: false,
      sales_uplift: false,
      transfer_value: false,
      transfer_quantity: false,
      unique_trips: false,
      rebal_type: false,
      warehouse: false,
      reorder_units: false,
      reorder_value: false,
      est_sales_increase: false,
      locations: false,
      replen_units: false,
      error_reason: false,
    };
  } else if (hasOngoingStatus(statuses)) {
    return {
      submitted_at: false,
      archived_at: false,
      error_reason: false,
    };
  } else if (
    hasArchivedSubmittedStatus(statuses) ||
    hasSubmittedStatus(statuses)
  ) {
    return {
      archived_at: false,
      error_reason: false,
    };
  } else if (hasFailedStatus(statuses)) {
    return {
      submitted_at: false,
      archived_at: false,
      est_sales_increase: false,
      locations: false,
      replen_units: false,
    };
  } else return {};
};

export const closeMoreActionsPopover = (batchId: string) => {
  const closeEvent = new CustomEvent('table-row-popover-close', {
    detail: { id: batchId },
  });
  document.dispatchEvent(closeEvent);
};

export const archivedTabDict: Record<
  Exclude<OverviewTabType, 'upcoming' | 'deleted' | 'failed'>,
  ('archived_ready' | 'archived_submitted')[]
> = {
  ongoing: ['archived_ready'],
  submitted: ['archived_submitted'],
};
