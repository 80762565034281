export const createListWithSubheaders = (array = [], groupingKey) => {
  // Get distinct groups and remove undefined values
  const distinctGroups = (array || [])
    .map((item) => item[groupingKey])
    .filter(
      (value, index, self) =>
        self.indexOf(value) === index && value !== undefined,
    );

  // If there are no distinct groups, return the original array
  if (distinctGroups.length === 0) {
    return array;
  }

  // Create the list
  const list = [];

  // Iterate over distinct groups
  distinctGroups.forEach((group) => {
    // Add subheaders to the list
    list.push({ [groupingKey]: group, isSubheader: true });

    // Filter objects with the current group
    const groupObjects = array.filter((obj) => obj[groupingKey] === group);

    // Add objects with the current group to the list
    groupObjects.forEach((obj) => {
      list.push({ ...obj, isSubheader: false });
    });
  });

  return list;
};
