import { capitalizeFirstLetter } from '@autone/utils';
import {
  Box,
  type CustomTheme,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  type SxProps,
  useTheme,
} from '@mui/material';
import React from 'react';

import { getIsActive } from '../../../utils/getIsActive';
import { SubListItemStyles } from '../styles';

import { type SidebarItemOptions } from './SidebarConfig';
import SidebarLink from './SidebarLink';

const SidebarSubItem = ({
  options,
  currentPathname,
  isDisabled,
  isInsightsLink,
}: {
  options?: SidebarItemOptions[];
  currentPathname: string;
  isDisabled: boolean;
  isInsightsLink: boolean;
}) => {
  const theme: CustomTheme = useTheme();
  return (
    <Box sx={{ py: 1, px: 1.5 }}>
      {options?.map((item) => {
        const { title, href, icon, path } = item;
        const isActive = getIsActive({ href, path, currentPathname });

        return (
          <SidebarLink
            key={title}
            to={href}
            path={path}
            isDisabled={isDisabled}
            isInsightsLink={isInsightsLink}
          >
            <ListItemButton
              disableGutters
              className="isActiveRoot"
              sx={
                {
                  ...SubListItemStyles(theme),
                  ...(isActive && {
                    color: theme.palette.primary.main,
                  }),
                } as SxProps
              }
            >
              <ListItemIcon>{icon as React.ReactNode}</ListItemIcon>
              <ListItemText
                sx={{ textTransform: 'none' }}
                disableTypography
                primary={capitalizeFirstLetter(title)}
              />
            </ListItemButton>
          </SidebarLink>
        );
      })}
    </Box>
  );
};

export default SidebarSubItem;
