import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { Box, Stack, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import Label from '../label';
import Popover from '../popover';

import DropDownButton from './DropDownButton';
import DropdownMenuContents from './DropdownMenuContents';

// ----------------------------------------------------------------------

export default function DropDownIconMenu({
  options,
  onOptionSelection,
  maxValues = 2,
  placeholder = 'All',
  maxHeight = '250px',
  isFullScreen = false,
  buttonSx,
}) {
  const anchorRef = useRef(null);
  const theme = useTheme();

  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  // get the active options and the length of the active options
  const activeOptions = options?.filter((item) => item?.active);
  const activeOptionsLength = activeOptions?.length;
  const isSelected = activeOptionsLength > 0;

  const renderLabel = isSelected
    ? activeOptions
        .map(({ title }) => title)
        .slice(0, maxValues)
        .join(', ')
    : placeholder;

  return (
    <>
      <DropDownButton
        ref={anchorRef}
        buttonSx={buttonSx}
        isSelected={isSelected}
        endIcon={
          openPopover ? (
            <KeyboardArrowUpRoundedIcon
              sx={{
                color: theme.palette.grey[600],
                fontSize: `${theme.icons.md}px`,
              }}
            />
          ) : (
            <KeyboardArrowDownRoundedIcon
              sx={{
                color: theme.palette.grey[600],
                fontSize: `${theme.icons.md}px`,
              }}
            />
          )
        }
        onClick={handleOpenPopover}
      >
        <Box sx={{ py: theme.spacing(0.2) }}>{renderLabel}</Box>
        {activeOptionsLength > maxValues && (
          <Label color="info" sx={{ ml: theme.spacing(1) }}>
            +{activeOptionsLength - maxValues}
          </Label>
        )}
      </DropDownButton>
      <Popover
        open={!!openPopover}
        anchorEl={anchorRef.current}
        container={isFullScreen && anchorRef.current}
        onClose={handleClosePopover}
        sx={{ p: theme.spacing(2.5), width: 'fit-content' }}
      >
        <Stack
          spacing={2.5}
          direction="row"
          sx={{ maxHeight, overflow: 'scroll' }}
        >
          <DropdownMenuContents
            options={options}
            onOptionSelection={onOptionSelection}
          />
        </Stack>
      </Popover>
    </>
  );
}

DropDownIconMenu.propTypes = {
  options: PropTypes.array,
  maxValues: PropTypes.number,
  onOptionSelection: PropTypes.func,
  placeholder: PropTypes.string,
  maxHeight: PropTypes.string,
  isFullScreen: PropTypes.bool,
  buttonSx: PropTypes.object,
};
