import { useAutoneTranslation } from '@autone/translations';
import { fPercentage, fReadableCurrency } from '@autone/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, type HTMLAttributes, type PropsWithChildren } from 'react';

import { useDefaultCurrency } from '../../hooks';
import { cn } from '../utils';

import { isFiniteNumber } from './DataTableCells.helpers';

const DataTableCellContentVariants = cva('flex flex-col gap-1 w-full', {
  variants: {
    align: {
      left: 'items-start',
      center: 'items-center',
      right: 'items-end',
    },
  },
  defaultVariants: {
    align: 'left',
  },
});

type DataTableCellContentVariantsProps = VariantProps<
  typeof DataTableCellContentVariants
>;

export const DataTableCellContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & DataTableCellContentVariantsProps
>(({ children, className, align, ...props }, ref) => (
  <div
    className={cn(DataTableCellContentVariants({ align }), className)}
    {...props}
    ref={ref}
  >
    {children}
  </div>
));

export const DataTableCellTitle = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
  <div
    className={cn('font-normal leading-none whitespace-nowrap', className)}
    {...props}
    ref={ref}
  >
    <DataTableCellOptional>{children}</DataTableCellOptional>
  </div>
));

export const DataTableCellSubtitle = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, className, ...props }, ref) => (
  <div
    className={cn(
      'text-xs font-extralight text-grey-60 leading-none whitespace-nowrap',
      className,
    )}
    {...props}
    ref={ref}
  >
    <DataTableCellOptional>{children}</DataTableCellOptional>
  </div>
));

export const DataTableCellOptional = ({ children }: PropsWithChildren) => {
  const { common } = useAutoneTranslation();

  if (!children) {
    return common('na');
  } else {
    return children;
  }
};

export const DataTableCellLongText = ({
  value,
  maxLength,
}: {
  value?: string | null;
  maxLength: number;
}) => {
  const { common } = useAutoneTranslation();

  if (!value) {
    return common('na');
  } else if (value.length <= maxLength) {
    return value;
  } else {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span>{value.slice(0, maxLength) + '...'}</span>
          </TooltipTrigger>
          <TooltipContent side={'bottom'} asChild>
            <div
              className={
                'p-1 bg-white border border-grey-20 rounded-sm shadow-md'
              }
            >
              {value}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }
};

export const DataTableCellUnits = ({ value }: { value?: number }) => {
  const { common } = useAutoneTranslation();

  return value ? common('units-value', { value, count: value }) : common('na');
};

export const DataTableCellDate = ({ value }: { value?: string | null }) => {
  const { common } = useAutoneTranslation();
  const locale = navigator.language;

  return value ? new Date(value).toLocaleDateString(locale) : common('na');
};

export const DataTableCellDateRange = ({
  start,
  end,
}: {
  start?: string | null;
  end?: string | null;
}) => {
  const { common } = useAutoneTranslation();
  const locale = navigator.language;

  return start && end
    ? `${new Date(start).toLocaleDateString(locale)} - ${new Date(
        end,
      ).toLocaleDateString(locale)}`
    : common('na');
};

export const DataTableCellDateTime = ({ value }: { value?: string }) => {
  const { common } = useAutoneTranslation();
  const locale = navigator.language;

  return value ? new Date(value).toLocaleString(locale) : common('na');
};

export const DataTableCellPerc = ({ value }: { value?: number }) => {
  const { common } = useAutoneTranslation();

  return value === undefined ? common('na') : fPercentage(value);
};

export const DataTableCellPercProjected = ({ value }: { value?: number }) => {
  const { common } = useAutoneTranslation();

  return isFiniteNumber(value)
    ? common('perc-projected', { value })
    : common('na');
};

export const DataTableCellPercAchieved = ({ value }: { value?: number }) => {
  const { common } = useAutoneTranslation();
  return isFiniteNumber(value)
    ? common('perc-already-achieved', { value })
    : common('na');
};

export const DataTableCellPercRemaining = ({ value }: { value?: number }) => {
  const { common } = useAutoneTranslation();

  return isFiniteNumber(value)
    ? common('perc-remaining', { value })
    : common('na');
};

export const DataTableCellCurrencyValue = ({
  value,
}: {
  value?: number | null;
}) => {
  const { common } = useAutoneTranslation();
  const { defaultCurrency } = useDefaultCurrency();
  const locale = navigator.language;

  return value === undefined || value === null
    ? common('na')
    : fReadableCurrency(value, defaultCurrency, locale);
};

export const DataTableCellPercentageCovered = ({
  value,
}: {
  value?: number;
}) => {
  const { common } = useAutoneTranslation();

  return isFiniteNumber(value)
    ? common('perc-covered', { value: value, format: '0.00' })
    : common('na');
};

export const DataTableCellBoolean = ({ value }: { value?: boolean }) => {
  const { common } = useAutoneTranslation();

  return value ? common('yes') : common('no');
};
