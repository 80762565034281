import { createContext, type PropsWithChildren, useContext } from 'react';

import {
  type SavedFilterEditModeContextValue,
  type SavedFiltersContextValue,
  type ViewedSavedFilterContextValue,
} from './types';

/**
 * `ViewedSavedFilterContext` is a local context used to keep track of the currently viewed
 * filter across the component.
 */
const ViewedSavedFilterContext =
  createContext<ViewedSavedFilterContextValue | null>(null);

interface ViewedSavedFilterContextProviderProps extends PropsWithChildren {
  viewedSavedFilterId: string | null;
  setViewedSavedFilterId: (id: string | null) => void;
}

export const ViewedSavedFilterContextProvider = ({
  viewedSavedFilterId,
  setViewedSavedFilterId,
  children,
}: ViewedSavedFilterContextProviderProps) => {
  return (
    <ViewedSavedFilterContext.Provider
      value={{ viewedSavedFilterId, setViewedSavedFilterId }}
    >
      {children}
    </ViewedSavedFilterContext.Provider>
  );
};

export const useViewedSavedFilterContext = () => {
  const viewedSavedFilterContext = useContext(ViewedSavedFilterContext);

  if (!viewedSavedFilterContext) {
    throw new Error(
      'useViewedSavedFilterContext must be used within a <ViewedSavedFilterContextProvider />',
    );
  }

  return viewedSavedFilterContext;
};

/**
 * `SavedFiltersContext` makes all required callbacks quickly accessible to all
 * components of `<SavedFiltersView />` without prop-drilling.
 */
const SavedFiltersContext = createContext<SavedFiltersContextValue | null>(
  null,
);

interface SavedFiltersContextProviderProps
  extends PropsWithChildren,
    SavedFiltersContextValue {}

export const SavedFiltersContextProvider = ({
  children,
  savedFilterTemplates,
  onApplySavedFilter,
  onDeleteSavedFilter,
  onEditSavedFilterName,
}: SavedFiltersContextProviderProps) => {
  return (
    <SavedFiltersContext.Provider
      value={{
        savedFilterTemplates,
        onApplySavedFilter,
        onDeleteSavedFilter,
        onEditSavedFilterName,
      }}
    >
      {children}
    </SavedFiltersContext.Provider>
  );
};

export const useSavedFiltersContext = () => {
  const savedFiltersContext = useContext(SavedFiltersContext);

  if (!savedFiltersContext) {
    throw new Error(
      'useSavedFiltersContext must be used within a <SavedFiltersContextProvider />',
    );
  }

  return savedFiltersContext;
};

/**
 * This context is used to keep track of whether a saved filter is currently being edited.
 */
export const SavedFilterEditModeContext =
  createContext<SavedFilterEditModeContextValue | null>(null);

export const useSavedFilterEditModeContext = () => {
  const savedFilterEditModeContext = useContext(SavedFilterEditModeContext);

  if (!savedFilterEditModeContext) {
    throw new Error(
      'useSavedFilterEditModeContext must be used within a <SavedFilterEditModeContextProvider />',
    );
  }

  return savedFilterEditModeContext;
};
