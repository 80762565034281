import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Card,
  ClickAwayListener,
  Grow,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper as MuiPopper,
  Stack,
  type SxProps,
  Typography,
} from '@mui/material';
import React, { type ReactNode, useRef, useState } from 'react';

import useLanguagePreferencePopover from '../../hooks/useLanguagePreferencePopover';
import { FrFlag } from '../../svgs/flags/FrFlag';
import { GbFlag } from '../../svgs/flags/GbFlag';
import { ItFlag } from '../../svgs/flags/ItFlag';
import { UsFlag } from '../../svgs/flags/UsFlag';

export type LabelDict = {
  'en-US': string;
  en: string;
  it?: string;
  fr?: string;
};

export type Language = 'en-US' | 'en' | 'fr' | 'it';

type LanguageData = {
  language: Language;
  label?: string;
  icon: ReactNode;
  alt: string;
};

const POPPER_CARD_STYLES = {
  ml: 1.1,
  zIndex: 100,
  pointerEvents: 'auto',
  width: 166,
  paddingY: '20px',
  paddingX: 3,
  borderRadius: '20px',
  boxShadow:
    '0 0 2px 0 rgba(99, 115, 129, 0.24),0 12px 24px 0 rgba(99, 115, 129, 0.24)',
  backgroundColor: 'white',
};

const filterLanguageData = (
  languageData: LanguageData[],
  languages?: Language[],
): LanguageData[] => {
  if (!languages?.length) {
    return languageData;
  }

  const languageSet = new Set(languages);
  return languageData.filter((item) => languageSet.has(item.language));
};

export const getLanguageData = (labelDict: LabelDict): LanguageData[] => [
  {
    language: 'en-US',
    icon: <UsFlag />,
    label: labelDict['en-US'],
    alt: 'US flag',
  },
  {
    language: 'en',
    icon: <GbFlag />,
    label: labelDict['en'],
    alt: 'GB flag',
  },
  {
    language: 'fr',
    icon: <FrFlag />,
    label: labelDict['fr'],
    alt: 'FR flag',
  },
  {
    language: 'it',
    icon: <ItFlag />,
    label: labelDict['it'],
    alt: 'IT flag',
  },
];

const LanguageItem = ({
  langKey,
  label,
  icon,
  handleSelectLanguage,
}: {
  langKey: string;
  label?: string;
  icon: React.ReactNode;
  handleSelectLanguage: (key: string) => void;
}) => {
  return (
    <ListItemButton
      component={'span'}
      disableGutters
      className="isActiveRoot"
      onClick={() => handleSelectLanguage(langKey)}
      sx={{
        '&:hover': { bgcolor: 'inherit', color: 'primary.main' },
        width: 'auto',
        display: 'flex',
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        sx={{ textTransform: 'none' }}
        disableTypography
        primary={label}
      />
    </ListItemButton>
  );
};

const LanguageItems = ({
  languageData,
  handleSelectLanguage,
}: {
  languageData: LanguageData[];
  handleSelectLanguage: (key: string) => void;
}) => {
  return (
    <List disablePadding>
      {languageData.map(({ language, icon, label }) => {
        return (
          <LanguageItem
            key={language}
            langKey={language}
            icon={icon}
            label={label}
            handleSelectLanguage={handleSelectLanguage}
          />
        );
      })}
    </List>
  );
};

const Popper = ({
  popperProps,
  children,
  handleClosePopper,
}: {
  popperProps: {
    open: boolean;
    anchorEl: null | HTMLElement;
    sx: SxProps;
  };
  children: React.ReactElement<any, any>;
  handleClosePopper: () => void;
}) => {
  return (
    <MuiPopper {...popperProps} transition>
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={() => handleClosePopper()}>
          <Grow {...TransitionProps}>{children}</Grow>
        </ClickAwayListener>
      )}
    </MuiPopper>
  );
};

const LanguageSelectionPopover = ({
  isCollapse,
  labelDict = {
    'en-US': 'English (US)',
    en: 'English',
    it: 'Italian',
    fr: 'French',
  },
  errorText = 'Something went wrong loading your selected language',
  languages,
}: {
  isCollapse: boolean;
  labelDict?: LabelDict;
  errorText?: string;
  languages?: Language[];
}) => {
  const {
    currentLanguage: selectedLanguage,
    changeLanguage: handleSelectLanguageCallback,
  } = useLanguagePreferencePopover({ errorText });

  const languageData = filterLanguageData(
    getLanguageData(labelDict),
    languages,
  );

  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpenPopper = () => {
    setOpen(true);
  };

  const handleClosePopper = () => {
    setOpen(false);
  };

  const selectedLanguageData =
    languageData.find(({ language }) => language === selectedLanguage) ??
    languageData.find(({ language }) => language === 'en');

  const { icon, label } = selectedLanguageData || {};

  const handleSelectLanguage = (lng: string) => {
    handleSelectLanguageCallback(lng);
    handleClosePopper();
  };

  const popperProps = {
    open: open,
    anchorEl: menuRef.current,
    sx: {
      zIndex: 9999,
    },
    placement: 'right-end' as const,
  };

  if (isCollapse) {
    return (
      <>
        <Card
          ref={menuRef}
          component={'button'}
          onMouseEnter={handleOpenPopper}
          onMouseLeave={handleClosePopper}
          sx={{
            boxShadow: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': { cursor: 'pointer' },
            py: 1.5,
          }}
        >
          {icon}
        </Card>
        <Popper popperProps={popperProps} handleClosePopper={handleClosePopper}>
          <Box
            onMouseEnter={handleOpenPopper}
            onMouseLeave={handleClosePopper}
            sx={POPPER_CARD_STYLES}
          >
            <LanguageItems
              languageData={languageData}
              handleSelectLanguage={handleSelectLanguage}
            />
          </Box>
        </Popper>
      </>
    );
  }

  return (
    <>
      <Stack
        ref={menuRef}
        direction={'row'}
        alignItems={'center'}
        sx={{
          py: 1.5,
          px: 2.5,
        }}
      >
        {icon}
        <Typography sx={{ ml: '12px' }}>{label}</Typography>
        <IconButton
          aria-label="open-popper"
          sx={{ ml: 'auto' }}
          onClick={open ? handleClosePopper : handleOpenPopper}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
      <Popper popperProps={popperProps} handleClosePopper={handleClosePopper}>
        <Box sx={POPPER_CARD_STYLES}>
          <LanguageItems
            languageData={languageData}
            handleSelectLanguage={handleSelectLanguage}
          />
        </Box>
      </Popper>
    </>
  );
};

export default LanguageSelectionPopover;
