import {
  type CustomTheme,
  Divider,
  List,
  Stack,
  type SxProps,
  useTheme,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { CanAccess } from '../../../utils/check-permissions';
import { getIsActive } from '../../../utils/getIsActive';
import ListSubheader from '../../list-subheader';
import UserCard, { UserCardContent } from '../../user-card/UserCard';
import { ListSubheaderStyles } from '../styles/Styles';

import {
  accountConfig,
  hubConfig,
  modulesConfig,
  settingsConfig,
  type SidebarItemConfig,
} from './SidebarConfig';
import SidebarItemRoot from './SidebarItemRoot';

const DIVIDER_STYLES = {
  my: 2,
  mx: 0.5,
};

function renderSidebarItems({
  items,
  currentPathname,
  token,
  sidebarItemStyles,
  handlePopoverClose = () => {},
}: {
  items: SidebarItemConfig[];
  currentPathname: string;
  token: string;
  sidebarItemStyles?: SxProps;
  handlePopoverClose?: () => void;
}) {
  return (
    <List disablePadding>
      {items.map(
        (
          {
            href,
            icon,
            title,
            tooltip,
            isHubLink,
            path,
            permissionGroups,
            options,
            isInsightsLink,
            renderDivider,
            ignoreCollapse,
            isLogoutButton,
          },
          idx,
        ) => {
          // check if user has permission to the sidebar item based on the permission groups in the sidebar config
          const isDisabled = permissionGroups
            ? !CanAccess(token, permissionGroups)
            : false;

          const isActive = getIsActive({ href, path, currentPathname });

          return (
            <SidebarItemRoot
              key={idx}
              href={href}
              icon={icon}
              title={title}
              tooltip={tooltip}
              isActive={isActive}
              isDisabled={isDisabled}
              isHubLink={!!isHubLink}
              path={path}
              options={options}
              isInsightsLink={!!isInsightsLink}
              currentPathname={currentPathname}
              sidebarItemStyles={sidebarItemStyles}
              renderDivider={!!renderDivider}
              ignoreCollapse={ignoreCollapse}
              isLogoutButton={isLogoutButton}
              handlePopoverClose={handlePopoverClose}
            />
          );
        },
      )}
    </List>
  );
}

export default function SideBarContent({
  renderItems,
}: {
  renderItems?: (isCollapse: boolean) => React.ReactNode;
}) {
  const { launchAssortmentMatrix, launchEventsApp } = useFlags();

  const flaggedAccountConfig = useMemo(
    () => ({
      ...accountConfig,
      items: accountConfig.items.filter((item) => {
        if (!launchAssortmentMatrix && item.title === 'Assortment')
          return false;
        if (!launchEventsApp && item.title === 'Events') return false;
        return true;
      }),
    }),
    [accountConfig, launchAssortmentMatrix, launchEventsApp],
  );

  const theme: CustomTheme = useTheme();
  //@ts-ignore TODO: struggling to type this as store comes from the app and not the ui
  const { idToken: token } = useSelector((state) => state.user);
  const { pathname: currentPathname } = useLocation();
  const { isCollapse } = useCollapseDrawer();

  const sharedSidebarProps = {
    currentPathname,
    token,
  };

  return (
    <>
      <List
        subheader={
          !isCollapse ? (
            <ListSubheader
              sx={{
                ...ListSubheaderStyles(theme, isCollapse),
              }}
            >
              {hubConfig.title}
            </ListSubheader>
          ) : null
        }
        disablePadding
      >
        {renderSidebarItems({
          items: hubConfig.items,
          ...sharedSidebarProps,
        })}
      </List>
      {isCollapse && <Divider variant={'middle'} sx={DIVIDER_STYLES} />}
      <List
        subheader={
          !isCollapse ? (
            <ListSubheader
              sx={{
                ...ListSubheaderStyles(theme, isCollapse),
              }}
            >
              {modulesConfig.title}
            </ListSubheader>
          ) : null
        }
        disablePadding
      >
        {renderSidebarItems({
          items: modulesConfig.items,
          ...sharedSidebarProps,
        })}
      </List>
      {isCollapse && <Divider variant={'middle'} sx={DIVIDER_STYLES} />}
      <List
        subheader={
          !isCollapse ? (
            <ListSubheader
              sx={{
                ...ListSubheaderStyles(theme, isCollapse),
              }}
            >
              {accountConfig.title}
            </ListSubheader>
          ) : null
        }
        disablePadding
      >
        {renderSidebarItems({
          items: flaggedAccountConfig.items,
          ...sharedSidebarProps,
        })}
      </List>
      <List sx={{ mt: 'auto' }}>
        <Stack
          gap={'8px'}
          sx={{ mt: 2, ...(isCollapse ? { mx: 1 } : { mx: 2 }) }}
        >
          {renderItems && renderItems(isCollapse)}
          <UserCard
            isCollapse={isCollapse}
            settingsPopoverContent={
              <UserCardContent
                renderContent={({ handlePopoverClose }) =>
                  renderSidebarItems({
                    items: settingsConfig.items,
                    ...sharedSidebarProps,
                    sidebarItemStyles: {
                      height: null,
                      p: 1,
                      fontSize: 'body1.fontSize',
                    },
                    handlePopoverClose: handlePopoverClose,
                  })
                }
              />
            }
          />
        </Stack>
      </List>
    </>
  );
}
