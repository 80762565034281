import { Box, ListItemIcon, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

export const BoxStyled = styled(Box)(() => ({
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
  margin: 0,
}));

export const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => {
  return {
    color: theme.palette.text.primary,
    '&.MuiListItemIcon-root': {
      minWidth: 'fit-content',
      marginRight: 0,
      paddingRight: theme.spacing(1),
    },
  };
});
