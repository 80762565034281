import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, IconButton, MenuItem, Stack, useTheme } from '@mui/material';
import type { DraggableProvided } from 'react-beautiful-dnd';

import { type TableConfigType } from '../../table/types';

const ColumnItem = ({
  header,
  handleColumnSelect,
  provided,
  disabled,
  columnData,
}: {
  header?: string;
  handleColumnSelect: (columnData: TableConfigType<any>) => void;
  provided: DraggableProvided;
  disabled?: boolean;
  columnData: any;
}) => {
  const theme = useTheme();
  return (
    <MenuItem
      sx={{
        p: 1,
        my: 1,
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: `${theme.shape.borderRadius}px`,
        backgroundColor: 'white',
      }}
      ref={provided.innerRef}
      disabled={disabled}
      component={Box}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Stack
        sx={{
          alignItems: 'center',
          flex: 1,
          width: '100%',
        }}
        direction="row"
        spacing={1}
      >
        <Box
          sx={{
            width: '100%',
            textAlign: 'start',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {header}
        </Box>
        <DragIndicatorIcon
          onClick={(e) => e.stopPropagation}
          sx={{
            ml: 'auto',
            '&:hover': { cursor: 'grab' },
          }}
        />
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleColumnSelect(columnData);
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </Stack>
    </MenuItem>
  );
};

export default ColumnItem;
