export default function Accordion(theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '0px',
          padding: theme.spacing(0),
          margin: theme.spacing(0),
          '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.action.disabled,
            '& .MuiTypography-root': {
              color: 'inherit',
            },
          },
        },
        content: {
          margin: theme.spacing(0),
          px: theme.spacing(0),
        },
        expandIconWrapper: {
          color: 'inherit',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: { padding: theme.spacing(0) },
      },
    },
  };
}
