import { type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, type ReactNode, type SelectHTMLAttributes } from 'react';

const useSelectStyles = makeStyles((theme: Theme) => ({
  select: {
    width: '100%',
    padding: '8px 24px 8px 8px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[300]}`,
    appearance: 'none',
    background: 'transparent',
  },
}));

const SelectBox = forwardRef<
  HTMLSelectElement,
  SelectHTMLAttributes<HTMLSelectElement> & {
    children: ReactNode;
  }
>(({ children, ...props }, ref) => {
  const { select } = useSelectStyles();
  return (
    <select ref={ref} {...props} className={select}>
      {children}
    </select>
  );
});

export default SelectBox;
