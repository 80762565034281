import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';

const Navbar = ({ contentBeginning, contentMiddle, contentEnd }) => (
  <>
    {contentBeginning}
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        overflowX: 'scroll',
        gap: '10px',
        px: 2,
      }}
    >
      {contentMiddle}
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {contentEnd}
    </Box>
  </>
);

export default Navbar;

Navbar.propTypes = {
  contentBeginning: PropTypes.node,
  contentEnd: PropTypes.node,
  contentMiddle: PropTypes.node,
};
