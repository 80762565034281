import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import LightTooltip from '../LightTooltip';

export default function ClearAllSelections({
  filters,
  activePage,
  handleRemoveAll,
}) {
  return (
    <>
      {filters &&
        filters[activePage] &&
        filters[activePage].some(
          (filterOption) =>
            filterOption?.options?.filter((option) => option?.active)?.length >
            0,
        ) && (
          <LightTooltip title="Remove all selections">
            <IconButton
              color="error"
              aria-label="remove selections"
              onClick={handleRemoveAll}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </LightTooltip>
        )}
    </>
  );
}

ClearAllSelections.propTypes = {
  filters: PropTypes.object,
  activePage: PropTypes.string,
  handleRemoveAll: PropTypes.func,
};
