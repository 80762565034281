export type AvatarSize = {
  width: number;
  height: number;
};

const avatar: { [key: string]: AvatarSize } = {
  xs: { width: 25, height: 25 },
  sm: { width: 30, height: 30 },
  md: { width: 40, height: 40 },
  lg: { width: 54, height: 54 },
  xl: { width: 75, height: 75 },
};

export default avatar;
