import PropTypes from 'prop-types';

const ReorderIcon = ({ size = 22, color = '#01172F' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8138 11.2674C22.3831 10.9388 23.1109 11.1338 23.4396 11.7031C23.7408 12.2249 23.602 12.8799 23.1383 13.2386L23.0039 13.3288L16.5915 17.0309C16.2759 17.2131 15.8975 17.2391 15.564 17.109L15.4014 17.0309L8.98907 13.3288C8.41984 13.0002 8.2248 12.2723 8.55344 11.7031C8.85468 11.1813 9.4914 10.9739 10.0339 11.1961L10.1792 11.2674L15.9965 14.626L21.8138 11.2674Z"
      fill={color}
    />
    <path
      d="M15.9994 14.8109C16.602 14.8109 17.0999 15.2586 17.1787 15.8395L17.1896 16.001V23.4115C17.1896 24.0688 16.6567 24.6017 15.9994 24.6017C15.3969 24.6017 14.899 24.1539 14.8202 23.573L14.8093 23.4115V16.001C14.8093 15.3437 15.3422 14.8109 15.9994 14.8109Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.52663 10.7275L14.6695 7.75765C15.4925 7.28453 16.5052 7.28453 17.3272 7.75879L22.4693 10.7282C23.2923 11.2042 23.7982 12.0807 23.7982 13.031V18.9673C23.7982 19.9165 23.2919 20.7944 22.4686 21.2706L17.3253 24.2407C16.5023 24.7138 15.4896 24.7138 14.6675 24.2396L9.52549 21.2702C8.70249 20.7942 8.19653 19.9177 8.19653 18.9673V13.031C8.19653 12.0808 8.7041 11.202 9.52663 10.7275ZM21.2761 12.7902L16.1352 9.82146C16.0492 9.77183 15.9416 9.77183 15.8557 9.82122L10.7144 12.7902C10.6282 12.8399 10.5747 12.9326 10.5747 13.0322V18.9685C10.5747 19.0688 10.6274 19.1602 10.7144 19.2105L15.8553 22.1792C15.9413 22.2289 16.0489 22.2289 16.1348 22.1795L21.2754 19.2109C21.3625 19.1606 21.4159 19.068 21.4159 18.9685V13.0322C21.4159 12.9319 21.3631 12.8405 21.2761 12.7902Z"
      fill={color}
    />
    <path
      d="M24.9095 10.0958C23.5761 9.92159 23.5761 7.91395 24.9095 7.7397L25.0995 7.72765H28.6699V4.15729C28.6699 3.55477 29.1176 3.05683 29.6985 2.97803L29.86 2.96716C30.4625 2.96716 30.9605 3.41489 31.0393 3.99579L31.0502 4.15729V8.91619C31.0502 9.52029 30.6024 10.0182 30.0215 10.097L29.86 10.1079H25.0995L24.9095 10.0958Z"
      fill={color}
    />
    <path
      d="M16 0C21.9199 0 27.2687 3.24437 30.0535 8.34791C30.3684 8.92489 30.1559 9.64785 29.5789 9.96269C29.0019 10.2775 28.279 10.065 27.9641 9.48805C25.5923 5.14144 21.0401 2.38024 16 2.38024C8.47775 2.38024 2.38024 8.4782 2.38024 16C2.38024 23.5218 8.47775 29.6198 16 29.6198C23.5223 29.6198 29.6198 23.5218 29.6198 16C29.6198 15.3427 30.1526 14.8099 30.8099 14.8099C31.4672 14.8099 32 15.3427 32 16C32 24.8363 24.8369 32 16 32C7.16314 32 0 24.8363 0 16C0 7.16367 7.16314 0 16 0Z"
      fill={color}
    />
  </svg>
);

ReorderIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default ReorderIcon;
