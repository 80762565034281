import {
  type CustomTheme,
  Drawer,
  Hidden,
  Stack,
  useTheme,
} from '@mui/material';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { NAVBAR } from '../consts';
import DashboardLogo from '../DashboardLogo';

const SIDEBAR_COLLAPSE_COOKIE_NAME = 'autone-sidebar-collapse-state';

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  children,
}: {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
  children: React.ReactNode;
}) {
  const { pathname } = useLocation();
  const theme: CustomTheme = useTheme();
  const { isCollapse, collapseClick, onToggleCollapse, setToggleCollapse } =
    useCollapseDrawer();
  const [showCollapse, setShowCollapse] = useState(false);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    // find the cookie which stores the sidebar collapse state
    // if this cookie exists then set the collapse state to it's value
    // this lets us keep the state persistent across apps
    const sidebarCollapseCookie = Cookies.get(SIDEBAR_COLLAPSE_COOKIE_NAME);
    if (sidebarCollapseCookie) {
      setToggleCollapse(sidebarCollapseCookie !== 'false');
    }
  }, []);

  const handleClickToggleCollapse = () => {
    // set cookie to store the sidebar collapse state across apps
    // set to alternative of current state as it is toggled on click
    const cookieValue = isCollapse ? 'false' : 'true';
    Cookies.set(SIDEBAR_COLLAPSE_COOKIE_NAME, cookieValue, {
      sameSite: 'strict',
      secure: true,
    });
    onToggleCollapse();
  };

  const dashboardLogoProps = {
    isCollapse,
    collapseClick,
    handleClickToggleCollapse,
    showCollapse,
  };

  return (
    <Stack
      onMouseEnter={() => setShowCollapse(true)}
      onMouseLeave={() => setShowCollapse(false)}
      sx={{
        [theme.breakpoints.up('lg')]: {
          flexShrink: 0,
          width: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
          height: '100%',
        },
      }}
    >
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              bgcolor: 'white',
              width: NAVBAR.DASHBOARD_WIDTH,
              display: 'flex',
              height: '100%',
            },
          }}
        >
          <DashboardLogo {...dashboardLogoProps} />
          {children}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              display: 'flex',
              height: '100%',
              width: isCollapse
                ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
                : NAVBAR.DASHBOARD_WIDTH,
              bgcolor: 'white',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
            },
          }}
        >
          <DashboardLogo {...dashboardLogoProps} />
          {children}
        </Drawer>
      </Hidden>
    </Stack>
  );
}
