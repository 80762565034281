import { fNumberWithCommas } from '@autone/utils';
import { Box, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

const SimpleKPI = ({ title, value, headingSize, cardSx }) => (
  <>
    <Box
      sx={{
        py: 2.5,
        px: 0.5,
        display: 'flex',
        width: '100%',
        minWidth: 100,
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: 0.5,
        ...cardSx,
      }}
    >
      <Typography sx={{ padding: 0 }}>{title}</Typography>
      {/* // HARRY - why are you assuming its always a fNumberWithCommas format?
      pass fNumberWithCommas into as props
      */}
      <Typography variant={headingSize}>{fNumberWithCommas(value)}</Typography>
    </Box>
  </>
);

SimpleKPI.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headingSize: PropTypes.string,
  cardSx: PropTypes.object,
};

export default SimpleKPI;
