import { type TableConfigType } from '../../table/types';

// This reducer contains the actions for changing the custom columns
// We have two actions, toggle active which toggles whether a column is added or removed from the table
// and reorder columns which takes the state of reordered columns
export function customColumnReducer(
  state: TableConfigType<any>[],
  action:
    | {
        type: 'load_columns';
        payload: TableConfigType<any>[];
      }
    | {
        type: 'toggle_active';
        payload: TableConfigType<any>;
      }
    | {
        type: 'reorder_columns';
        payload: TableConfigType<any>[];
      },
) {
  switch (action.type) {
    case 'load_columns':
      return action.payload;

    case 'toggle_active':
      return state.map((obj) =>
        obj.id === action.payload.id
          ? {
              ...obj,
              active: !obj.active,
            }
          : obj,
      );

    // same action as load columns, but we keep separate in case this needs to change in the future
    case 'reorder_columns':
      return action.payload;

    default:
      throw new Error();
  }
}
