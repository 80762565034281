import { useAtom } from 'jotai';
import { type LDContext } from 'launchdarkly-js-client-sdk';
import { useEffect, useState } from 'react';

import { createLDMultiContext, getLDHash } from '../utils/launchdarkly';
import { ldHashAtom } from '../utils/launchdarkly/client';

const useLaunchDarkly = () => {
  const [ldHash, setLDHash] = useAtom(ldHashAtom);
  const [ldContext, setLDContext] = useState<LDContext | undefined>();

  useEffect(() => {
    const identifyLDClient = async () => {
      const context = createLDMultiContext();
      const hash = await getLDHash(context);
      setLDContext(context);
      setLDHash(hash);
    };

    identifyLDClient();
  }, []);

  return {
    ldHash,
    ldContext,
  };
};

export default useLaunchDarkly;
