import { Box, Skeleton, Tab, useTheme } from '@mui/material';
import { PropTypes } from 'prop-types';

import TabsWrapper from './TabsWrapper';

const Tabs = ({
  options,
  currentTab,
  onChangeTab,
  variant = 'grey',
  iconOnly = false,
  sx,
}) => {
  const theme = useTheme();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      {options ? (
        <TabsWrapper
          value={currentTab}
          aria-label="basic tabs example"
          onChange={onChangeTab}
          variant={variant}
          sx={{ ...sx }}
        >
          {options.map(({ label, value, icon }) => (
            <Tab
              sx={{
                '&:not(:last-child)': {
                  marginRight: iconOnly ? theme.spacing(1) : theme.spacing(5),
                },
              }}
              disableRipple
              key={value}
              value={value}
              label={
                iconOnly ? (
                  ''
                ) : (
                  <Box sx={{ px: 0.5, textTransform: 'none' }}>{label}</Box>
                )
              }
              {...a11yProps(value)}
              icon={icon}
              iconPosition="start"
            />
          ))}
        </TabsWrapper>
      ) : (
        <TabsWrapper value="skeleton_0" variant={variant}>
          {[...Array(5)].fill(1).map((option, i) => (
            <Tab
              key={i}
              value={`skeleton_${i}`}
              label={<Skeleton width={80} height={30} />}
              sx={{ textTransform: 'none', padding: '5px' }}
              {...a11yProps(option)}
            />
          ))}
        </TabsWrapper>
      )}
    </>
  );
};

export default Tabs;

Tabs.propTypes = {
  options: PropTypes.array,
  currentTab: PropTypes.string,
  onChangeTab: PropTypes.func,
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['grey', 'minimal']),
  iconOnly: PropTypes.bool,
};
