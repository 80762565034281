import { atomWithStorage } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';

import type { SelectedGeneralFilters } from '../../features';
import { type AdvancedFilterOperators } from '../../types';

export type FiltersStorage = Record<string, Array<string>>;
export type AdvancedFiltersStorage = {
  conditions: Array<{
    field: string;
    operator: AdvancedFilterOperators;
    value: string;
  }>;
  mode: 'and' | 'or' | null;
};
export type ProductDimensionId =
  | 'department'
  | 'sub_department'
  | 'brand'
  | 'class'
  | 'sub_class'
  | 'budget_level';
export type GeographicDimensionId =
  | 'budget_level'
  | 'region'
  | 'country'
  | 'location';

interface SessionStorage {
  replenishment: {
    productsTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    productLocationsTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    productSkusTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    locationsTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    locationSkusTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    locationProductsTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    locationDepartmentsTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    pivotTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
  };
  reorder: {
    productsTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
  };
  rebalancing: {
    pivotTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    locationTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    productTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
    tripsTable: {
      advancedFilters: AdvancedFiltersStorage | null;
    };
  };
  hub: {
    budget: {
      drilldownTable: {
        generalFilters: SelectedGeneralFilters;
        productDimension: ProductDimensionId;
        geographicDimension: GeographicDimensionId;
      };
      budgetLineTable: { generalFilters: SelectedGeneralFilters };
      selectedBudget: string | null;
      selectedBudgetView: 'sales' | 'otb' | 'inventory';
    };
  };
}

export type SessionStorageKeys = PathOf<SessionStorage, false, true>;

export const initialSessionStorageData: SessionStorage = {
  replenishment: {
    productsTable: {
      advancedFilters: {
        conditions: [
          {
            field: 'manual_replenishment_quantity',
            operator: 'gt',
            value: '0',
          },
        ],
        mode: 'and',
      },
    },
    productLocationsTable: {
      advancedFilters: {
        conditions: [
          {
            field: 'manual_replenishment_quantity',
            operator: 'gt',
            value: '0',
          },
        ],
        mode: 'and',
      },
    },
    productSkusTable: {
      advancedFilters: {
        conditions: [
          {
            field: 'manual_replenishment_quantity',
            operator: 'gt',
            value: '0',
          },
        ],
        mode: 'and',
      },
    },
    locationsTable: {
      advancedFilters: {
        conditions: [
          {
            field: 'manual_replenishment_quantity',
            operator: 'gt',
            value: '0',
          },
        ],
        mode: 'and',
      },
    },
    locationSkusTable: {
      advancedFilters: {
        conditions: [
          {
            field: 'manual_replenishment_quantity',
            operator: 'gt',
            value: '0',
          },
        ],
        mode: 'and',
      },
    },
    locationProductsTable: {
      advancedFilters: {
        conditions: [
          {
            field: 'manual_replenishment_quantity',
            operator: 'gt',
            value: '0',
          },
        ],
        mode: 'and',
      },
    },
    locationDepartmentsTable: {
      advancedFilters: {
        conditions: [
          {
            field: 'manual_replenishment_quantity',
            operator: 'gt',
            value: '0',
          },
        ],
        mode: 'and',
      },
    },
    pivotTable: {
      advancedFilters: {
        conditions: [
          {
            field: 'manual_replenishment_quantity',
            operator: 'gt',
            value: '0',
          },
        ],
        mode: 'and',
      },
    },
  },
  reorder: {
    productsTable: {
      advancedFilters: null,
    },
  },
  rebalancing: {
    pivotTable: {
      advancedFilters: null,
    },
    locationTable: {
      advancedFilters: null,
    },
    productTable: {
      advancedFilters: null,
    },
    tripsTable: {
      advancedFilters: null,
    },
  },
  hub: {
    budget: {
      drilldownTable: {
        generalFilters: {},
        productDimension: 'department',
        geographicDimension: 'region',
      },
      budgetLineTable: {
        generalFilters: {},
      },
      selectedBudget: null,
      selectedBudgetView: 'sales',
    },
  },
};

const triggerEvent = (
  key: string,
  oldValue: string | null,
  newValue: string | null,
) => {
  const event = new StorageEvent('storage', {
    key,
    oldValue,
    newValue,
    storageArea: sessionStorage,
    url: window.location.href,
  });
  window.dispatchEvent(event);
};

const storage = {
  subscribe: (key: string, callback: (value: SessionStorage) => void) => {
    const storageEventCallback = (e: StorageEvent) => {
      if (e.key === key && e.newValue) {
        callback(JSON.parse(e.newValue));
      }
    };

    window.addEventListener('storage', storageEventCallback);
    return () => {
      window.removeEventListener('storage', storageEventCallback);
    };
  },
  getItem: function (key: string): SessionStorage {
    const value = sessionStorage.getItem(key);

    if (!value) {
      return initialSessionStorageData;
    }

    const parsedValue = JSON.parse(value);

    for (const [defaultKey, defaultValue] of Object.entries(
      initialSessionStorageData,
    )) {
      if (!(defaultKey in parsedValue)) {
        parsedValue[defaultKey] = defaultValue;
      }
    }

    return parsedValue;
  },
  setItem: function (key: string, newValue: SessionStorage): void {
    const oldValue = sessionStorage.getItem(key);
    const value = JSON.stringify(newValue);
    sessionStorage.setItem(key, value);

    triggerEvent(key, oldValue, value);
  },
  removeItem: function (key: string): void {
    const oldValue = sessionStorage.getItem(key);
    sessionStorage.removeItem(key);

    triggerEvent(key, oldValue, null);
  },
};

export const sessionStorageAtom = atomWithStorage(
  'autone-session-storage-v3',
  initialSessionStorageData,
  storage,
  {
    getOnInit: true,
  },
);

export const getSessionStorageAtom = <K extends SessionStorageKeys>(key: K) => {
  return focusAtom(sessionStorageAtom, (optic) => optic.path(key));
};
