import { Link as RouterLink, useLocation } from 'react-router-dom';

const SidebarLink = ({
  children,
  to,
  isHubLink,
  path,
  isDisabled,
  isInsightsLink,
}: {
  children: React.ReactNode;
  to: string;
  isHubLink?: boolean;
  path: string;
  isDisabled: boolean;
  isInsightsLink: boolean;
}) => {
  // check if you're on hub based on url containing <ROUTE OF HUB>
  // if user is on hub and isHub is true then internal route otherwise external
  const { pathname } = useLocation();

  // we cannot use RouterLink for the hub settings page as we have to now push to different tabs
  //TODO: migrate to RouterLink reloadDocument once we upgrade to react-router v6
  if (pathname.includes('hub') && pathname.includes('settings') && isHubLink) {
    return (
      <a
        href={path}
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          ...(isDisabled && { pointerEvents: 'none' }),
        }}
      >
        {children}
      </a>
    );
  }

  if (
    (pathname.includes('hub') && isHubLink) ||
    (pathname.includes('insights') && isInsightsLink)
  ) {
    return (
      <RouterLink
        className="isActiveRoot"
        to={path || ''}
        style={{
          textDecoration: 'none',
          ...(isDisabled && { pointerEvents: 'none' }),
        }}
      >
        {children}
      </RouterLink>
    );
  }

  return (
    <a
      href={to}
      rel="noopener noreferrer"
      style={{
        textDecoration: 'none',
        ...(isDisabled && { pointerEvents: 'none' }),
      }}
    >
      {children}
    </a>
  );
};

export default SidebarLink;
