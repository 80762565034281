import { FILTER_OPTIONS_THRESHOLD } from '@autone/utils';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  type SxProps,
  useTheme,
} from '@mui/material';
// material
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useRef } from 'react';

import { useFilterList, useSearchField } from '../../hooks';
import { generateFilterTitle } from '../../utils';
import Label from '../label/Label';
import SelectAll from '../multi-select/SelectAll';
import { NoResultsMessage } from '../no-results-message';

import FilterItem from './FilterItem';
// @ts-expect-error - not yet typed
import FilterSearch from './FilterSearch';
// @ts-expect-error - not yet typed
import LoadMoreButton from './LoadMoreButton';
// @ts-expect-error not yet typed
import { FilterListItemStyles } from './styles';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  maxHeight: {
    maxHeight: '300px',
    overflowY: 'scroll',
  },
}));

export type OptionsType = {
  id: string | null;
  title: string | null;
  active?: boolean | null;
}[];

type FilterItemsTypes<DimensionType = string> = {
  dimension: DimensionType;
  title: string;
  type?: string;
  open?: boolean;
  activeFilters?: number;
  labels?: {
    searchPlaceholder?: string;
    selectAll?: (optionCount: number) => string;
    clearAll?: string;
    loadMore?: string;
  };
  options: OptionsType;
  handleFilterClickCallback: (
    dimension: DimensionType,
    id: string,
    active: boolean,
    type: string,
    description: string,
  ) => any;
  handleSelectDeselectAllByDimension?: ({
    dimension,
    active,
    dimensionOptions,
  }: {
    dimension: DimensionType;
    active: boolean;
    dimensionOptions: OptionsType;
  }) => any;
  defaultOpen?: boolean;
  numOptionsDefaultOpen?: number;
  hideSelectAll?: boolean;
  listItemSx?: SxProps;
  filterItemSx?: SxProps;
  filterSearchSx?: SxProps;
  filterItemCollapseSx?: SxProps;
};

export default function FilterItems({
  dimension,
  title,
  type,
  options,
  open = false,
  activeFilters,
  handleFilterClickCallback,
  handleSelectDeselectAllByDimension = () => {},
  numOptionsDefaultOpen = 0,
  defaultOpen = false,
  hideSelectAll = false,
  listItemSx,
  filterItemSx,
  filterSearchSx,
  filterItemCollapseSx,
  labels,
}: FilterItemsTypes) {
  const theme = useTheme();
  const classes = useStyles();
  const popoverRef = useRef();

  const optionsLenExceedsThreshold = options.length > FILTER_OPTIONS_THRESHOLD;

  const {
    loadMoreItems,
    isLoading,
    itemsPerPage,
    page,
    show,
    handleShow,
    handleFilterClick,
  } = useFilterList({
    options,
    defaultOpen,
    numOptionsDefaultOpen,
    handleFilterClickCallback,
    type,
  });

  useEffect(() => {
    if (!show) {
      handleClearSearchInput();
    }
  }, [show]);

  const { searchInput, handleSearchInputChange, handleClearSearchInput } =
    useSearchField();

  // options after the search input filters results
  const optionsSearchResults = (options || []).filter(({ title, id }) => {
    if (!searchInput || searchInput.length === 0) return true;
    const searchField = title ?? id;
    return searchField?.toLowerCase()?.includes(searchInput?.toLowerCase());
  });

  return (
    <>
      <ListItem
        ref={popoverRef.current}
        disableGutters
        onClick={handleShow}
        className={open ? 'isActiveRoot' : ''}
        sx={{
          ...FilterListItemStyles(theme),
          ...listItemSx,
          backgroundColor: show ? '#f0f5fd' : '',
        }}
      >
        <ListItemText
          sx={{
            textTransform: 'none',
            fontWeight: 700,
            color: 'text.primary',
          }}
          disableTypography
          primary={generateFilterTitle(title)}
        />
        {activeFilters ? <Label color="primary">{activeFilters}</Label> : null}

        <Box
          component={Icon}
          icon={show ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 16, height: 16 }}
        />
      </ListItem>
      <Collapse in={show} sx={{ ml: 1, ...filterItemCollapseSx }}>
        {optionsLenExceedsThreshold ? (
          <>
            <FilterSearch
              searchInput={searchInput}
              handleSearchInputChange={handleSearchInputChange}
              handleClearSearchInput={handleClearSearchInput}
              filterSearchSx={filterSearchSx}
              placeholder={labels?.searchPlaceholder}
            />
            {!hideSelectAll && (
              <SelectAll
                options={options}
                selectAllText={labels?.selectAll?.(options.length)}
                clearAllText={labels?.clearAll}
                handleSelectAll={() => {
                  handleSelectDeselectAllByDimension({
                    dimension,
                    active: true,
                    dimensionOptions: options.filter(({ active }) => !active),
                  });
                }}
                handleClearAll={() =>
                  handleSelectDeselectAllByDimension({
                    dimension,
                    active: false,
                    dimensionOptions: options.filter(({ active }) => !!active),
                  })
                }
                showDivider={false}
                sx={{
                  width: '90%',
                  ml: theme.spacing(1),
                  mt: theme.spacing(0.5),
                  buttonMargin: 0,
                }}
              />
            )}
          </>
        ) : null}

        {optionsSearchResults.length === 0 ? (
          <NoResultsMessage />
        ) : (
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // Rendering a list as a parent of a list is adding some space to the left hand side. This margin fixes this.
              marginLeft: '-8px',
              ...(optionsLenExceedsThreshold && { pt: 0 }),
            }}
            className={classes.maxHeight}
          >
            {optionsSearchResults.slice(0, itemsPerPage * page).map((item) => (
              <FilterItem
                key={`${item?.id}-${item?.title}`}
                filterItemData={item}
                handleFilterClick={handleFilterClick}
                dimension={dimension}
                filterItemSx={filterItemSx}
              />
            ))}
            <>
              {optionsSearchResults.length > itemsPerPage * page && (
                <LoadMoreButton
                  isLoading={isLoading}
                  loadMoreItems={loadMoreItems}
                  label={labels?.loadMore}
                />
              )}
            </>
          </List>
        )}
      </Collapse>
    </>
  );
}
