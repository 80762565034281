import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import * as React from 'react';

import { cn } from './utils';

// TODO: ghost variant is named text in the design system, these should be aligned
const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none',
  {
    variants: {
      color: {
        primary: '',
        secondary: '',
        error: '',
      },
      variant: {
        contained: 'disabled:bg-grey-10 disabled:text-text-disabled',
        outlined:
          'border bg-background disabled:border-grey-10 disabled:text-text-disabled',
        ghost: 'disabled:text-text-disabled',
      },
      size: {
        sm: 'h-7 px-2.5 text-sm',
        md: 'h-8 px-4 text-sm',
        lg: 'h-10 px-5 text-base',
        icon: 'h-10 w-10',
      },
    },
    compoundVariants: [
      {
        color: 'primary',
        variant: 'contained',
        class: 'bg-button-primary text-white hover:bg-primary-120',
      },
      {
        color: 'secondary',
        variant: 'contained',
        class: 'bg-button-secondary text-text-primary hover:bg-grey-20',
      },
      {
        color: 'error',
        variant: 'contained',
        class: 'bg-button-error text-white hover:bg-red-120',
      },
      {
        color: 'primary',
        variant: 'outlined',
        class:
          'border-border-primary text-primary hover:bg-hover-primary hover:border-primary',
      },
      {
        color: 'secondary',
        variant: 'outlined',
        class: 'border-grey-20 text-text-primary hover:bg-grey-5',
      },
      {
        color: 'error',
        variant: 'outlined',
        class:
          'border-border-error text-button-error hover:bg-hover-error hover:border-button-error',
      },
      {
        color: 'primary',
        variant: 'ghost',
        class: 'text-primary hover:bg-hover-primary',
      },
      {
        color: 'secondary',
        variant: 'ghost',
        class: 'text-text-primary hover:bg-grey-10',
      },
      {
        color: 'error',
        variant: 'ghost',
        class: 'text-button-error hover:bg-hover-error',
      },
    ],
    defaultVariants: {
      variant: 'contained',
      color: 'primary',
      size: 'md',
    },
  },
);

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      color,
      asChild = false,
      children,
      loading,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, color, className }))}
        ref={ref}
        disabled={loading}
        {...props}
      >
        {loading ? (
          <>
            <Loader2 className="absolute h-4 w-4 animate-spin" />
            <span className="invisible">{children}</span>
          </>
        ) : (
          children
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
