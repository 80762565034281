import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Stack, type SxProps, Typography } from '@mui/material';

import { HUB_PATH_APP } from '../../routes';
import { Button } from '../../tailwind/Button';

const NoAccess = ({
  sx,
  text = "You don't have permission to view this page",
  buttonLabel = 'Back to hub',
  url = `https://${process.env.REACT_APP_DOMAIN}${HUB_PATH_APP.root}`,
}: {
  sx?: SxProps;
  text?: string;
  buttonLabel?: string;
  url?: string;
}) => (
  <Stack
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      ...sx,
    }}
    spacing={4}
  >
    <Box>
      <LockOutlinedIcon color="primary" sx={{ fontSize: '162px' }} />
    </Box>
    <Typography variant="h4">{text}</Typography>
    <Box>
      <Button onClick={() => window && window.location.replace(url)}>
        {buttonLabel}
      </Button>
    </Box>
  </Stack>
);

export default NoAccess;
