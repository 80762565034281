export type AutoneBackendError = {
  error_code: string;
  detail?: string;
  body?: unknown;
};

/**
 * Type predicate to narrow an unknown error data payload to `AutoneBackendError`.
 */
export const isAutoneBackendError = (
  data: unknown,
): data is AutoneBackendError => {
  return typeof data === 'object' && data !== null && 'error_code' in data;
};
