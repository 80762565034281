import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

HeaderText.propTypes = {
  header: PropTypes.string,
};

export default function HeaderText({ header }) {
  return (
    <Box>
      <Typography sx={{ color: 'text.secondary' }}>{header}</Typography>
    </Box>
  );
}
