import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ListItemStyle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'activeRoot',
})(({ activeRoot, theme }) => {
  const activeRootStyle = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected,
  };

  return {
    ...theme.typography.body1,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(1, 1.5),
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
    ...(activeRoot && {
      ...activeRootStyle,
      '&:hover': { ...activeRootStyle },
    }),
  };
});
