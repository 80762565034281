import { forwardRef } from 'react';

import { Button, type ButtonProps } from './Button';
import { cn } from './utils';

const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <Button
        variant="ghost"
        size="md"
        className={cn(
          'rounded-full hover:bg-grey-5 p-2 h-fit disabled:bg-grey-10',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

IconButton.displayName = 'IconButton';

export { IconButton };
