import { experimentalStyled as styled } from '@mui/material/styles';
import { useState } from 'react';

import DashboardSidebar from './sidebar/DashboardSidebar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 70;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 6,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout({
  SideBarContent,
  children,
}: {
  SideBarContent: React.ReactNode;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      >
        {SideBarContent}
      </DashboardSidebar>
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}
