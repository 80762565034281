import { generatePath } from './generate-paths';

export const EVENTS_ROOT = '/events';
export const EVENTS_PATH_APP = {
  root: EVENTS_ROOT,
  home: generatePath(EVENTS_ROOT, '/home'),
  createEvent: generatePath(EVENTS_ROOT, '/create'),
  error: generatePath(EVENTS_ROOT, '/error'),
  editEvent: generatePath(EVENTS_ROOT, '/edit'),
  editEventDetails: generatePath(EVENTS_ROOT, '/edit/:eventId/details'),
  editEventScope: generatePath(EVENTS_ROOT, '/edit/:eventId/scope'),
  editEventImpact: generatePath(EVENTS_ROOT, '/edit/:eventId/impact'),
};
