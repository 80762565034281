import PropTypes from 'prop-types';

export const TeamIcon = ({ size = 22, color = '#01172F' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2 13.3996C14.7347 13.3996 17.6 10.5342 17.6 6.99961C17.6 3.46499 14.7347 0.599609 11.2 0.599609C7.66543 0.599609 4.80005 3.46499 4.80005 6.99961C4.80005 10.5342 7.66543 13.3996 11.2 13.3996Z"
      fill={color}
    />
    <path
      d="M24 16.5996C26.6509 16.5996 28.8 14.4506 28.8 11.7996C28.8 9.14867 26.6509 6.99963 24 6.99963C21.349 6.99963 19.2 9.14867 19.2 11.7996C19.2 14.4506 21.349 16.5996 24 16.5996Z"
      fill={color}
    />
    <path
      d="M30.4 27.8004C31.2837 27.8004 32 27.0841 32 26.2004C31.9974 23.1497 30.26 20.3661 27.5206 19.0236C24.7811 17.681 21.5167 18.0134 19.104 19.8804C15.8993 16.6883 11.0891 15.7372 6.91076 17.4694C2.73239 19.2017 0.00611887 23.2772 0 27.8004C0 28.6841 0.716344 29.4004 1.6 29.4004H20.8C21.6837 29.4004 22.4 28.6841 22.4 27.8004"
      fill={color}
    />
  </svg>
);

TeamIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
