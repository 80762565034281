import bellFill from '@iconify/icons-eva/bell-fill';
import { Badge, Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { IconButtonAnimate } from '../animate';
import Iconify from '../Iconify';
import MenuPopover from '../popover/Popover';
import Scrollbar from '../Scrollbar';

// ----------------------------------------------------------------------

export default function NotificationsPopover({ children, badgeColor }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge variant="dot" color={badgeColor || 'success'}>
          <Iconify icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {children}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

NotificationsPopover.propTypes = {
  children: PropTypes.node,
  badgeColor: PropTypes.string,
};
