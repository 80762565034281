import { useAutoneTranslation } from '@autone/translations';
import {
  ChevronLeft,
  EllipsisVertical,
  Info,
  Pencil,
  Trash2,
} from 'lucide-react';
import { type HTMLAttributes, useRef, useState } from 'react';

import FilterChip from '../../../components/filters/FilterChip/FilterChip';
import {
  AutoneTooltip,
  TailwindButton,
  TailwindInput,
  TailwindPopover,
  TailwindPopoverContent,
  TailwindPopoverTrigger,
} from '../../../tailwind';

import {
  SavedFilterEditModeContext,
  useSavedFilterEditModeContext,
  useSavedFiltersContext,
  useViewedSavedFilterContext,
} from './SavedFilters.context';
import { type SavedFilterItem, type SavedFilterTemplate } from './types';

interface SavedFiltersListProps {
  savedFilterTemplates: SavedFilterTemplate[];
}

export const SavedFiltersList = ({
  savedFilterTemplates,
}: SavedFiltersListProps) => (
  <div className="flex flex-col h-full min-h-0 overflow-y-scroll">
    <div className="flex flex-col">
      {savedFilterTemplates.map((savedFilterTemplate) => (
        <SavedFilterListItem
          key={savedFilterTemplate.id}
          savedFilterTemplate={savedFilterTemplate}
        />
      ))}
    </div>
  </div>
);

interface SavedFilterItemProps {
  savedFilterTemplate: SavedFilterTemplate;
}

const SavedFilterListItem = ({ savedFilterTemplate }: SavedFilterItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { setViewedSavedFilterId } = useViewedSavedFilterContext();

  const handleViewSavedFilter = () => {
    setViewedSavedFilterId(savedFilterTemplate.id);
  };

  return (
    <SavedFilterEditModeContext.Provider value={{ isEditing, setIsEditing }}>
      <div
        onClick={handleViewSavedFilter}
        className="flex flex-row h-14 w-full border-b border-b-grey-10 last:border-b-0 hover:bg-grey-5 transition-colors hover:cursor-pointer"
      >
        {isEditing ? (
          <SavedFilterListItemEditView
            savedFilterTemplate={savedFilterTemplate}
          />
        ) : (
          <SavedFilterListItemDefaultView
            savedFilterTemplate={savedFilterTemplate}
          />
        )}
      </div>
    </SavedFilterEditModeContext.Provider>
  );
};

const SavedFilterListItemDefaultView = ({
  savedFilterTemplate,
}: SavedFilterItemProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <div
      ref={containerRef}
      className="flex flex-row justify-between items-center p-3 w-full"
    >
      {savedFilterTemplate.name}
      <TailwindPopover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <TailwindPopoverTrigger asChild>
          <TailwindButton
            data-testid={'saved-filter-popover-menu-button'}
            onClick={(e) => e.stopPropagation()}
            className="p-1 rounded-full aspect-square h-fit hover:bg-grey-10"
            variant={'ghost'}
            color={'secondary'}
            size={'sm'}
          >
            <EllipsisVertical size={18} />
          </TailwindButton>
        </TailwindPopoverTrigger>
        <SavedFilterListItemPopover
          onClickMenuButton={() => setPopoverOpen(false)}
          savedFilterTemplate={savedFilterTemplate}
          container={containerRef.current}
        />
      </TailwindPopover>
    </div>
  );
};

interface SavedFilterListItemPopoverProps extends SavedFilterItemProps {
  container: HTMLDivElement | null;
  onClickMenuButton?: () => void;
}

const SavedFilterListItemPopover = ({
  onClickMenuButton,
  savedFilterTemplate,
  container,
}: SavedFilterListItemPopoverProps) => {
  const { common } = useAutoneTranslation();
  const { onDeleteSavedFilter } = useSavedFiltersContext();
  const { setIsEditing } = useSavedFilterEditModeContext();

  return (
    <TailwindPopoverContent
      data-testid={'saved-filter-popover-content'}
      className="flex flex-col gap-1 p-1"
      container={container}
      onClick={(e) => e.stopPropagation()}
    >
      <TailwindButton
        variant={'ghost'}
        size={'sm'}
        color={'secondary'}
        type="button"
        onClick={() => {
          onClickMenuButton?.();
          setIsEditing(true);
        }}
        className="flex flex-row self-stretch justify-between gap-2 rounded-sm"
      >
        <span>{common('buttons.edit')}</span>
        <Pencil size={16} />
      </TailwindButton>
      <TailwindButton
        type="button"
        variant={'ghost'}
        size={'sm'}
        color={'error'}
        onClick={() => {
          onClickMenuButton?.();
          onDeleteSavedFilter(savedFilterTemplate.id);
        }}
        className="flex flex-row self-stretch justify-between gap-2 rounded-sm"
      >
        <span>{common('buttons.delete')}</span>
        <Trash2 size={16} />
      </TailwindButton>
    </TailwindPopoverContent>
  );
};

const SavedFilterListItemEditView = ({
  savedFilterTemplate,
}: SavedFilterItemProps) => {
  const [filterName, setFilterName] = useState(savedFilterTemplate.name);
  const { onEditSavedFilterName } = useSavedFiltersContext();
  const { setIsEditing } = useSavedFilterEditModeContext();
  const { common } = useAutoneTranslation();

  const saveIsDisabled = filterName === savedFilterTemplate.name;

  return (
    <div
      className="flex flex-row gap-2 justify-between items-center w-full h-full px-3 py-2 bg-white hover:cursor-default"
      onClick={(e) => e.stopPropagation()}
    >
      <TailwindInput
        className="py-1 px-2 h-full"
        value={filterName}
        onChange={(e) => setFilterName(e.target.value)}
      />
      <div className="flex flex-row gap-1 h-full">
        <TailwindButton
          variant={'outlined'}
          color="secondary"
          className="h-full"
          onClick={() => setIsEditing(false)}
        >
          {common('buttons.cancel')}
        </TailwindButton>
        <TailwindButton
          disabled={saveIsDisabled}
          variant={'contained'}
          color="secondary"
          className="h-full"
          onClick={() => {
            setIsEditing(false);
            onEditSavedFilterName(savedFilterTemplate.id, filterName);
          }}
        >
          {common('buttons.save')}
        </TailwindButton>
      </div>
    </div>
  );
};

interface SavedFilterSummaryProps {
  savedFilterTemplate: SavedFilterTemplate;
}

export const SavedFilterSummary = ({
  savedFilterTemplate,
}: SavedFilterSummaryProps) => {
  const { common } = useAutoneTranslation();
  const { setViewedSavedFilterId } = useViewedSavedFilterContext();
  const { savedGeneralFilters, savedAdvancedFilters } = savedFilterTemplate;

  const validFilters = [
    ...(savedGeneralFilters?.validForScope ?? []),
    ...(savedAdvancedFilters?.validForScope ?? []),
  ];
  const invalidFilters = [
    ...(savedGeneralFilters?.invalidForScope ?? []),
    ...(savedAdvancedFilters?.invalidForScope ?? []),
  ];

  const hasValidFilters = validFilters.length > 0;
  const hasInvalidFilters = invalidFilters.length > 0;

  return (
    <div className="flex flex-col h-full min-h-0 overflow-y-scroll">
      <div className="flex flex-col w-fill gap-6 p-3">
        <TailwindButton
          className="flex flex-row gap-1 w-fit"
          variant={'ghost'}
          onClick={() => setViewedSavedFilterId(null)}
        >
          <div className="-ms-2">
            <ChevronLeft size={16} />
          </div>
          <div>{common('filters.back-to-saved-filters')}</div>
        </TailwindButton>
        {hasValidFilters && (
          <div className="flex flex-col">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-1 items-center text-base">
                <span className="font-medium">
                  {common(
                    'table-filters.saved-filters-view.valid-filters.header',
                  )}
                </span>
                <AutoneTooltip
                  text={common(
                    'table-filters.saved-filters-view.valid-filters.info',
                  )}
                >
                  <Info className="inline" size={14} />
                </AutoneTooltip>
              </div>
              <FilterChipsSection
                savedFilterItems={validFilters}
                data-testid={'valid-filters'}
              />
            </div>
          </div>
        )}
        {hasInvalidFilters && (
          <div className="flex flex-col">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-1 items-center text-base">
                <span className="font-medium">
                  {common(
                    'table-filters.saved-filters-view.invalid-filters.header',
                  )}
                </span>
                <AutoneTooltip
                  text={common(
                    'table-filters.saved-filters-view.invalid-filters.info',
                  )}
                >
                  <Info className="inline" size={14} />
                </AutoneTooltip>
              </div>
              <FilterChipsSection
                savedFilterItems={invalidFilters}
                data-testid={'invalid-filters'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface FilterChipSectionProps extends HTMLAttributes<HTMLDivElement> {
  savedFilterItems: SavedFilterItem[];
}

const FilterChipsSection = ({
  savedFilterItems,
  ...props
}: FilterChipSectionProps) => (
  <div className="flex flex-row gap-2 flex-wrap" {...props}>
    {savedFilterItems.map((filter) => (
      <FilterChip
        size={'medium'}
        key={`${filter.translatedFilterName}-${filter.translatedCondition}-${filter.translatedPostfix}`}
      >
        <div className="inline">
          <span>{filter.translatedFilterName}</span>{' '}
          <span className="text-grey-60">
            {filter.translatedCondition.toLowerCase()}
          </span>{' '}
          <span>{filter.translatedPostfix}</span>
        </div>
      </FilterChip>
    ))}
  </div>
);
