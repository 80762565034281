import {
  type BrandPage,
  type ClassPage,
  type CollectionTypePage,
  type CountryPage,
  type DepartmentPage,
  type EventPage,
  type ListCountriesFilters,
  type ListLocationsFilters,
  type ListLocationTypesFilters,
  type ListProductAttributeFilters,
  type ListProductsFilters,
  type ListRegionsFilters,
  type ListSkusPagedFilters,
  type ListTerritoriesFilters,
  type Location,
  type LocationPage,
  type LocationTypePage,
  type ManufacturerPage,
  type ProductBudgetLevelPage,
  type ProductPage,
  type ProductView,
  type RegionPage,
  type SeasonPage,
  type SkusPage,
  type SubClassPage,
  type SubDepartmentPage,
} from '@autone/openapi-core';
import { LOCATIONS_QUERY_LIMIT } from '@autone/utils';
import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery, encodeUrl } from '../../utils';

// Define a service using the base core URL and expected endpoints
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    return customBaseQuery(args, api, extraOptions, 'v2/core');
  },
  endpoints: (builder) => ({
    getCoreDepartmentsPaged: builder.query<
      DepartmentPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/departments?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreProductsPaged: builder.query<
      ProductPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductsFilters;
      }) => ({
        url: encodeUrl({
          url: `/products?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreSeasonsPaged: builder.query<
      SeasonPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/seasons?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreEventsPaged: builder.query<
      EventPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/events?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreBrandsPaged: builder.query<
      BrandPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/brands?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreClassesPaged: builder.query<
      ClassPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/classes?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreSubClassesPaged: builder.query<
      SubClassPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/sub-classes?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreSubDepartmentsPaged: builder.query<
      SubDepartmentPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/sub-departments?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getRegionOptionsPaged: builder.query<
      RegionPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListRegionsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListRegionsFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations/regions?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getLocationTypeOptionsPaged: builder.query<
      LocationTypePage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListLocationTypesFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListLocationTypesFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations/location-types?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getLocationOptionsPaged: builder.query<
      LocationPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListLocationsFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListLocationsFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCountryOptionsPaged: builder.query<
      CountryPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListCountriesFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListCountriesFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations/countries?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getTerritoryOptionsPaged: builder.query<
      CountryPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListTerritoriesFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListTerritoriesFilters;
      }) => ({
        url: encodeUrl({
          url: `/locations/territories?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getLocationOptions: builder.query<Location[], void>({
      query: () => `locations?limit=${LOCATIONS_QUERY_LIMIT}`,
      transformResponse: (response: { locations: Location[] }) =>
        response.locations,
    }),
    getProductPeakView: builder.query<ProductView, { productId: string }>({
      query: ({ productId }) =>
        encodeUrl({
          url: `/products/{productId}/peak`,
          variables: { productId },
        }),
      transformResponse: (response: { product_view: ProductView }) =>
        response.product_view,
    }),
    getCoreCollectionTypesPaged: builder.query<
      CollectionTypePage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/collection-types?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreManufacturersPaged: builder.query<
      ManufacturerPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/manufacturers?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getCoreSkusPaged: builder.query<
      SkusPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListSkusPagedFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductsFilters;
      }) => ({
        url: encodeUrl({
          url: `/skus?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
    getProductBudgetLevelsPaged: builder.query<
      ProductBudgetLevelPage,
      {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }
    >({
      query: ({
        sortBy,
        cursor,
        limit,
        filters,
      }: {
        sortBy: string;
        cursor: string | null;
        limit: number;
        filters: ListProductAttributeFilters;
      }) => ({
        url: encodeUrl({
          url: `/products/budget-levels?limit={limit}&sort_by={sortBy}${
            cursor ? `&cursor={cursor}` : ``
          }`,
          variables: {
            sortBy,
            limit: `${limit}`,
            cursor: cursor || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
        },
      }),
    }),
  }),
});

export const {
  useGetCoreDepartmentsPagedQuery,
  useGetCoreSeasonsPagedQuery,
  useGetCoreEventsPagedQuery,
  useGetCoreBrandsPagedQuery,
  useGetCoreClassesPagedQuery,
  useGetCoreSubClassesPagedQuery,
  useGetCoreSubDepartmentsPagedQuery,
  useGetRegionOptionsPagedQuery,
  useGetLocationTypeOptionsPagedQuery,
  useGetLocationOptionsPagedQuery,
  useGetCountryOptionsPagedQuery,
  useGetCoreProductsPagedQuery,
  useGetTerritoryOptionsPagedQuery,
  useGetLocationOptionsQuery,
  useLazyGetLocationOptionsQuery,
  useGetProductPeakViewQuery,
  useGetCoreCollectionTypesPagedQuery,
  useGetCoreManufacturersPagedQuery,
  useGetCoreSkusPagedQuery,
  useGetProductBudgetLevelsPagedQuery,
} = coreApi;
