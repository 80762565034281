export const isObject = (item) =>
  item && typeof item === 'object' && !Array.isArray(item);

export const deepFreeze = (obj) => {
  Object.keys(obj).forEach(
    (key) => key && isObject(obj[key]) && Object.freeze(obj[key]),
  );

  return Object.freeze(obj);
};

export const deepMerge = (target, ...sources) => {
  if (!sources.length) {
    return target;
  }
  // making sure to not change target (immutable)
  const output = { ...target };
  sources.forEach((source) => {
    if (isObject(source)) {
      Object.keys(source).forEach((key) => {
        if (isObject(source[key])) {
          if (!output[key]) {
            output[key] = { ...source[key] };
          } else {
            output[key] = deepMerge(output[key], source[key]);
          }
        } else {
          output[key] = source[key];
        }
      });
    }
  });

  return output;
};

// get the nested value from a key
export const getNestedObjectValue = (obj, key) => {
  if (!key) return undefined;

  let foundValue = obj;

  const keys = key.split('.');
  for (let i = 0; i < keys.length; i++) {
    foundValue = foundValue?.[keys?.[i]];
  }

  return foundValue;
};
