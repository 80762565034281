import { createBrowserHistory } from 'history';
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
import { useCheckIsUserAuthenticated } from '../hooks';
import useLaunchDarkly from '../hooks/useLaunchDarkly';
import ThemeConfig from '../theme';
import { CanAccess } from '../utils';
import { createLDMultiContext, getLDHash } from '../utils/launchdarkly';
import NoAccess from '../views/permission/NoAccess';

import AppRoutes from './AppRoutes';
import LoadingScreen from './loading-screen/LoadingScreen';
import NotistackProvider from './notistack-provider/NotistackProvider';

// how auth checks works:
// 1. user hits an app which is wrapped in the app shell
// 2. uses currentAuthenicatedUser function to check whether the user is currently logged in or not based on their cookies
// 3. the currentAuthenicatedUser function can either fail if they are not logged in or succeed if they are
// 4. if the function fails or succeeds we set authCheck to true to indicate that the authentication state of the user has been checked. If the user is logged in, we update redux with the user details
// 5. after auth check if complete (regardless of user being authenticated or not), render the routes -> if there is no token the user is not authenticated and then AuthGuard will redirect them back to the login page

const history = createBrowserHistory();

const App = ({ token, authCheck, children }) => {
  const [loading, setLoading] = useState(true);
  const ldClient = useLDClient();

  useEffect(() => {
    (async () => {
      if (ldClient && authCheck) {
        await ldClient.waitUntilReady();
        setLoading(false);
      }
    })();
  }, [ldClient, authCheck]);

  useEffect(() => {
    if (token && !loading) {
      (async () => {
        const context = createLDMultiContext(token);
        const hash = await getLDHash(context);
        ldClient.identify(createLDMultiContext(token), hash);
      })();
    }
  }, [token, loading]);

  if (loading) {
    return <LoadingScreen />;
  }

  return children;
};

const AppShell = ({ routes, reduxStore, permissionGroups }) => {
  const [authCheck, setAuthCheck] = useState(false);
  const [token, setToken] = useState();
  const { ldHash, ldContext } = useLaunchDarkly();

  // function check if the user is authenticated
  const { checkUserAuthentication } = useCheckIsUserAuthenticated({
    setAuthCheck,
    setToken,
  });

  useEffect(() => {
    void checkUserAuthentication();

    window.addEventListener('vite:preloadError', (event) => {
      event.preventDefault();
      window.location.reload();
    });
  }, []);

  return (
    <HelmetProvider>
      <ReduxProvider store={reduxStore}>
        <ThemeConfig>
          <CollapseDrawerProvider>
            <NotistackProvider>
              <LDProvider
                clientSideID={process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID}
                context={ldContext}
                options={{ hash: ldHash }}
                deferInitialization
                sendEventsOnlyForVariation
              >
                <App token={token} authCheck={authCheck}>
                  <Router history={history}>
                    {/*
                      If there is no token OR if there is a token and the user has permission, direct to routes.
                      We only want to direct users to the NoAccess page, if they are logged in AND do not have access to the app they are accessing
                    */}
                    {!token || (token && CanAccess(token, permissionGroups)) ? (
                      <AppRoutes routes={routes} />
                    ) : (
                      <NoAccess />
                    )}
                  </Router>
                </App>
              </LDProvider>
            </NotistackProvider>
          </CollapseDrawerProvider>
        </ThemeConfig>
      </ReduxProvider>
    </HelmetProvider>
  );
};

App.propTypes = {
  token: PropTypes.string,
  authCheck: PropTypes.bool,
  children: PropTypes.node,
};

AppShell.propTypes = {
  routes: PropTypes.array,
  reduxStore: PropTypes.object,
  permissionGroups: PropTypes.array,
};

export default AppShell;
