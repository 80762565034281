// util to generate the error snackbar pop up
import { type EnqueueSnackbar } from 'notistack';

import errorMessages from './errorMessages';
import { type ErrorType } from './types';

export default (
  enqueueSnackbar: EnqueueSnackbar,
  err?: ErrorType,
  customMessage?: string,
) => enqueueSnackbar(errorMessages(err, customMessage), { variant: 'error' });
