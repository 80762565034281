import { Box, type SxProps } from '@mui/material';
import React, { forwardRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { Mixpanel } from '../../utils/mixpanel';
import { PAGE_VIEW } from '../../utils/mixpanel/eventTypes';

const Page = forwardRef(
  (
    {
      children,
      title = '',
      sx,
      ...other
    }: { children: React.ReactNode; title?: string; sx?: SxProps },
    ref,
  ) => {
    const { pathname } = useLocation();
    useEffect(() => Mixpanel.track(PAGE_VIEW, { pathname }), []);

    return (
      <Box ref={ref} sx={{ ...sx }} {...other}>
        <Helmet />
        <title>{title}</title>
        {children}
      </Box>
    );
  },
);

export default Page;
