import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import useExpandOptions from '../../hooks/useExpandOptions';

const ScopeSection = ({
  children,
  title,
  subheader,
  alert,
  expandable = true,
  spacing,
}) => {
  const theme = useTheme();
  // get state and functions for expandable options
  const { open, toggleExpand, expandButtonText } = useExpandOptions();

  return (
    <Stack spacing={spacing}>
      {/* header section */}
      <Box sx={{ mb: theme.spacing(1) }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h3">{title}</Typography>
            {alert && alert}
          </Stack>
          {expandable && (
            <Button
              onClick={() => {
                toggleExpand(open);
              }}
              sx={{ textTransform: 'none' }}
            >
              {expandButtonText}
            </Button>
          )}
        </Box>
        <Typography
          sx={{
            color: theme.palette.info.main,
            fontSize: theme.typography.body2.fontSize,
          }}
        >
          {subheader && subheader}
        </Typography>
      </Box>
      {/* children components  */}
      {expandable
        ? React.Children.map(children, (child) =>
            React.isValidElement(child)
              ? React.cloneElement(child, { open })
              : child,
          )
        : children}
    </Stack>
  );
};

export default ScopeSection;

ScopeSection.propTypes = {
  title: PropTypes.string.isRequired,
  alert: PropTypes.node,
  children: PropTypes.node.isRequired,
  expandable: PropTypes.bool,
  spacing: PropTypes.number,
  subheader: PropTypes.string,
};
