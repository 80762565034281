import { createContext, type PropsWithChildren, useContext } from 'react';

import { useForm } from '../hooks';
import { type HandleError, type HandleWarning } from '../types';

type ErrorHandlingContextProps = {
  error: Record<string, any>;
  handleError: HandleError;
  warning: Record<string, any>;
  handleWarning: HandleWarning;
  isError: boolean;
};

const ErrorHandlingContext = createContext<ErrorHandlingContextProps | null>(
  null,
);

export const ErrorHandlingContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { warning, handleWarning, error, handleError, isError } = useForm();

  return (
    <ErrorHandlingContext.Provider
      value={{
        error,
        handleError,
        warning,
        handleWarning,
        isError,
      }}
    >
      {children}
    </ErrorHandlingContext.Provider>
  );
};

export default ErrorHandlingContextProvider;

export const useErrorHandlingContext = () => {
  const context = useContext(ErrorHandlingContext);

  if (!context) {
    throw new Error(
      'useErrorHandlingContext must be used within the ErrorHandlingContextProvider',
    );
  }

  return context;
};
