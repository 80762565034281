import { TailwindButton as Button } from '../../tailwind';

const CreateChipButton = ({
  createText,
  buttonText,
  onClick,
  show,
}: {
  createText: string;
  buttonText: string;
  onClick: () => void;
  show: boolean;
}) => {
  if (!show) return;

  return (
    <div className="py-1 mx-2">
      <Button variant="ghost" onClick={onClick}>
        {`${buttonText} "${createText}"`}
      </Button>
    </div>
  );
};

export default CreateChipButton;
