import { capitalizeFirstLetter } from '@autone/utils';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

const FilterChip = ({ id, title, dimensionId, handleRemoval }) => (
  <Chip
    label={capitalizeFirstLetter(title)}
    onDelete={() => {
      handleRemoval(dimensionId, id);
    }}
    size="small"
    sx={{ m: 0.5 }}
  />
);

export default FilterChip;

FilterChip.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  dimensionId: PropTypes.string,
  handleRemoval: PropTypes.func,
};
