import { NO_DATA_SUBTITLE, NO_DATA_TITLE } from '@autone/utils';
import {
  Card,
  CircularProgress,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { type CSSProperties, useEffect, useRef } from 'react';
import { FullScreen } from 'react-full-screen';
import {
  type TableComponents as TableComponentsType,
  TableVirtuoso,
  type VirtuosoHandle,
} from 'react-virtuoso';

import { useFullScreen } from '../../../hooks';
import useTableResize from '../../../hooks/useTableResize';
import NoDataMessage from '../components/NoDataMessage';
import { TableContext } from '../contexts/TableContext';
import useTableDynamicWidth from '../hooks/useTableDynamicWidth';
import {
  type DataItem,
  type FullScreenContainer,
  type SortOrder,
  type TablePropsType,
} from '../types';

const NUMBER_OF_SKELETON_ROWS = 8;

type TableBodyOptions<T extends DataItem> = {
  dataItem: T;
  i: number;
  isLoading: boolean;
  scrollLeft: number | null;
  tableWidth: number | null;
};

type ContextProps = {
  context?: {
    scrollLeft: number | null;
    tableWidth: number | null;
    tableStyles?: React.CSSProperties;
    scrollerStyles?: CSSProperties | undefined;
  };
};

type InfiniteScrollActiveProps = {
  endReached: (index: number) => void;
  isMoreDataLoading: boolean;
};

type InfiniteScrollNotActiveProps = {
  endReached?: never;
  isMoreDataLoading?: never;
};

type InfiniteScrollProps =
  | InfiniteScrollActiveProps
  | InfiniteScrollNotActiveProps;

const TableComponents = {
  Scroller: React.forwardRef(
    (
      props: TableComponentsType['Scroller'] &
        ContextProps & { style: CSSProperties },
      ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
      const { context, style, ...rest } = props;
      const { scrollerStyles } = context || {};
      return (
        <div
          ref={ref}
          {...rest}
          style={{
            ...style,
            ...scrollerStyles,
          }}
        />
      );
    },
  ),
  Table: (props: TableComponentsType['Table'] & ContextProps) => {
    const { context: { tableStyles } = {} } = props;
    return (
      <Table
        {...props}
        style={{
          borderCollapse: 'separate',
          ...tableStyles,
        }}
      />
    );
  },
  TableHead: React.forwardRef(
    (
      props: TableComponentsType['TableHead'] & ContextProps,
      ref: React.ForwardedRef<HTMLTableSectionElement>,
    ) => (
      <TableHead
        ref={ref}
        {...props}
        style={{ position: 'sticky', top: 0, zIndex: 99 }}
      />
    ),
  ),
  TableRow: (props: TableComponentsType['TableRow'] & ContextProps) => (
    <TableRow {...props} style={{ backgroundColor: 'white' }} />
  ),
  TableBody: TableBody,
  TableFoot: React.forwardRef(
    (
      props: TableComponentsType['TableFoot'] & ContextProps,
      ref: React.ForwardedRef<HTMLTableSectionElement>,
    ) => (
      <tfoot
        ref={ref}
        {...props}
        style={{ zIndex: 98, position: 'sticky', bottom: '0px' }}
      />
    ),
  ),
};

const TableRowContent = <TDataItem extends DataItem>({
  dataItem,
  i,
  isLoading,
  scrollLeft,
  tableWidth,
  tableBody,
}: {
  dataItem: TDataItem;
  i: number;
  isLoading: boolean;
  scrollLeft: number | null;
  tableWidth: number | null;
  tableBody: (options: TableBodyOptions<TDataItem>) => React.ReactNode;
}) => {
  return <>{tableBody({ dataItem, i, isLoading, scrollLeft, tableWidth })}</>;
};

const MemoisedTableRowContent = React.memo(TableRowContent);

const TableGroupVirtualised = <TDataItem extends DataItem>({
  data,
  height = '100%',
  isLoading,
  sortOrder,
  sortColumn,
  tableClasses,
  tableHeader,
  tableFooter,
  tableBody,
  isTabbed,
  cardHeader,
  tableStyles,
  scrollerStyles,
  tableCardStyles,
  customScrollParent,
  numberOfSkeletonRows = 15,
  endReached,
  isMoreDataLoading,
  scrollIndex,
  noDataLabels = { title: NO_DATA_TITLE, subtitle: NO_DATA_SUBTITLE },
  noResultComponent,
}: {
  data?: TDataItem[];
  height?: string | number;
  isLoading: boolean;
  sortOrder: SortOrder;
  sortColumn: string;
  tableClasses: TablePropsType['tableClasses'];
  tableHeader?: (options: {
    headerData?: TDataItem[];
    isLoading: boolean;
  }) => React.ReactNode;
  tableFooter?: React.ReactNode;
  tableBody: (options: TableBodyOptions<TDataItem>) => React.ReactNode;
  isTabbed?: boolean;
  cardHeader?: (options: {
    isFullScreen: boolean;
    fullScreenContainer: FullScreenContainer;
    handleFullScreenMode: (isFullScreen: boolean) => void;
  }) => React.ReactNode;
  tableStyles?: React.CSSProperties;
  scrollerStyles?: React.CSSProperties;
  tableCardStyles?: SxProps;
  customScrollParent?: HTMLElement | null;
  numberOfSkeletonRows?: number;
  scrollIndex?: number | null;
  noDataLabels?: {
    title: string;
    subtitle: string;
  };
  noResultComponent?: React.ReactNode;
} & InfiniteScrollProps) => {
  const ref = useRef<VirtuosoHandle>(null);
  const tableCardRef = useRef<HTMLDivElement>(null);

  const isTableLoading = isLoading || !data;

  const { scrollLeft, tableWidth, handleResizeCallback, handleSetScrollLeft } =
    useTableDynamicWidth({
      ref: tableCardRef,
      data,
    });

  useTableResize<HTMLDivElement>({ handleResizeCallback, ref: tableCardRef });

  const { handleFullScreenMode, handle, fullScreenChange, isFullScreen } =
    useFullScreen(() => {});

  const fullScreenHeight = window.innerHeight;

  useEffect(() => {
    if (ref.current && scrollIndex != null) {
      if (scrollIndex !== -1) {
        ref.current.scrollToIndex({ index: scrollIndex, behavior: 'smooth' });
      }
    }
  }, [data, scrollIndex]);

  return (
    <TableContext.Provider
      value={{
        data,
        isLoading,
        tableProps: {
          sortOrder,
          sortColumn,
          tableClasses,
        },
      }}
    >
      <FullScreen handle={handle} onChange={fullScreenChange}>
        <Card
          ref={tableCardRef}
          sx={{
            ...(isTabbed && {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }),
            ...tableCardStyles,
            height,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {cardHeader &&
            cardHeader({
              isFullScreen,
              fullScreenContainer: handle.node,
              handleFullScreenMode,
            })}
          {data?.length === 0 ? (
            noResultComponent ? (
              noResultComponent
            ) : (
              <NoDataMessage
                title={noDataLabels.title}
                subtitle={noDataLabels.subtitle}
                sx={{
                  height,
                }}
              />
            )
          ) : (
            <TableVirtuoso
              ref={ref}
              style={isFullScreen ? { height: fullScreenHeight } : { height }}
              onScroll={(e) => handleSetScrollLeft(e)}
              data={
                isTableLoading
                  ? new Array(
                      numberOfSkeletonRows || NUMBER_OF_SKELETON_ROWS,
                    ).fill(1)
                  : data
              }
              // @ts-ignore - I've been unable to fully type this, I've added types to TableComponents as per docs, but I must be missing something. Ignoring for now.
              components={TableComponents}
              customScrollParent={
                customScrollParent ? customScrollParent : undefined
              }
              fixedHeaderContent={() =>
                tableHeader && (
                  <TableRow>
                    {tableHeader(
                      data
                        ? { headerData: data as TDataItem[], isLoading: false }
                        : { headerData: undefined, isLoading: true },
                    )}
                  </TableRow>
                )
              }
              context={{ scrollLeft, tableWidth, tableStyles, scrollerStyles }}
              itemContent={(index, dataItem: TDataItem, context) => {
                const { scrollLeft, tableWidth } = context;
                return (
                  <MemoisedTableRowContent
                    dataItem={dataItem}
                    i={index}
                    isLoading={isTableLoading}
                    scrollLeft={scrollLeft}
                    tableWidth={tableWidth}
                    // @ts-ignore  - TODO: struggling to type this
                    tableBody={tableBody}
                  />
                );
              }}
              fixedFooterContent={() =>
                isMoreDataLoading ? (
                  <>
                    <TableRow>
                      <Stack alignItems="center" width="100%" py={1}>
                        <CircularProgress size={22} />
                      </Stack>
                    </TableRow>
                    {tableFooter}
                  </>
                ) : (
                  tableFooter
                )
              }
              endReached={endReached}
            />
          )}
        </Card>
      </FullScreen>
    </TableContext.Provider>
  );
};

export default TableGroupVirtualised;
