import { Box, useTheme } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';

const PageHeaderContainer = ({ children, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        mb: theme.spacing(2),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default PageHeaderContainer;

PageHeaderContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  sx: PropTypes.object,
};
