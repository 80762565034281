export const SwitchArrowsSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.45113 1.40086C8.5979 1.23575 8.85073 1.22087 9.01584 1.36764L10.5159 2.70097C10.681 2.84774 10.6958 3.10057 10.5491 3.26568C10.4023 3.4308 10.1495 3.44567 9.98437 3.2989L8.48435 1.96557C8.31924 1.8188 8.30437 1.56598 8.45113 1.40086ZM4.2251 3.99992C4.2251 3.779 4.40418 3.59992 4.6251 3.59992H11.3751C11.5412 3.59992 11.69 3.70253 11.749 3.85776C11.808 4.013 11.765 4.18855 11.6408 4.29888L9.01584 6.63221C8.85073 6.77898 8.5979 6.76411 8.45113 6.599C8.30437 6.43388 8.31924 6.18105 8.48435 6.03429L10.323 4.39992H4.6251C4.40418 4.39992 4.2251 4.22083 4.2251 3.99992Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.47913 9.41674C7.61714 9.58924 7.58917 9.84096 7.41666 9.97896L5.75 11.3123C5.57749 11.4503 5.32578 11.4223 5.18777 11.2498C5.04977 11.0773 5.07774 10.8256 5.25024 10.6876L6.91691 9.35427C7.08941 9.21626 7.34113 9.24423 7.47913 9.41674ZM3.87267 11.8675C3.9289 11.7072 4.08024 11.5999 4.25012 11.5999H11.7501C11.971 11.5999 12.1501 11.779 12.1501 11.9999C12.1501 12.2208 11.971 12.3999 11.7501 12.3999H5.39043L7.41666 14.0209C7.58917 14.1589 7.61714 14.4106 7.47913 14.5831C7.34113 14.7556 7.08941 14.7836 6.91691 14.6456L4.00024 12.3123C3.86759 12.2062 3.81644 12.0278 3.87267 11.8675Z"
      fill="currentColor"
    />
  </svg>
);
