import { capitalizeFirstLetter } from '@autone/utils';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Chip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';

import LightTooltip from '../LightTooltip';

import OverLimitTooltipChips from './OverLimitTooltipChips';

type SelectedChipsProps = {
  optionsLimit: number;
  optionsLimitLabel: string;
  handleDeleteCallback: (id: string, title: string, index: number) => void;
  placeholder?: string;
  selectedOptions?: { id: string; title: string; active?: boolean }[];
  handleClearAll: () => void;
  tagTitle: string;
};

const SelectedChips = ({
  optionsLimit,
  optionsLimitLabel,
  handleDeleteCallback,
  placeholder,
  selectedOptions,
  handleClearAll,
  tagTitle,
}: SelectedChipsProps) => {
  const noSelectedOptions = !selectedOptions || selectedOptions.length === 0;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {/* if there are no selected options or if selected options are null due to options being loaded than show placeholder chip */}
      {noSelectedOptions && placeholder && <Chip label={placeholder} />}
      {/* if there are seleted options but the number of selected options is over the limit than render the chip with tooltip */}
      {selectedOptions &&
      selectedOptions.length > 0 &&
      selectedOptions.length > optionsLimit ? (
        <LightTooltip
          sx={{
            [`& .${tooltipClasses.tooltip}`]: {
              maxWidth: 500,
            },
          }}
          title={
            <OverLimitTooltipChips
              selectedOptions={selectedOptions}
              handleDeleteCallback={handleDeleteCallback}
              title={tagTitle}
            />
          }
          enterNextDelay={250}
        >
          <Chip
            label={optionsLimitLabel}
            deleteIcon={
              <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
            }
            onDelete={() => handleClearAll()}
          />
        </LightTooltip>
      ) : (
        // if there are seleted options but the number of selected options is below the limit than render the individual filter chips
        selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions.map(({ id, title }, index) => {
          return (
            // if below limit render normal chips
            <LightTooltip
              key={`${id}-${title}`}
              enterDelay={1000}
              title={capitalizeFirstLetter(title)}
            >
              <Chip
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  maxWidth: '180px',
                }}
                label={capitalizeFirstLetter(title)}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={() => handleDeleteCallback(id, title, index)}
              />
            </LightTooltip>
          );
        })
      )}
    </Box>
  );
};

export default SelectedChips;
