import { keyframes } from '@emotion/react';
import {
  Box,
  Card,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import Page from '../../components/page/Page';
import { CircularTickSvg } from '../../svgs/CircularTickSvg';

type ChecklistLoadingPageProps = {
  stepData: { step: string; timing: number }[];
  subTitle?: string;
  pageTitle: string;
  loadingComplete: boolean;
  redirectDelay: number;
  gradientDimensions?: string;
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const progressSharedStyles = {
  variant: 'determinate',
  size: 32,
  thickness: 4,
} as {
  variant: 'determinate' | 'indeterminate';
  size: number;
  thickness: number;
};

const StepItem = ({
  description,
  progress = 0,
  ticked,
  isCurrentStep,
}: {
  description: string;
  progress: number;
  ticked: boolean;
  isCurrentStep: boolean;
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {ticked ? (
        <Box
          sx={{
            animation: `${fadeIn} 0.2s ease-in`,
          }}
        >
          <CircularTickSvg />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <CircularProgress
            {...progressSharedStyles}
            value={100}
            sx={{ color: 'grey.300' }}
          />
          <CircularProgress
            {...progressSharedStyles}
            value={progress}
            sx={{ position: 'absolute', left: 0 }}
          />
        </Box>
      )}

      <Typography
        variant="h5"
        sx={{
          fontWeight: 'normal',
          ...(!ticked && !isCurrentStep && { color: 'text.secondary' }),
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
};

const ChecklistLoadingPage = ({
  stepData,
  subTitle,
  pageTitle,
  loadingComplete,
  redirectDelay,
  gradientDimensions,
}: ChecklistLoadingPageProps) => {
  const theme = useTheme();

  const [progressBar, setProgressBar] = useState({
    stepNumber: 0,
    progress: 0,
  });
  const [tickedSteps, setTickedSteps] = useState<number[]>([]);
  const [intervalValue, setIntervalValue] = useState(100);
  const [stepNumberAtComplete, setStepNumberAtComplete] = useState<number>(0);

  const timings = stepData.map((stepObject) => stepObject.timing);
  const lastStep = stepData.length - 1;

  /* we want to work out the number of steps remaining so that we can split the remaining time
    evenly between them to ensure a smooth transition when loading is complete */
  const tickRemainingSteps = (stepNumber: number) => {
    const stepsLeft = stepData.length - stepNumberAtComplete;
    setIntervalValue(redirectDelay / stepsLeft);
    setTickedSteps((tickedSteps) => [...tickedSteps, stepNumber]);
    return {
      stepNumber: stepNumber + 1,
      progress: 100,
    };
  };

  // when loading is complete, set the step number at complete to calculate the remaining steps
  useEffect(() => {
    if (loadingComplete) {
      setStepNumberAtComplete(progressBar.stepNumber);
    }
  }, [loadingComplete]);

  useEffect(() => {
    let rateOfChange = 10;

    const timer = setInterval(() => {
      setProgressBar(
        ({
          stepNumber,
          progress,
        }: {
          stepNumber: number;
          progress: number;
        }) => {
          const isLastStep = stepNumber === lastStep;

          if (loadingComplete) {
            return tickRemainingSteps(stepNumber);
          }
          // if on the last step, slow down the progress bar so that it doesn't reach 100% before loading is complete
          if (isLastStep) {
            rateOfChange = 1;
          }

          // increment the progress bar by a percentage of the rate of change, based on the timing of the current step
          const incrementValue = rateOfChange / (timings[stepNumber] / 1000);

          // if the progress bar is at 100%, and we are not on the last step, tick the current step and move to the next step
          if (progress === 100 && stepNumber < lastStep) {
            setTickedSteps((tickedSteps) => [...tickedSteps, stepNumber]);
            return {
              stepNumber: stepNumber + 1,
              progress: 0,
            };
          }

          return {
            stepNumber,
            progress: Math.min(progress + incrementValue, 100),
          };
        },
      );
    }, intervalValue);

    return () => {
      clearInterval(timer);
    };
  }, [loadingComplete, intervalValue]);

  return (
    <Page
      title={pageTitle}
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{
          width: '100%',
          height: '100%',
          background: `radial-gradient(${gradientDimensions}, ${
            theme.palette.primary.main
          }, ${alpha(theme.palette.primary.main, 0.15)}, ${
            theme.palette.background.default
          })`,
        }}
      >
        <Card sx={{ p: theme.spacing(6), width: 'fit-content' }}>
          <Stack
            sx={{
              width: '100%',
              justifyContent: 'center',
              textAlign: 'left',
            }}
            spacing={1.5}
          >
            <Typography sx={{ color: 'text.secondary' }}>{subTitle}</Typography>
            <Stack spacing={1}>
              {stepData.map(({ step }, index) => {
                const isCurrentStep = index === progressBar.stepNumber;
                const progress = isCurrentStep ? progressBar.progress : 0;
                const ticked = tickedSteps.includes(index);
                return (
                  <StepItem
                    key={index}
                    description={step}
                    progress={progress}
                    ticked={ticked}
                    isCurrentStep={isCurrentStep}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Page>
  );
};

export default ChecklistLoadingPage;
