import { CircularProgress, Checkbox as MuiCheckbox } from '@mui/material';
import React, { forwardRef } from 'react';

import LightTooltip from '../LightTooltip';

type Props = {
  loading: boolean;
  checked: boolean;
  disabled: boolean;
  indeterminate: boolean;
  onChangeCallback: () => void | Promise<void>;
  tooltipText?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
};

// note we are keeping loading, checked, disabled naming convention to align with MUI Checkbox
// usually we denote as isLoading, isChecked, isDisabled
const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      loading,
      checked,
      disabled,
      onChangeCallback = () => {},
      tooltipText,
      onKeyDown,
      indeterminate,
    },
    ref,
  ) => {
    return (
      <>
        {loading ? (
          <CircularProgress sx={{ ml: 1, mt: 1 }} size={24} />
        ) : (
          <LightTooltip title={tooltipText}>
            <span>
              <MuiCheckbox
                inputRef={ref}
                checked={checked}
                onChange={() => onChangeCallback()}
                disabled={disabled}
                onKeyDown={onKeyDown}
                indeterminate={indeterminate}
              />
            </span>
          </LightTooltip>
        )}
      </>
    );
  },
);

export default Checkbox;
