import {
  ListSubheader as MuiListSubHeader,
  type SxProps,
  useTheme,
} from '@mui/material';

const ListSubheader = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx: SxProps;
}) => {
  const theme = useTheme();

  return (
    <MuiListSubHeader
      sx={{
        color: 'text.primary',
        typography: 'body2',
        fontWeight: 'bold',
        pt: theme.spacing(3),
        pb: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      {children}
    </MuiListSubHeader>
  );
};

export default ListSubheader;
