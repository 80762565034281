'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { type PropsWithChildren } from 'react';

import { cn } from './utils';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipArrow = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow> & {
    variant?: 'default' | 'white';
  }
>(({ className, variant = 'default', ...props }, ref) => (
  <TooltipPrimitive.Arrow
    ref={ref}
    className={cn(
      variant === 'default' ? 'fill-grey-100' : 'fill-white',
      className,
    )}
    {...props}
  />
));

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    variant?: 'default' | 'white';
    container?: React.ComponentPropsWithoutRef<
      typeof TooltipPrimitive.Portal
    >['container'];
  }
>(
  (
    { className, sideOffset = 4, container, variant = 'default', ...props },
    ref,
  ) => {
    return (
      <TooltipPrimitive.Portal container={container}>
        <TooltipPrimitive.Content
          ref={ref}
          sideOffset={sideOffset}
          className={cn(
            'z-[1200] overflow-hidden rounded-md px-3 py-1.5 text-sm shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            variant === 'default'
              ? 'bg-grey-100 text-paper'
              : 'bg-white text-gray-900 border border-gray-200',
            className,
          )}
          {...props}
        />
      </TooltipPrimitive.Portal>
    );
  },
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const AutoneTooltip: React.FC<
  PropsWithChildren<
    {
      text?: string | null | React.ReactNode;
      asChild?: boolean;
      arrow?: React.ReactNode | false;
      variant?: 'default' | 'white';
      alwaysOpen?: boolean;
    } & React.ComponentPropsWithoutRef<typeof TooltipContent> & {
        container?: React.ComponentPropsWithoutRef<
          typeof TooltipPrimitive.Portal
        >['container'];
      }
  >
> = ({
  children,
  text,
  asChild,
  arrow = <TooltipArrow />,
  variant = 'default',
  alwaysOpen,
  ...props
}) => {
  if (!text) {
    return <>{children}</>;
  }

  return (
    <TooltipProvider>
      <Tooltip delayDuration={300} open={alwaysOpen}>
        <TooltipTrigger asChild={asChild}>
          <div>{children}</div>
        </TooltipTrigger>
        <TooltipContent variant={variant} {...props}>
          {text}
          {arrow && React.isValidElement(arrow)
            ? React.cloneElement(arrow as React.ReactElement, { variant })
            : arrow}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export { AutoneTooltip, TooltipArrow as AutoneTooltipArrow };
