export const ItFlag = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.799988" width="22.4" height="16" rx="3" fill="white" />
    <mask
      id="mask0_875_99"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="16"
    >
      <rect x="0.799988" width="22.4" height="16" rx="3" fill="white" />
    </mask>
    <g mask="url(#mask0_875_99)">
      <rect x="16" width="7.2" height="16" fill="#CE2B37" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.799988 16H7.99999V0H0.799988V16Z"
        fill="#009246"
      />
    </g>
  </svg>
);
