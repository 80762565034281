import { Popover } from '@mui/material';
import {
  type CustomTheme,
  experimentalStyled as styled,
} from '@mui/material/styles';

export const ListSubheaderStyles = (
  _theme: CustomTheme,
  isCollapse: boolean,
) => ({
  pb: 0.5,
  ...(isCollapse
    ? {
        justifyContent: 'center',
      }
    : { pl: '36px' }),
  fontSize: 'body1.fontSize',
  position: 'relative',
});

export const ListItemStyles = (theme: CustomTheme, isCollapse: boolean) => ({
  width: 'auto',
  color: theme.palette.text.primary,
  borderRadius: `${theme.shape.borderRadius}px`,
  marginBottom: theme.spacing(0.4),
  '&: hover': { bgcolor: 'inherit', color: 'primary.main' },
  ...(!isCollapse
    ? {
        height: 48,
        px: '20px',
        py: '11px',
        mx: 2,
        fontSize: 'body1.fontSize',
      }
    : {
        display: 'flex',
        padding: 0,
        mx: 1,
        ...theme.typography.body2,
      }),
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
      backgroundColor: theme.palette.primary.main,
    },
  },
});

export const PaperStyle = styled(Popover)(({ theme }) => {
  const customTheme = theme as unknown as CustomTheme;
  return {
    pointerEvents: 'none',
    '& .MuiPopover-paper': {
      width: 160,
      pointerEvents: 'auto',
      padding: 0.5,
      borderRadius: Number(customTheme.shape.borderRadius) * 1.5,
      boxShadow: customTheme.customShadows.dropdown,
    },
  };
});

export const SubListItemStyles = (theme: CustomTheme) => ({
  textTransform: 'capitalize',
  width: 'auto',
  color: 'text.primary',
  borderRadius: `${theme.shape.borderRadius}px`,
  marginBottom: 0.2,
  '&: hover': { bgcolor: 'inherit', color: 'primary.main' },
  display: 'flex',
  padding: 0.5,
  pt: 0.5,
  margin: 0.5,
  ...theme.typography.body1,
  '&.isActiveSub': {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& .subIcon:before': {
      transform: 'scale(2)',
    },
  },
});

export const SubIconStyle = styled('span')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    width: 4,
    height: 4,
    content: "''",
    display: 'block',
    borderRadius: '50%',
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('transform'),
  },
}));
