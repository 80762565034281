import { forwardRef, type HTMLAttributes } from 'react';

import { cn, TailwindCard, TailwindSkeleton } from '../../tailwind';

export const ExplainedKPICard = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <TailwindCard
      className={cn('flex flex-col p-6 gap-4', className)}
      {...props}
      ref={ref}
    ></TailwindCard>
  );
});

ExplainedKPICard.displayName = 'ExplainedKPICard';

export const ExplainedKPICardIcon = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        'self-start grid place-items-center aspect-square bg-primary-10 p-2 rounded-md',
        className,
      )}
      {...props}
      ref={ref}
    ></div>
  );
});

ExplainedKPICardIcon.displayName = 'ExplainedKPICardIcon';

export const ExplainedKPIMetric = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        'bg-primary-10 text-primary-100 p-1.5 rounded-lg inline-block font-medium',
        className,
      )}
      {...props}
      ref={ref}
    ></div>
  );
});

ExplainedKPIMetric.displayName = 'ExplainedKPIMetric';

export const ExplainedKPICardHeader = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      className={cn(
        'w-full flex flex-col gap-1 first-letter:uppercase',
        className,
      )}
      {...props}
      ref={ref}
    >
      {children}
    </div>
  );
});

ExplainedKPICardHeader.displayName = 'ExplainedKPICardHeader';

export const ExplainedKPICardValue = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <h2
      className={cn('text-2xl font-semibold leading-none', className)}
      {...props}
      ref={ref}
    >
      {children}
    </h2>
  );
});

ExplainedKPICardValue.displayName = 'ExplainedKPICardValue';

export const ExplainedKPICardTitle = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <h5
      className={cn('text-base font-semibold leading-none', className)}
      {...props}
      ref={ref}
    >
      {children}
    </h5>
  );
});

ExplainedKPICardTitle.displayName = 'ExplainedKPICardTitle';

export const ExplainedKPICardSubtitle = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <p
      className={cn('text-sm font-normal text-grey-60', className)}
      {...props}
      ref={ref}
    >
      {children}
    </p>
  );
});

ExplainedKPICardSubtitle.displayName = 'ExplainedKPICardSubtitle';

export const ExplainedKPICardSkeleton = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ ...props }, ref) => {
  return (
    <ExplainedKPICard {...props} ref={ref}>
      <div className="h-8 flex flex-row gap-4">
        <TailwindSkeleton className="aspect-square h-full" />
        <TailwindSkeleton className="h-full grow basis-0" />
      </div>
      <TailwindSkeleton className="grow basis-0" />
      <TailwindSkeleton className="h-4" />
    </ExplainedKPICard>
  );
});

ExplainedKPICardSkeleton.displayName = 'ExplainedKPICardSkeleton';
