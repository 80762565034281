import PropTypes from 'prop-types';

const RebalancingIcon = ({ size = 22, color = '#01172F' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 30 28"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5615 10.962C26.3532 10.9634 26.1504 10.8954 25.9851 10.7686C25.8199 10.6418 25.7016 10.4635 25.6491 10.2619C25.0106 7.85575 23.5946 5.72815 21.6214 4.21029C19.6483 2.69242 17.2287 1.86944 14.7392 1.86944C12.2498 1.86944 9.83019 2.69242 7.85703 4.21029C5.88387 5.72815 4.46784 7.85575 3.82936 10.2619C3.76469 10.5039 3.60654 10.7103 3.3897 10.8357C3.17285 10.9611 2.91508 10.9952 2.67309 10.9305C2.4311 10.8658 2.22471 10.7077 2.09932 10.4908C1.97393 10.274 1.93983 10.0162 2.0045 9.77423C2.75505 6.97034 4.40999 4.49253 6.71257 2.72524C9.01515 0.957957 11.8366 0 14.7392 0C17.6418 0 20.4633 0.957957 22.7659 2.72524C25.0685 4.49253 26.7234 6.97034 27.474 9.77423C27.5061 9.89406 27.5142 10.019 27.498 10.142C27.4817 10.265 27.4414 10.3836 27.3793 10.491C27.3172 10.5983 27.2346 10.6924 27.1361 10.7679C27.0376 10.8433 26.9252 10.8985 26.8054 10.9305C26.726 10.9525 26.6439 10.9631 26.5615 10.962Z"
      fill={color}
    />
    <path
      d="M15.4341 27.5294C12.5312 27.6108 9.68266 26.7314 7.33085 25.0277C4.97903 23.3241 3.25555 20.8916 2.42806 18.1079C2.35658 17.8679 2.3834 17.6092 2.50261 17.3889C2.62182 17.1686 2.82366 17.0047 3.06373 16.9332C3.3038 16.8618 3.56243 16.8886 3.78273 17.0078C4.00303 17.127 4.16695 17.3288 4.23842 17.5689C4.94461 19.9561 6.42017 22.0428 8.43541 23.5044C10.4507 24.9659 12.8925 25.7202 15.381 25.6499C17.8694 25.5796 20.2648 24.6886 22.1943 23.1156C24.1238 21.5426 25.4792 19.3758 26.0495 16.9526C26.1073 16.7089 26.2595 16.4981 26.4727 16.3666C26.686 16.2352 26.9427 16.1938 27.1864 16.2516C27.4301 16.3094 27.6409 16.4617 27.7723 16.6749C27.9038 16.8881 27.9452 17.1448 27.8874 17.3885C27.2184 20.2145 25.635 22.7404 23.3832 24.5742C21.1313 26.4079 18.337 27.4467 15.4341 27.5294Z"
      fill={color}
    />
    <path
      d="M22.8253 9.82173C21.8814 9.26326 22.5186 7.76876 23.5726 8.05979L23.7142 8.10699L26.3807 9.23966L27.5133 6.57316C27.6042 6.35853 27.77 6.1843 27.9799 6.08298C28.1898 5.98166 28.4293 5.96018 28.6539 6.02255L28.7797 6.06188C28.9944 6.15271 29.1686 6.31854 29.2699 6.52842C29.3712 6.73829 29.3927 6.97787 29.3303 7.20242L29.291 7.32827L27.7808 10.8836C27.6899 11.0982 27.5241 11.2725 27.3142 11.3738C27.1044 11.4751 26.8648 11.4966 26.6402 11.4342L26.5222 11.3949L22.9669 9.88466L22.8253 9.82173Z"
      fill={color}
    />
    <path
      d="M6.59006 16.6916C7.54127 17.2156 6.95469 18.7351 5.89287 18.474L5.74192 18.4232L3.04449 17.3663L1.99544 20.0635C1.91216 20.282 1.75196 20.4626 1.54493 20.5713C1.3379 20.68 1.0983 20.7094 0.871131 20.6539L0.744218 20.6182C0.526091 20.532 0.346317 20.3701 0.237833 20.1622C0.129349 19.9543 0.099421 19.7142 0.153531 19.486L0.197375 19.3667L1.59872 15.7704C1.68201 15.5519 1.84221 15.3713 2.04924 15.2626C2.25626 15.1538 2.49587 15.1244 2.72303 15.1799L2.85017 15.2235L6.44653 16.6249L6.59006 16.6916Z"
      fill={color}
    />
  </svg>
);

RebalancingIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default RebalancingIcon;
