import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { forwardRef } from 'react';

interface HighchartsBaseProps {
  highchartsOptions: Highcharts.Options;
  highchartsReactProps?: HighchartsReact.Props;
}

export const HighchartsBase = forwardRef<
  HighchartsReact.RefObject,
  HighchartsBaseProps
>(
  (
    { highchartsOptions = {}, highchartsReactProps = {} }: HighchartsBaseProps,
    ref,
  ) => {
    // disable watermark
    highchartsOptions.credits = { enabled: false };

    // disable default 'Chart Title' text
    highchartsOptions.title = highchartsOptions.title ?? undefined;

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={highchartsOptions}
        ref={ref}
        containerProps={{
          style: { height: '100%', width: '100%', padding: 0 },
        }}
        {...highchartsReactProps}
      />
    );
  },
);

HighchartsBase.displayName = 'HighchartsBase';
