import { generatePath } from './generate-paths';

export const HUB_ROOT = '/hub';

export const HUB_PATH_APP = {
  root: generatePath(HUB_ROOT, '/modules'),
  analytics: generatePath(HUB_ROOT, '/analytics'),
  parameters: generatePath(HUB_ROOT, '/parameters'),
  parameter: generatePath(HUB_ROOT, '/parameters/:parameterPath'),
  settings: generatePath(HUB_ROOT, '/settings'),
  userManagement: generatePath(HUB_ROOT, '/user-management'),
  budgets: generatePath(HUB_ROOT, '/budgets/:budgetId'),
  budget: generatePath(
    HUB_ROOT,
    '/budgets/:budgetId/budget-lines/:budgetLineId',
  ),
} as const;
