import { capitalizeFirstLetter } from '@autone/utils';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Chip, Divider, Typography } from '@mui/material';

// render chips with tooltip when selected are over a given limit
const OverLimitTooltipChips = ({
  selectedOptions,
  handleDeleteCallback,
  title,
}: {
  selectedOptions: { id: string; title: string }[];
  handleDeleteCallback: (id: string, title: string, index: number) => void;
  title: string;
}) => {
  return (
    <Box
      sx={{
        p: 1,
        maxHeight: '300px',
        overflowY: 'scroll',
      }}
    >
      {title && (
        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
          {title}
        </Typography>
      )}
      <Divider sx={{ fontWeight: 'bold', mb: 1, mt: 1 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '5px',
          alignItems: 'start',
        }}
      >
        {(selectedOptions || []).map(({ id, title }, index) => (
          <Chip
            sx={{ fontWeight: 500 }}
            key={`${id}-${title}`}
            label={capitalizeFirstLetter(title)}
            size="small"
            deleteIcon={
              <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
            }
            onDelete={() => handleDeleteCallback(id, title, index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default OverLimitTooltipChips;
