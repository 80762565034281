export const CrossIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.96967 2.96967C3.26256 2.67678 3.73744 2.67678 4.03033 2.96967L7.53033 6.46967L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L7 8.06066L4.03033 11.0303C3.73744 11.3232 3.26256 11.3232 2.96967 11.0303C2.67678 10.7374 2.67678 10.2626 2.96967 9.96967L5.93934 7L2.96967 4.03033C2.67678 3.73744 2.67678 3.26256 2.96967 2.96967ZM11.0303 2.96967C11.3232 3.26256 11.3232 3.73744 11.0303 4.03033L8.697 6.36366C8.4041 6.65656 7.92923 6.65656 7.63634 6.36366C7.34344 6.07077 7.34344 5.5959 7.63634 5.303L9.96967 2.96967C10.2626 2.67678 10.7374 2.67678 11.0303 2.96967Z"
        fill="currentColor"
      />
    </svg>
  );
};
