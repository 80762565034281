import React from 'react';

import { type tableStyles } from '../../../utils';
import {
  type DataItem,
  type FullScreenContainer,
  type SharedTableContextTypes,
  type SortOrder,
  type TablePropsType,
} from '../types';

type VirualisedTablePropsType = {
  sortOrder: SortOrder;
  sortColumn: string;
  tableClasses: typeof tableStyles;
};

type TableContextType<
  TTablePropsType extends Record<string, unknown>,
  TDataItem extends DataItem = DataItem,
> = {
  data?: TDataItem[];
  customRowFormat?: (dataItem: TDataItem) => Record<string, unknown>;
  tableProps: TTablePropsType;
} & SharedTableContextTypes;

export const TableContext = React.createContext<TableContextType<
  TablePropsType | VirualisedTablePropsType
> | null>(null);

export const useTableContext = <
  TTablePropsType extends TablePropsType,
  TDataItem extends DataItem = DataItem,
>() => {
  const context = React.useContext(TableContext);

  if (!context) {
    throw new Error('useTableContext must be used within a TableGroup');
  }
  return context as {
    data?: TDataItem[];
    customRowFormat?: (dataItem: TDataItem) => Record<string, unknown>;
    tableProps: TTablePropsType;
    isFullScreen: boolean;
    fullScreenContainer: FullScreenContainer;
  } & SharedTableContextTypes;
};
