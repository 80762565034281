import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';

const useGetUserIdFromToken = () => {
  //@ts-expect-error
  const { idToken: token } = useSelector((state) => state.user);
  // catch needed to avoid error when token is undefined
  if (token) {
    const decodedJWT = jwtDecode(token);
    //@ts-expect-error
    const { user_id: userId } = decodedJWT;
    return userId;
  }

  return undefined;
};

export default useGetUserIdFromToken;
