import { PropTypes } from 'prop-types';
import React from 'react';

import { TabsGreyStyled, TabsMinimalStyled } from './styles';

const TabsWrapper = ({
  children,
  value,
  onChange,
  ariaLabel,
  variant = 'grey',
  sx,
}) => {
  return (
    <>
      {variant === 'grey' && (
        <TabsGreyStyled
          value={value}
          onChange={onChange}
          aria-label={ariaLabel}
          sx={{ ...sx }}
        >
          {children}
        </TabsGreyStyled>
      )}
      {variant === 'minimal' && (
        <TabsMinimalStyled
          value={value}
          onChange={onChange}
          aria-label={ariaLabel}
          sx={{ ...sx }}
        >
          {children}
        </TabsMinimalStyled>
      )}
    </>
  );
};

export default TabsWrapper;

TabsWrapper.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  ariaLabel: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['grey', 'minimal']),
  sx: PropTypes.object,
};
