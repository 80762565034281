import {
  type GeographicFilters,
  type ListSkusPagedFilters,
  type ProductFilters,
} from '@autone/openapi-core';
import { atom, type PrimitiveAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import {
  type Control,
  type FieldValues,
  type Path,
  useWatch,
} from 'react-hook-form';

import {
  useGetCoreBrandsPagedQuery,
  useGetCoreDepartmentsPagedQuery,
  useGetCoreProductsPagedQuery,
  useGetCoreSkusPagedQuery,
  useGetLocationOptionsPagedQuery,
} from '../../../redux/services';
import { type GeographicFields, type ProductFields } from '../types';

type CountState = {
  isLoading: boolean;
  count: null | number;
};
export const locationsCountAtom = atom<CountState>({
  isLoading: false,
  count: null,
});

export const productsCountAtom = atom<CountState>({
  isLoading: false,
  count: null,
});

export const departmentsCountAtom = atom<CountState>({
  isLoading: false,
  count: null,
});

export const brandsCountAtom = atom<CountState>({
  isLoading: false,
  count: null,
});

export const skusCountAtom = atom<CountState>({
  isLoading: false,
  count: null,
});

type UseUpdateCount = {
  atom: PrimitiveAtom<CountState>;
  noSelectionsState: {
    isLoading: boolean;
    totalCount: number | null;
  };
  selectedCount?: number;
};

export const useUpdateCount = ({
  atom,
  noSelectionsState,
  selectedCount,
}: UseUpdateCount) => {
  const setCount = useSetAtom(atom);

  const { isLoading, totalCount } = noSelectionsState;

  const count = selectedCount || totalCount;

  useEffect(() => {
    if (isLoading) {
      setCount({
        count: null,
        isLoading: true,
      });
    } else {
      setCount({
        count: count,
        isLoading: false,
      });
    }

    // Upon unmounting set the count to null
    return () => {
      setCount({
        count: null,
        isLoading: false,
      });
    };
  }, [isLoading, count]);
};

export const useGetSelectedCount = <T extends FieldValues>({
  name,
  control,
}: {
  name: Path<T>;
  control: Control<T>;
}): number => {
  const fieldState = useWatch({ control, name });
  const selectedCount =
    fieldState && Array.isArray(fieldState) ? (fieldState.length as number) : 0;
  return selectedCount;
};

export const useSyncProductsCount = ({
  filters,
  control,
  name,
}: {
  filters?: ProductFilters;
  control: Control<ProductFields>;
  name: Path<ProductFields>;
}) => {
  const { data, isLoading, isFetching } = useGetCoreProductsPagedQuery({
    filters: { product: filters || {} },
    cursor: null,
    sortBy: 'product_id:asc',
    limit: 10,
  });

  const selectedCount = useGetSelectedCount({ control, name });

  useUpdateCount({
    atom: productsCountAtom,
    noSelectionsState: {
      isLoading: isLoading || isFetching,
      totalCount: data?.page_info.total_count ?? null,
    },
    selectedCount,
  });
};

export const useSyncDepartmentsCount = ({
  filters,
  control,
  name,
}: {
  filters?: ProductFilters;
  control: Control<ProductFields>;
  name: Path<ProductFields>;
}) => {
  const { data, isLoading, isFetching } = useGetCoreDepartmentsPagedQuery({
    sortBy: 'department_desc:asc',
    filters: {
      product: filters || {},
    },
    limit: 100,
    cursor: null,
  });

  const selectedCount = useGetSelectedCount({ control, name });

  useUpdateCount({
    atom: departmentsCountAtom,
    noSelectionsState: {
      isLoading: isLoading || isFetching,
      totalCount: data?.page_info.total_count ?? null,
    },
    selectedCount,
  });
};

export const useSyncBrandsCount = ({
  filters,
  control,
  name,
}: {
  filters?: ProductFilters;
  control: Control<ProductFields>;
  name: Path<ProductFields>;
}) => {
  const { data, isLoading, isFetching } = useGetCoreBrandsPagedQuery({
    sortBy: 'brand_desc:asc',
    filters: {
      product: filters || {},
    },
    limit: 100,
    cursor: null,
  });

  const selectedCount = useGetSelectedCount({ control, name });

  useUpdateCount({
    atom: brandsCountAtom,
    noSelectionsState: {
      isLoading: isLoading || isFetching,
      totalCount: data?.page_info.total_count ?? null,
    },
    selectedCount,
  });
};

export const useSyncLocationsCount = ({
  filters,
  control,
  name,
}: {
  filters?: GeographicFilters;
  control: Control<GeographicFields>;
  name: Path<GeographicFields>;
}) => {
  const { data, isLoading, isFetching } = useGetLocationOptionsPagedQuery({
    sortBy: 'location_desc:asc',
    filters: { geographic: filters || {} },
    limit: 10,
    cursor: null,
  });

  const selectedCount = useGetSelectedCount({ control, name });

  useUpdateCount({
    atom: locationsCountAtom,
    noSelectionsState: {
      isLoading: isLoading || isFetching,
      totalCount: data?.page_info.total_count ?? null,
    },
    selectedCount,
  });
};

export const useSyncSkusCount = ({
  filters,
}: {
  filters?: ListSkusPagedFilters;
}) => {
  const { data, isLoading, isFetching } = useGetCoreSkusPagedQuery({
    filters: {
      product: filters?.product || {},
      sku: filters?.sku || {},
    },
    cursor: null,
    sortBy: 'sku_id:asc',
    limit: 100,
  });

  useUpdateCount({
    atom: skusCountAtom,
    noSelectionsState: {
      isLoading: isLoading || isFetching,
      totalCount: data?.page_info.total_count ?? null,
    },
  });
};
