const icons: { [key: string]: number } = {
  xxs: 12,
  xs: 16,
  sm: 18,
  md: 20,
  lg: 24,
  xl: 32,
};

export default icons;
