import { type AuthTokens } from '@aws-amplify/auth';
export const redirectToAuth = () => {
  // No logged-in user: redirect to auth
  let protocol = 'https';
  let authDomain = process.env.REACT_APP_AUTH_DOMAIN;

  if (window.location.host.startsWith('localhost')) {
    protocol = 'http';
    authDomain = 'localhost:3000';
  }

  window.location.replace(
    `${protocol}://${authDomain}/login?redirect=${window.location.href}`,
  );
};

export const redirectToApp = () => {
  if (window.location.host.startsWith('localhost')) {
    window.location.replace('http://localhost:3979');
  } else {
    window.location.replace(`https://${process.env.REACT_APP_DOMAIN}`);
  }
};

export const getTokenExpiry = (tokens: AuthTokens | undefined) => {
  const idTokenExpire = tokens?.idToken?.payload.exp;
  const currentTimeSeconds = Math.round(+new Date() / 1000);
  const idToken = tokens?.idToken?.toString();
  const accessToken = tokens?.accessToken?.toString();

  return {
    idToken: idToken,
    accessToken: accessToken,
    hasTokenExpired: !!(idTokenExpire && idTokenExpire < currentTimeSeconds),
  };
};
