import {
  ASSORTMENT_ADMIN,
  ASSORTMENT_READONLY,
  ASSORTMENT_STANDARD,
  BUYING_ADMIN,
  BUYING_READONLY,
  BUYING_STANDARD,
  CORE_ADMIN,
  EVENTS_ADMIN,
  EVENTS_READONLY,
  EVENTS_STANDARD,
  INSIGHTS_READONLY,
  PARAMETERS_ADMIN,
  PARAMETERS_READONLY,
  REBALANCING_ADMIN,
  REBALANCING_READONLY,
  REBALANCING_STANDARD,
  REORDER_ADMIN,
  REORDER_READONLY,
  REORDER_STANDARD,
  REPLENISHMENT_ADMIN,
  REPLENISHMENT_READONLY,
  REPLENISHMENT_STANDARD,
} from '@autone/utils';
import bar from '@iconify/icons-eva/bar-chart-2-fill';
import searchOutline from '@iconify/icons-eva/search-outline';
import shoppingCart from '@iconify/icons-eva/shopping-cart-outline';
import { Icon, type IconifyIcon } from '@iconify/react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Box } from '@mui/material';

import {
  ASSORTMENT_PATH_APP,
  BUYING_2_PATH_APP,
  BUYING_ROOT,
  EVENTS_PATH_APP,
  HUB_PATH_APP,
  INSIGHTS_PATH_APP,
  REBALANCING_PATH_APP,
  REBALANCING_ROOT,
  REORDER_PATH_APP,
  REORDER_ROOT,
  REPLEN_PATH_APP,
  REPLEN_ROOT,
} from '../../../routes';
import { CalendarDatesSvg } from '../../../svgs';
import {
  BuyingIcon,
  DashboardIcon,
  InsightsIcon,
  ParametersIcon,
  RebalancingIcon,
  ReorderIcon,
  ReplenishmentIcon,
  // @ts-ignore
} from '../../icons';

const getIcon = (icon: IconifyIcon['icon']) => (
  <Box
    component={Icon}
    icon={icon}
    sx={{
      mr: 0,
      width: 24,
      height: 24,
    }}
  />
);

export type SidebarItemOptions = {
  title: string;
  href: string;
  path: string;
  icon: IconifyIcon['icon'];
};

export type SidebarItemConfig = {
  title: string;
  tooltip?: string;
  href?: string;
  icon: IconifyIcon['icon'];
  path?: string;
  permissionGroups?: string[];
  isHubLink?: boolean;
  isInsightsLink?: boolean;
  options?: SidebarItemOptions[];
  renderDivider?: boolean;
  ignoreCollapse?: boolean;
  isLogoutButton?: boolean;
};

export type SidebarConfig = {
  title?: string;
  items: SidebarItemConfig[];
};

const hubConfig: SidebarConfig = {
  title: 'Hub',
  items: [
    {
      title: 'overview',
      tooltip: 'hub',
      href: `http://${process.env.REACT_APP_DOMAIN}${HUB_PATH_APP.root}`,
      icon: <DashboardIcon />,
      path: HUB_PATH_APP.root,
      isHubLink: true,
    },
  ],
};

const modulesConfig: SidebarConfig = {
  title: 'Modules',
  items: [
    {
      title: 'replenishment',
      href: `https://${process.env.REACT_APP_DOMAIN}${REPLEN_PATH_APP.overview}`,
      icon: <ReplenishmentIcon />,
      path: REPLEN_ROOT,
      permissionGroups: [
        REPLENISHMENT_READONLY,
        REPLENISHMENT_STANDARD,
        REPLENISHMENT_ADMIN,
      ],
      isHubLink: false,
      isInsightsLink: false,
    },
    {
      title: 'reorder',
      href: `https://${process.env.REACT_APP_DOMAIN}${REORDER_PATH_APP.overview}`,
      icon: <ReorderIcon />,
      path: REORDER_ROOT,
      permissionGroups: [REORDER_READONLY, REORDER_STANDARD, REORDER_ADMIN],
      isHubLink: false,
      isInsightsLink: false,
    },
    {
      title: 'rebalancing',
      href: `https://${process.env.REACT_APP_DOMAIN}${REBALANCING_PATH_APP.overview}`,
      icon: <RebalancingIcon />,
      path: REBALANCING_ROOT,
      permissionGroups: [
        REBALANCING_READONLY,
        REBALANCING_STANDARD,
        REBALANCING_ADMIN,
      ],
      isHubLink: false,
      isInsightsLink: false,
    },
    {
      title: 'buying',
      href: `https://${process.env.REACT_APP_DOMAIN}${BUYING_2_PATH_APP.overview}`,
      icon: <BuyingIcon />,
      path: BUYING_ROOT,
      permissionGroups: [BUYING_READONLY, BUYING_STANDARD, BUYING_ADMIN],
      isHubLink: false,
      isInsightsLink: false,
    },
    {
      title: 'insights',
      href: `https://${process.env.REACT_APP_DOMAIN}${INSIGHTS_PATH_APP.root}`,
      icon: <InsightsIcon />,
      path: 'insights',
      permissionGroups: [INSIGHTS_READONLY],
      isHubLink: false,
      isInsightsLink: true,
      options: [
        {
          title: 'Retail',
          href: `https://${process.env.REACT_APP_DOMAIN}${INSIGHTS_PATH_APP.root}`,
          path: INSIGHTS_PATH_APP.root,
          icon: getIcon(shoppingCart),
        },
        {
          title: 'Bestsellers',
          href: `https://${process.env.REACT_APP_DOMAIN}${INSIGHTS_PATH_APP.bestsellers}`,
          path: INSIGHTS_PATH_APP.bestsellers,
          icon: getIcon(bar),
        },
        {
          title: 'Explorer',
          href: `https://${process.env.REACT_APP_DOMAIN}${INSIGHTS_PATH_APP.explorer}`,
          path: INSIGHTS_PATH_APP.explorer,
          icon: getIcon(searchOutline),
        },
      ],
    },
  ],
};

const accountConfig: SidebarConfig = {
  title: 'Account',
  items: [
    {
      title: 'Assortment',
      href: `https://${process.env.REACT_APP_DOMAIN}${ASSORTMENT_PATH_APP.fineTuning}`,
      icon: <AppRegistrationIcon />,
      permissionGroups: [
        ASSORTMENT_READONLY,
        ASSORTMENT_ADMIN,
        ASSORTMENT_STANDARD,
      ],
      path: ASSORTMENT_PATH_APP.fineTuning,
    },
    {
      title: 'Events',
      href: `https://${process.env.REACT_APP_DOMAIN}${EVENTS_PATH_APP.home}`,
      icon: <CalendarDatesSvg />,
      permissionGroups: [EVENTS_READONLY, EVENTS_ADMIN, EVENTS_STANDARD],
      path: EVENTS_PATH_APP.home,
    },
    {
      title: 'parameters',
      href: `http://${process.env.REACT_APP_DOMAIN}${HUB_PATH_APP.parameters}`,
      icon: <ParametersIcon />,
      path: HUB_PATH_APP.parameters,
      isHubLink: true,
      permissionGroups: [PARAMETERS_READONLY, PARAMETERS_ADMIN],
    },
    {
      title: 'team',
      href: `https://${process.env.REACT_APP_DOMAIN}${HUB_PATH_APP.userManagement}`,
      icon: <PeopleAltOutlinedIcon />,
      path: HUB_PATH_APP.userManagement,
      isHubLink: true,
      permissionGroups: [CORE_ADMIN],
    },
  ],
};

const settingsConfig: SidebarConfig = {
  items: [
    {
      title: 'profile',
      href: `http://${process.env.REACT_APP_DOMAIN}${HUB_PATH_APP.settings}?tab=profile`,
      icon: <AccountCircleOutlinedIcon />,
      path: `${HUB_PATH_APP.settings}?tab=profile`,
      isHubLink: true,
      ignoreCollapse: true,
    },
    {
      title: 'change password',
      href: `http://${process.env.REACT_APP_DOMAIN}${HUB_PATH_APP.settings}?tab=change_password`,
      icon: <LockOutlinedIcon />,
      path: `${HUB_PATH_APP.settings}?tab=change_password`,
      isHubLink: true,
      renderDivider: true,
      ignoreCollapse: true,
    },
    {
      title: 'logout',
      icon: <LogoutOutlinedIcon />,
      isHubLink: false,
      ignoreCollapse: true,
      isLogoutButton: true,
    },
  ],
};

export { modulesConfig, accountConfig, hubConfig, settingsConfig };
