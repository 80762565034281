import { capitalizeFirstLetter } from '@autone/utils';
import { type IconifyIcon } from '@iconify/react';
import {
  type CustomTheme,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { signOut as amplifySignOut } from 'aws-amplify/auth';
import React, { type ForwardedRef, forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UPDATE_USER } from '../../../redux/user';
import { ListItemStyles } from '../styles';

const SidebarItem = forwardRef(
  (
    {
      title,
      tooltip,
      icon,
      isActive,
      isDisabled,
      isCollapse,
      onMouseEnter = () => {},
      onMouseLeave = () => {},
      isInsightsLink,
      sidebarItemStyles,
      renderDivider,
      isLogoutButton,
      handlePopoverClose,
    }: {
      title: string;
      tooltip?: string;
      icon: IconifyIcon['icon'];
      isActive: boolean;
      isDisabled: boolean;
      isCollapse: boolean;
      onMouseEnter?: (event: React.MouseEvent) => void;
      onMouseLeave?: (event: React.MouseEvent) => void;
      isInsightsLink: boolean;
      sidebarItemStyles: any;
      renderDivider: boolean;
      isLogoutButton?: boolean;
      handlePopoverClose?: () => void;
    },
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const signOut = async () => {
      await amplifySignOut();
      dispatch(
        UPDATE_USER({
          attributes: undefined,
          idToken: undefined,
          accessToken: undefined,
        }),
      );
      // if successful push to home
      history.push('/');
    };

    const [isHovered, setIsHovered] = useState(false);
    const theme: CustomTheme = useTheme();
    const iconColor =
      isActive || isHovered
        ? theme.palette.primary.main
        : theme.palette.text.primary;

    return (
      <>
        <Tooltip
          title={
            !isInsightsLink &&
            isCollapse &&
            capitalizeFirstLetter(tooltip || title)
          }
          placement={'right'}
          arrow
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 2],
                },
              },
            ],
          }}
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: 'body1.fontSize',
                padding: 1,
                borderRadius: '4px',
              },
            },
          }}
        >
          <ListItemButton
            component={'span'}
            onClick={() => {
              if (isLogoutButton) {
                signOut();
              }
              handlePopoverClose?.();
            }}
            ref={ref}
            disabled={isDisabled}
            disableGutters
            className="isActiveRoot"
            selected={isActive}
            onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {
              onMouseEnter(e);
              setIsHovered(true);
            }}
            onMouseLeave={(e: React.MouseEvent<HTMLSpanElement>) => {
              onMouseLeave(e);
              setIsHovered(false);
            }}
            sx={{
              ...ListItemStyles(theme, isCollapse),
              ...(isCollapse && {
                display: 'flex',
                flexDirection: 'column',
              }),
              ...(isActive && {
                color: 'primary.main',
                backgroundColor: 'action.selected',
              }),
              ...(isLogoutButton && {
                color: 'error.dark',
              }),
              ...sidebarItemStyles,
            }}
          >
            <ListItemIcon
              sx={{
                '&:hover': { color: 'primary.main' },
                ...(isCollapse && { mr: 0, my: '11px', p: 0 }),
              }}
            >
              {/* renders svg as a component and passes props into it */}
              {React.cloneElement(
                icon as React.ReactElement<{
                  color?: string;
                  size?: any;
                }>,
                {
                  color: iconColor,
                  //@ts-ignore not recognising icons on CustomTheme
                  size: theme.icons.lg,
                },
              )}
            </ListItemIcon>
            {!isCollapse && (
              <ListItemText
                sx={{ textTransform: 'none' }}
                disableTypography
                primary={capitalizeFirstLetter(title)}
              />
            )}
          </ListItemButton>
        </Tooltip>
        {renderDivider && <Divider sx={{ my: 1 }} />}
      </>
    );
  },
);

export default SidebarItem;
