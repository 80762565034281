import { type CustomTheme } from '@mui/material/styles';
export const defaultOptions = (theme: CustomTheme) => ({
  chart: {
    style: {
      fontFamily: theme.typography.fontFamily,
    },
    resetZoomButton: {
      theme: {
        style: {
          marginRight: '100px',
          fontFamily: theme.typography.fontFamily,
          margin: '20px',
          padding: '200px',
          border: 'none',
          color: theme.palette.text.primary,
          fontWeight: 500,
        },
        fill: theme.palette.grey[200],
        stroke: 'none',
        r: theme.shape.borderRadius,
      },
    },
  },
  xAxis: {
    labels: {
      useHTML: true,
      style: {
        fontFamily: theme.typography.fontFamily,
      },
    },
  },
  yAxis: {
    labels: {
      useHTML: true,
      style: {
        fontFamily: theme.typography.fontFamily,
      },
    },
  },
  // remove watermark
  credits: {
    enabled: false,
  },
  title: false,
  tooltip: {
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    distance: 5,
  },
});
