import { useAutoneTranslation } from '@autone/translations';
import {
  forwardRef,
  type HTMLAttributes,
  type MouseEvent,
  type PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from 'react';

import { type ButtonProps, cn, TailwindButton } from '../../../tailwind';

import {
  AdvancedFiltersConditionCombineMode,
  AdvancedFiltersConditionRow,
} from './AdvancedFilters.components';
import {
  AdvancedFiltersContext,
  AdvancedFiltersOptionsContext,
  useAdvancedFiltersContext,
} from './AdvancedFilters.context';
import { advancedFiltersReducer } from './AdvancedFilters.reducer';
import {
  advancedFilterConditionIsComplete,
  initialiseAdvancedFiltersReducer,
} from './AdvancedFilters.utilities';
import {
  type AdvancedFiltersCondition,
  type AdvancedFiltersOptionsContextValue,
  type AdvancedFiltersState,
  type AdvancedFiltersViewRef,
} from './types';

interface AdvancedFiltersViewProps extends PropsWithChildren {
  advancedFiltersOptions: AdvancedFiltersOptionsContextValue;
  defaultAdvancedFilters?: AdvancedFiltersState;
  onAdvancedFiltersChange?: (newAdvancedFilters: AdvancedFiltersState) => void;
}

export const AdvancedFiltersView = forwardRef<
  AdvancedFiltersViewRef,
  AdvancedFiltersViewProps
>(
  (
    {
      children,
      advancedFiltersOptions,
      defaultAdvancedFilters,
      onAdvancedFiltersChange,
    },
    ref,
  ) => {
    const prevCompletedFiltersString = useRef<string | null>(null);
    const [state, dispatch] = useReducer(
      advancedFiltersReducer,
      defaultAdvancedFilters,
      initialiseAdvancedFiltersReducer,
    );

    // expose imperative methods to parent component
    useImperativeHandle(ref, () => ({
      flushNewAdvancedFilters: (newAdvancedFilters) => {
        dispatch({
          type: 'FLUSH_NEW_ADVANCED_FILTERS',
          payload: newAdvancedFilters,
        });
      },
      removeIncompleteRows: () => {
        dispatch({ type: 'REMOVE_INCOMPLETE_ROWS' });
      },
    }));

    useEffect(() => {
      // only call `onAdvancedFiltersChange` with completed conditions
      const completedFilters = Object.values(state.conditions).filter(
        (condition) => advancedFilterConditionIsComplete(condition),
      ) as AdvancedFiltersCondition[];
      const completedFiltersString = JSON.stringify(completedFilters);

      if (
        prevCompletedFiltersString.current &&
        prevCompletedFiltersString.current !== completedFiltersString
      ) {
        onAdvancedFiltersChange?.({
          conditionCombineMode: state.conditionCombineMode,
          conditions: completedFilters,
        });
      }

      prevCompletedFiltersString.current = completedFiltersString;
    }, [state]);

    return (
      <AdvancedFiltersContext.Provider value={[state, dispatch]}>
        <AdvancedFiltersOptionsContext.Provider value={advancedFiltersOptions}>
          {children}
        </AdvancedFiltersOptionsContext.Provider>
      </AdvancedFiltersContext.Provider>
    );
  },
);

type AdvancedFiltersContentProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'children'
>;

export const AdvancedFiltersContent = forwardRef<
  HTMLDivElement,
  AdvancedFiltersContentProps
>(({ className, ...props }, ref) => {
  const { common } = useAutoneTranslation();
  const [state, dispatch] = useAdvancedFiltersContext();
  const { conditions } = state;

  return (
    <div
      className={cn(
        'flex flex-col grow p-4 overflow-y-scroll no-scrollbar',
        className,
      )}
      {...props}
      ref={ref}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          {Object.keys(conditions).map((rowId, idx) => (
            <div
              key={rowId}
              className="flex flex-row gap-2 items-center w-full"
            >
              <div className="basis-1/6 italic">
                <AdvancedFiltersConditionCombineMode rowIdx={idx} />
              </div>
              <AdvancedFiltersConditionRow rowId={rowId} />
            </div>
          ))}
        </div>
        <TailwindButton
          size={'md'}
          variant={'ghost'}
          className="w-fit"
          onClick={() => dispatch({ type: 'ADD_CONDITION' })}
        >
          {common('advanced-filters.add-row')}
        </TailwindButton>
      </div>
    </div>
  );
});

type AdvancedFiltersClearAllButtonProps = ButtonProps;

export const AdvancedFiltersClearAllButton = forwardRef<
  HTMLButtonElement,
  AdvancedFiltersClearAllButtonProps
>(({ onClick, ...props }, ref) => {
  const [_, dispatch] = useAdvancedFiltersContext();

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    dispatch({ type: 'RESET' });
    onClick?.(e);
  };

  return (
    <TailwindButton
      onClick={handleOnClick}
      variant={'outlined'}
      color={'secondary'}
      {...props}
      ref={ref}
    />
  );
});
