import { useAutoneTranslation } from '@autone/translations';
import { TabsContent, TabsTrigger } from '@radix-ui/react-tabs';
import { Search } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';

import {
  Checkbox,
  TailwindButton,
  TailwindInputWithIcon,
} from '../../../tailwind';

import { useGeneralFiltersContext } from './GeneralFilters.context';
import { type GeneralFilterGroup, type GeneralFilterOption } from './types';

interface GeneralFiltersGroupTriggerProps {
  filterGroup: GeneralFilterGroup;
}

export const GeneralFiltersGroupTrigger = ({
  filterGroup,
}: GeneralFiltersGroupTriggerProps) => {
  const { toggledOptions } = useGeneralFiltersContext();
  const { id, translatedDisplayName } = filterGroup;

  const activeOptionsCount = useMemo(
    () =>
      Object.values(toggledOptions[id] || {}).filter((value) => value === true)
        .length,
    [toggledOptions, id],
  );

  return (
    <TabsTrigger
      value={filterGroup.id}
      className="w-full flex justify-between font-medium px-3 py-2 rounded-md data-[state=active]:bg-primary-5 data-[state=active]:text-primary-100 hover:bg-grey-5"
    >
      <span>{translatedDisplayName}</span>
      {activeOptionsCount > 0 && (
        <div
          data-testid={`${filterGroup.id}-active-count`}
          className="grid place-items-center bg-primary-100 text-white aspect-square rounded-sm -me-1 h-full"
        >
          {activeOptionsCount}
        </div>
      )}
    </TabsTrigger>
  );
};

interface GeneralFiltersGroupOptionsProps {
  filterGroup: GeneralFilterGroup;
}

export const GeneralFiltersGroupOptions = ({
  filterGroup,
}: GeneralFiltersGroupOptionsProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { common } = useAutoneTranslation();
  const { toggleFilterGroup, toggledOptions } = useGeneralFiltersContext();
  const { id: filterGroupId, options } = filterGroup;

  const visibleOptions = useMemo(
    () =>
      options.filter((option) =>
        option.translatedDisplayName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      ),
    [options, searchTerm],
  );

  const allOptionsSelected = useMemo(() => {
    const numAvailableOptions = options.length;
    const numSelectedOptions = Object.values(
      toggledOptions?.[filterGroupId] || {},
    ).filter((val) => val === true).length;
    return numAvailableOptions === numSelectedOptions;
  }, [options, filterGroupId, toggledOptions]);

  return (
    <TabsContent
      value={filterGroup.id}
      className="flex flex-col h-full min-h-0 px-5 py-3 gap-4 data-[state=inactive]:hidden"
    >
      <div className="flex flex-row items-center gap-2">
        <TailwindInputWithIcon
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={common('search-placeholder')}
          className="h-8 w-2/3"
          icon={<Search size={16} className="text-grey-80" />}
        />
        <TailwindButton
          className="ms-auto"
          variant={'ghost'}
          onClick={() => toggleFilterGroup(filterGroupId, !allOptionsSelected)}
        >
          {allOptionsSelected ? common('clear-all') : common('select-all')}
        </TailwindButton>
      </div>
      <div className="grow min-h-0 -mb-3 overflow-y-scroll no-scrollbar">
        <ul className="flex flex-col h-fit">
          {visibleOptions.map((option) => (
            <GeneralFiltersOption
              key={option.id}
              groupId={filterGroupId}
              option={option}
            />
          ))}
        </ul>
      </div>
    </TabsContent>
  );
};

interface GeneralFiltersOptionProps {
  groupId: string;
  option: GeneralFilterOption;
}

export const GeneralFiltersOption = ({
  groupId,
  option,
}: GeneralFiltersOptionProps) => {
  const { toggleOption, toggledOptions } = useGeneralFiltersContext();
  const optionIsSelected = toggledOptions?.[groupId]?.[option.id] || false;

  const toggleFilterOption = useCallback(
    () => toggleOption(groupId, option.id, !optionIsSelected),
    [toggleOption, groupId, option.id, optionIsSelected],
  );

  return (
    <li
      key={option.id}
      className="flex flex-row justify-between items-center px-2 py-3 rounded-md hover:bg-grey-5 hover:cursor-pointer"
      onClick={toggleFilterOption}
    >
      <span className="font-medium">{option.translatedDisplayName}</span>
      <div className="grid place-items-center h-full pe-1">
        <Checkbox checked={optionIsSelected} />
      </div>
    </li>
  );
};
