import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function AuthGuard({ children }) {
  const { idToken: token } = useSelector((state) => state.user) || {};
  const redirect = window.location.href;

  // if there is no token then redirect back to home
  if (!token) {
    window.location.replace(
      `https://${process.env.REACT_APP_AUTH_DOMAIN}/login?redirect=${redirect}`,
    );
    return <></>;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
