import { useFlags } from 'launchdarkly-react-client-sdk';

const useLanguagePreferenceFeatureFlag = ({
  moduleName,
}: {
  moduleName: string;
}) => {
  const { insightsLanguagePreference, globalLanguagePreference } = useFlags();

  if (moduleName === 'insights') {
    return {
      isLanguageFeatureFlagOn:
        insightsLanguagePreference && globalLanguagePreference,
    };
  }

  return {
    isLanguageFeatureFlagOn: globalLanguagePreference,
  };
};

export default useLanguagePreferenceFeatureFlag;
