import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import FilterChip from './FilterChip';

const FilterTooltip = ({ filters, handleRemoval, dimensionId }) => (
  <Box>
    {filters &&
      filters.map(({ title, id }, idx) => (
        <FilterChip
          id={id}
          title={title}
          dimensionId={dimensionId}
          handleRemoval={handleRemoval}
          key={idx}
        />
      ))}
  </Box>
);

export default FilterTooltip;

FilterTooltip.propTypes = {
  filters: PropTypes.array,
  handleRemoval: PropTypes.func,
  dimensionId: PropTypes.string,
};
