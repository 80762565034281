import { Search } from 'lucide-react';
import * as React from 'react';

import { Input } from './inputs';
import { cn } from './utils';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  iconSize?: number;
}

const SearchInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, iconSize = 24, value, onChange, ...props }, ref) => {
    return (
      <div className="relative">
        <div className="absolute left-3.5 top-1/2 transform -translate-y-1/2 text-grey-40">
          <Search size={iconSize} />
        </div>
        <Input
          className={cn('pl-12', className)}
          type={type}
          ref={ref}
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
    );
  },
);
SearchInput.displayName = 'SearchInput';

export { SearchInput };
