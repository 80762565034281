import { Tabs } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

const TabsGreyStyled = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  paddingLeft: '15px',
  paddingRight: '15px',
  paddingTop: '0px',
  paddingBottom: '0px',
  borderTopLeftRadius: `${theme.shape.borderRadius}px`,
  borderTopRightRadius: `${theme.shape.borderRadius}px`,
  minHeight: '0px',
}));

export default TabsGreyStyled;
