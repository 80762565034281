import { generatePath } from './generate-paths';

export const BUYING_ROOT = '/buying';

type BuyingPathKey =
  | 'welcome'
  | 'overview'
  | 'newBuy'
  | 'scope'
  | 'products'
  | 'assortment'
  | 'otb'
  | 'review'
  | 'ideal-buy'
  | 'summary'
  | 'error'
  | 'submitted'
  | 'buyConfirmed'
  | 'quantification-loading'
  | 'product-catalogue-loading';

export const BUYING_2_PATH_APP: {
  [key in BuyingPathKey]: string;
} = {
  welcome: generatePath(BUYING_ROOT, '/welcome'),
  overview: generatePath(BUYING_ROOT, '/overview'),
  newBuy: generatePath(BUYING_ROOT, '/new-buy'),
  scope: generatePath(BUYING_ROOT, '/batch/:batchId/scope'),
  products: generatePath(BUYING_ROOT, '/batch/:batchId/products'),
  assortment: generatePath(BUYING_ROOT, '/batch/:batchId/assortment'),
  otb: generatePath(BUYING_ROOT, '/batch/:batchId/budget'),
  review: generatePath(BUYING_ROOT, '/batch/:batchId/review'),
  'ideal-buy': generatePath(BUYING_ROOT, '/batch/:batchId/ideal-buy'),
  summary: generatePath(BUYING_ROOT, '/batch/:batchId/summary'),
  error: generatePath(
    BUYING_ROOT,
    '/error/batch/:batchId/reasonCode/:reasonCode',
  ),
  submitted: generatePath(BUYING_ROOT, '/batch/:batchId/submitted'),
  buyConfirmed: generatePath(BUYING_ROOT, '/buy-confirmed'),
  'quantification-loading': generatePath(
    BUYING_ROOT,
    '/batch/:batchId/quantification/:jobId',
  ),
  'product-catalogue-loading': generatePath(
    BUYING_ROOT,
    '/batch/:batchId/product-potential/:jobId',
  ),
};
