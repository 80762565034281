import { ArrowDropDownOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

export default function SplitButton({
  children,
  options,
  handleClick,
  variant,
  sx,
  optionHeader,
  disabled,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();
  const [selectedIndex] = useState(1);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleClickMaster = async () => {
    setLoading(true);
    if (handleClick) {
      await handleClick();
    }
    setLoading(false);
  };

  return (
    <>
      <ButtonGroup
        variant={variant || 'contained'}
        ref={anchorRef}
        aria-label="split button"
        fullWidth
        sx={{ ...sx }}
      >
        <LoadingButton
          size="medium"
          disabled={disabled}
          onClick={() => handleClickMaster()}
          loading={loading}
          variant={variant || 'contained'}
        >
          {children}
        </LoadingButton>
        <LoadingButton
          size="medium"
          sx={{ maxWidth: '60px' }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          loading={loading}
          variant={variant || 'contained'}
        >
          {/* <Typography fontSize="large"> */}
          <ArrowDropDownOutlined />
          {/* </Typography> */}
        </LoadingButton>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="top-start"
        disablePortal
        style={{
          width: '100%',
          padding: '20px',
          boxShadow: theme.shadows.dark,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'top-start',
              boxShadow: theme.customShadows.z20,
            }}
          >
            <Paper>
              {optionHeader}
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{ maxHeight: '250px', overflowY: 'scroll' }}
                  id="split-button-menu"
                  autoFocusItem
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      disabled={disabled}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

SplitButton.propTypes = {
  children: PropTypes.node,
  options: PropTypes.array,
  handleClick: PropTypes.func,
  variant: PropTypes.string,
  sx: PropTypes.object,
  optionHeader: PropTypes.object,
  disabled: PropTypes.bool,
};
