import { Box, Divider, Grid, Skeleton, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import SimpleKPI from './SimpleKPI';

const SimpleKPIGroup = ({
  kpiData,
  headingSize = 'h2',
  cardSx,
  showDivider = false,
}) => {
  const theme = useTheme();
  return (
    <>
      <Box>
        {showDivider && <Divider />}
        <Grid container spacing={0} justifyContent="space-evenly">
          {kpiData ? (
            kpiData.map(({ title, value }, index) => (
              <Grid key={index} item>
                <SimpleKPI
                  idx={index}
                  title={title}
                  value={value == null ? 0 : value}
                  headingSize={headingSize}
                  cardSx={cardSx}
                />
              </Grid>
            ))
          ) : (
            <Skeleton
              sx={{ m: 2, borderRadius: `${theme.shape.borderRadius}px` }}
              variant="rectangular"
              height={100}
              width="100%"
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

SimpleKPIGroup.propTypes = {
  kpiData: PropTypes.array,
  headingSize: PropTypes.string,
  cardSx: PropTypes.object,
  showDivider: PropTypes.bool,
};

export default SimpleKPIGroup;
