import { Skeleton, Stack, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ProductSkeleton = ({ sx }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" sx={{ ...sx }}>
      <Skeleton
        sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
        width={64}
        height={40}
        variant="rectangular"
      />
      <Stack sx={{ ml: 1, justifyContent: 'center' }}>
        <Skeleton width={60} height={15} />
        <Skeleton width={151} height={20} />
      </Stack>
    </Stack>
  );
};

export default ProductSkeleton;

ProductSkeleton.propTypes = { sx: PropTypes.object };
