import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import {
  FullScreen,
  type FullScreenHandle,
  useFullScreenHandle,
} from 'react-full-screen';

const FullscreenContext = createContext<FullScreenHandle | null>(null);

export const FullscreenContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const handle = useFullScreenHandle();
  return (
    <FullscreenContext.Provider value={handle}>
      <FullScreen handle={handle} className="w-full">
        {children}
      </FullScreen>
    </FullscreenContext.Provider>
  );
};

export const useFullscreenContext = () => {
  const handle = useContext(FullscreenContext);
  if (!handle) {
    throw new Error(
      'useFullScreen must be used within a FullScreenContextProvider',
    );
  }
  const toggleFullscreen = useCallback(() => {
    if (handle.active) {
      handle.exit();
    } else {
      handle.enter();
    }
  }, [handle.active, handle.enter, handle.exit]);

  return {
    isFullscreen: handle.active,
    toggleFullscreen,
    fullscreenContainer: handle.node?.current,
    /** @deprecated Use fullscreenContainer instead */
    legacyFullscreenContainer: handle.node,
  };
};
