import { partitionArray } from '../data';

import { hideColumnCheck } from './hideColumnCheck';

export const handleTableColumnReorder = (draggedColumn, columnConfig) => {
  // if no destination, return
  if (!draggedColumn.destination) {
    return columnConfig;
  }

  // we partition the array into shown and hidden items
  const [hiddenItems, shownItems] = partitionArray(
    columnConfig,
    hideColumnCheck,
  );

  // re-order dragged items
  const [reorderedItem] = shownItems.splice(draggedColumn.source.index, 1);

  // insert dragged item into new position
  shownItems.splice(draggedColumn.destination.index, 0, reorderedItem);

  // concatenate the hidden items
  // hidden items are always at the end of the array
  const newArray = shownItems.concat(hiddenItems);

  return newArray;
};
