import {
  Box,
  CardActionArea,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const DropdownMenuContents = ({ options, onOptionSelection }) => {
  const theme = useTheme();

  const getNumberOfGridColumns = () => {
    // if we have no options or only one option, we want to display one column
    if (options.length === 1) {
      return { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' };
    }

    // if we have two options, we want to display two columns
    if (options.length === 2) {
      return { xs: 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)' };
    }

    return { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)' };
  };

  // styles for the card action area component
  const cardActionAreaStyles = {
    p: 1,
    borderRadius: 1,
    cursor: 'pointer',
    color: 'text.secondary',
    border: (theme) => `solid 1px ${theme.palette.grey[100]}`,
  };

  // shared props for box component
  const boxProps = {
    display: 'grid',
    gap: 1,
  };

  // if options is undefined, we want to display a skeleton
  if (!options) {
    return (
      <Stack direction="row" gap={1}>
        <Skeleton width={100} height={50} />
        <Skeleton width={100} height={50} />
      </Stack>
    );
  }

  // if options is an empty array, we want to display a no results message
  if (options.length === 0) {
    return (
      <Box {...boxProps}>
        <Typography
          sx={{
            color: theme.palette.grey[500],
          }}
        >
          No results
        </Typography>
      </Box>
    );
  }

  // otherwise render the results
  return (
    <Box {...boxProps} gridTemplateColumns={getNumberOfGridColumns()}>
      {options.map((option, index) => {
        const { title, active, sx, icon, backgroundIconColor, iconSx } = option;

        return (
          <CardActionArea
            key={index}
            onClick={() => onOptionSelection(option)}
            sx={{
              ...cardActionAreaStyles,
              ...sx,
              ...(active && {
                color: 'text.primary',
                bgcolor: 'action.selected',
              }),
            }}
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <Box
                sx={{
                  float: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  padding: theme.spacing(0.8),
                  borderRadius: `${theme.shape.borderRadius}px`,
                  backgroundColor: backgroundIconColor,
                  ...iconSx,
                }}
              >
                {icon &&
                  React.cloneElement(icon, {
                    size: theme.icons.sm,
                  })}
              </Box>
              <Typography variant="body1">{title}</Typography>
            </Stack>
          </CardActionArea>
        );
      })}
    </Box>
  );
};

export default DropdownMenuContents;

DropdownMenuContents.propTypes = {
  options: PropTypes.array,
  onOptionSelection: PropTypes.func,
};
