import CancelIcon from '@mui/icons-material/Cancel';
// ----------------------------------------------------------------------

export default function Chip(theme) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <CancelIcon />,
      },
      styleOverrides: {
        colorDefault: {
          color: theme.palette.info.dark,
          backgroundColor: theme.palette.info.lighter,
          '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
            color: theme.palette.text.secondary,
          },
          fontSize: theme.typography.body1.fontSize,
          '& .MuiChip-label': {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 500,
          },
          borderRadius: `${theme.shape.borderRadius}px`,
        },
        outlined: {
          backgroundColor: 'inherit',
          borderColor: theme.palette.grey[500_32],
          '&.MuiChip-colorPrimary': {
            borderColor: theme.palette.primary.main,
          },
          '& .MuiChip-label': {
            fontSize: theme.typography.body1.fontSize,
          },
        },
        deleteIcon: {
          color: theme.palette.info.dark,
          '&:hover': {
            color: theme.palette.info.dark,
          },
        },
      },
    },
  };
}
