import { type IconifyIcon } from '@iconify/react';
import { useRef, useState } from 'react';

import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { PaperStyle } from '../styles';

import { type SidebarItemOptions } from './SidebarConfig';
import SidebarItem from './SidebarItem';
import SidebarLink from './SidebarLink';
import SidebarSubItem from './SidebarSubItem';

const SidebarItemRoot = ({
  href,
  icon,
  title,
  tooltip,
  isHubLink,
  isInsightsLink,
  options,
  isActive,
  isDisabled,
  path,
  currentPathname,
  sidebarItemStyles,
  renderDivider,
  ignoreCollapse,
  isLogoutButton,
  handlePopoverClose,
}: {
  href?: string;
  icon: IconifyIcon['icon'];
  title: string;
  tooltip?: string;
  isHubLink: boolean;
  isInsightsLink: boolean;
  options?: SidebarItemOptions[];
  isActive: boolean;
  isDisabled: boolean;
  path?: string;
  currentPathname: string;
  sidebarItemStyles: any;
  renderDivider: boolean;
  ignoreCollapse?: boolean;
  isLogoutButton?: boolean;
  handlePopoverClose: () => void;
}) => {
  const { isCollapse } = useCollapseDrawer();

  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = !!options;

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const sidebarProps = {
    title,
    tooltip,
    icon,
    isActive,
    isDisabled,
    isCollapse: ignoreCollapse === undefined ? isCollapse : !ignoreCollapse,
    isInsightsLink,
    renderDivider,
    isLogoutButton,
  };

  const sidebarSubItemProps = {
    options,
    isCollapse,
    currentPathname,
    isDisabled,
    isInsightsLink,
  };

  if (!path || !href) {
    return (
      <SidebarItem
        ref={menuRef}
        sidebarItemStyles={sidebarItemStyles}
        {...sidebarProps}
      />
    );
  }

  const sidebarLinkProps = {
    to: href,
    isHubLink,
    isDisabled,
    isInsightsLink,
    path,
  };

  // if the sidebar item has children, we need to render a sub menu
  if (hasChildren) {
    return (
      <>
        <SidebarLink {...sidebarLinkProps}>
          <SidebarItem
            ref={menuRef}
            sidebarItemStyles={sidebarItemStyles}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            {...sidebarProps}
          />
        </SidebarLink>
        <PaperStyle
          open={isOpen}
          anchorEl={menuRef.current}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: -9,
          }}
          PaperProps={{
            onMouseEnter: handleOpen,
            onMouseLeave: handleClose,
          }}
        >
          <SidebarSubItem {...sidebarSubItemProps} />
        </PaperStyle>
      </>
    );
  }

  return (
    <SidebarLink {...sidebarLinkProps}>
      <SidebarItem
        ref={menuRef}
        {...sidebarProps}
        sidebarItemStyles={sidebarItemStyles}
        handlePopoverClose={handlePopoverClose}
      />
    </SidebarLink>
  );
};

export default SidebarItemRoot;
