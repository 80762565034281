import * as ProgressPrimitive from '@radix-ui/react-progress';
import * as React from 'react';

import { cn } from '../utils';

type ProgressSegment = {
  value: number;
  color?: string;
};

type Props = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
  segments: ProgressSegment[];
};

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  Props
>(({ className, segments, ...props }, ref) => {
  return (
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        'relative h-2 w-full overflow-hidden rounded bg-grey-10',
        className,
      )}
      {...props}
    >
      {segments.map((segment, index) => (
        <ProgressPrimitive.Indicator
          key={index}
          className={cn(
            'h-full transition-all absolute',
            segment.color ? segment.color : 'bg-primary',
          )}
          style={{
            width: `${segment.value}%`,
            zIndex: segments.length - index,
            left: `${segments
              .slice(0, index)
              .reduce((acc, segment) => acc + segment.value, 0)}%`,
          }}
        />
      ))}
    </ProgressPrimitive.Root>
  );
});

Progress.displayName = 'Progress';

export { Progress };
