import { CORE_AXIOS_PATH } from '@autone/utils';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

type Context = {
  kind: 'multi';
  device: {
    key: string;
  };
  user?: {
    key: string;
  };
  organization?: {
    key: string;
  };
};

const PERM_USER_ID_COOKIE = 'perm-user-id';

const getOrCreatePermUserId = () => {
  if (!window.isSecureContext) {
    return null;
  }

  let permUserId = Cookies.get(PERM_USER_ID_COOKIE);

  if (!permUserId) {
    permUserId = crypto.randomUUID();
    Cookies.set(PERM_USER_ID_COOKIE, permUserId, {
      expires: 365,
      sameSite: 'Strict',
    });
  }

  return permUserId;
};

export const getBaseContext = (): Context => {
  const permUserId = getOrCreatePermUserId();
  return {
    kind: 'multi',
    device: {
      key: permUserId || '',
    },
  };
};

export const createLDMultiContext = (token?: string) => {
  const context = getBaseContext();

  if (!token) {
    return context;
  }

  const decodedToken = jwtDecode(token) as
    | {
        user_id: string;
        'custom:customer_id': string;
      }
    | undefined;

  if (!decodedToken) {
    return context;
  }
  context.user = { key: decodedToken.user_id };
  context.organization = { key: decodedToken['custom:customer_id'] };

  return context;
};

export const getLDHash = async (
  context: Context,
): Promise<string | undefined> => {
  const axiosInstance = axios.create({
    baseURL: `https://${process.env.REACT_APP_BACKEND_ENDPOINT_HOST}/${CORE_AXIOS_PATH}/feature-flagging`,
  });
  try {
    const response = await axiosInstance.post('/hash', { context });
    if (response.status === 200) {
      return response.data.hash;
    }
    return undefined;
  } catch (_) {
    return undefined;
  }
};
