import { useAutoneTranslation } from '@autone/translations';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import {
  type ForwardedRef,
  forwardRef,
  type ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';

import CreateChipButton from '../multi-select/CreateChipButton';
import SelectAll from '../multi-select/SelectAll';
import SelectedChips from '../selected-chips/SelectedChips';

import { type Option } from './MultiSelect.types';
import MultiSelectFormOption from './MultiSelectFormOption';

type Props<P extends Option> = {
  onChange: (options: Option[]) => void;
  value?: P[];
  options: P[];
  onInputChange?: (val: string) => void;
  loading?: boolean;
  fetching?: boolean;
  disabled?: boolean;
  text: {
    label: string;
    noOptionsText: string;
    placeholder: string;
    overLimitText: string;
    createButtonText?: string;
  };
  showId?: boolean;
  itemSlot?: ({ option }: { option: P }) => ReactElement;
  isCreatable?: boolean;
  handleSelectAll: (options: Option[]) => void;
  handleClearAll: (options: Option[]) => void;
  'data-testid'?: string;
};

const renderCreateButton = <P extends Option>(
  isCreatable: boolean | undefined,
  inputValue: string,
  options: P[],
) =>
  isCreatable &&
  inputValue.length > 0 &&
  !options.some(
    (option) => option.description.toLowerCase() === inputValue.toLowerCase(),
  );

const SELECT_ALL = 'select-all';
const SELECT_ALL_OPTION = { id: SELECT_ALL, description: '' };
const CREATE_TAG = 'create-tag';
const CREATE_TAG_OPTION = { id: CREATE_TAG, description: '' };

const getIdAndDescription = (option: Option[]) =>
  option.map(({ id, description }) => ({
    id,
    description,
  }));

const MultiSelect = <P extends Option>(
  {
    value,
    onChange,
    onInputChange,
    loading,
    options,
    fetching,
    disabled,
    text: {
      label,
      noOptionsText,
      placeholder,
      overLimitText,
      createButtonText = 'Create',
    },
    handleSelectAll,
    handleClearAll,
    showId,
    itemSlot,
    isCreatable,
    'data-testid': dataTestId,
  }: Props<P>,
  ref?: ForwardedRef<unknown>,
) => {
  const [inputValue, setInputValue] = useState('');
  const [createdChips, setCreatedChips] = useState<Option[]>([]);
  const { common } = useAutoneTranslation();
  const showSelectAllOption = options.length > 1;

  // Calculate the filtered options based on inputValue
  // if onInputChange is passed, filtering is handled externally to this component
  const filteredOptions = useMemo(() => {
    const optionsMatchingInputValue = onInputChange
      ? options
      : options.filter((option) =>
          option.description.toLowerCase().includes(inputValue.toLowerCase()),
        );
    const enabledOptions = optionsMatchingInputValue.filter(
      (option) => option.disabled !== true,
    );
    return enabledOptions;
  }, [onInputChange, options, inputValue]);

  const optionsWithButtons = useMemo(() => {
    let result = options;

    if (showSelectAllOption) {
      result = [SELECT_ALL_OPTION, ...options] as P[];
    }

    if (renderCreateButton(isCreatable, inputValue, options)) {
      result = [
        ...result,
        { ...CREATE_TAG_OPTION, description: inputValue },
      ] as P[];
    }

    return result;
  }, [showSelectAllOption, options, isCreatable, inputValue]);

  const areAllItemsSelected = useMemo(() => {
    const selectedIds = new Set(
      value?.map(
        (selectedOption) => `${selectedOption.id}${selectedOption.description}`,
      ),
    );

    if (filteredOptions.length === 0) {
      return false;
    }

    return filteredOptions.every((filteredOption) =>
      selectedIds.has(`${filteredOption.id}${filteredOption.description}`),
    );
  }, [filteredOptions, value]);

  const debouncedInputChange = useCallback(
    debounce((value: string) => onInputChange?.(value), 300),
    [onInputChange],
  );

  const handleInputChange = (
    _: React.SyntheticEvent,
    value: string,
    reason: string,
  ) => {
    if (reason === 'input') {
      setInputValue(value);
      debouncedInputChange(value);
    }
  };

  const handleChange = (value: P[], reason: string) => {
    if (reason === 'clear') {
      onInputChange?.('');
      setInputValue('');
    }
    const options = getIdAndDescription(value);
    onChange(options);
  };

  const handleCreateChips = (): void => {
    const newCreatedChips = [
      ...createdChips,
      { id: inputValue, description: inputValue },
    ];
    const newOptions = [...(value ?? []), ...newCreatedChips];

    const uniqueNewOptions = Array.from(
      new Map(
        newOptions.map((option) => [
          `${option.id}-${option.description}`,
          option,
        ]),
      ).values(),
    );

    onChange(uniqueNewOptions);
    setCreatedChips(newOptions);
    setInputValue('');
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      renderCreateButton(isCreatable, inputValue, options)
    ) {
      event.preventDefault();
      handleCreateChips();
      setInputValue('');
    }
  };

  return (
    <Autocomplete
      ref={ref}
      multiple
      loading={loading}
      disableCloseOnSelect
      options={optionsWithButtons}
      value={value ?? []}
      getOptionLabel={(option) => option.description}
      onChange={(_e, value, reason) => handleChange(value, reason)}
      noOptionsText={noOptionsText}
      disabled={disabled}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id && option.description === value.description
      }
      popupIcon={<KeyboardArrowDownRoundedIcon />}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          minHeight: '56px',
        },
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
      }}
      renderOption={(props, option, { selected }) => {
        const { id, description } = option;
        const { ...optionProps } = props;
        const hasDuplicateName = checkHasDuplicateName(options, description);

        if (id === 'create-tag') {
          return (
            <CreateChipButton
              onClick={handleCreateChips}
              show={!!isCreatable}
              buttonText={createButtonText}
              createText={inputValue}
            />
          );
        }
        if (id === 'select-all') {
          return (
            <SelectAll
              key={id}
              options={filteredOptions}
              handleSelectAll={(options) => {
                onInputChange?.('');
                setInputValue('');
                const optionsWithIdDescription = getIdAndDescription(options);
                handleSelectAll(optionsWithIdDescription);
              }}
              handleClearAll={(options) => {
                onInputChange?.('');
                setInputValue('');
                const optionsWithIdDescription = getIdAndDescription(options);
                handleClearAll(optionsWithIdDescription);
              }}
              areAllItemsSelected={areAllItemsSelected}
              selectAllText={common.withValue('dropdowns.select-all', {
                value: filteredOptions.length,
              })}
              clearAllText={common.withValue('dropdowns.clear-all', {
                value: filteredOptions.length,
              })}
            />
          );
        }

        return (
          <MultiSelectFormOption
            key={id + description}
            option={option}
            selected={selected}
            optionProps={optionProps}
            showId={showId || hasDuplicateName}
            itemSlot={itemSlot}
            disabled={option.disabled}
            disabledReason={option.disabledReason}
          />
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          hiddenLabel
          variant="outlined"
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            'data-testid': dataTestId ?? 'multi-select',
            onKeyDown: handleKeyDown,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetching ? (
                  <CircularProgress
                    sx={{ color: 'text.secondary' }}
                    size={18}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderTags={(value: P[]) => {
        /* transforming the value to match the SelectedChips component for now, although this component
               should be refactored to accept the value directly */
        const selectedOptions = value.map(({ id, description }) => ({
          id,
          title: description,
        }));

        return (
          <SelectedChips
            optionsLimit={2}
            selectedOptions={selectedOptions}
            handleClearAll={() => {
              onChange([]);
              setCreatedChips([]);
            }}
            tagTitle={label}
            handleDeleteCallback={(id: string) => {
              const updatedDropdownValue = value.filter(
                (option) => option.id !== id && option.description !== id,
              );
              onChange(updatedDropdownValue);
              setCreatedChips((prevState) =>
                prevState.filter(
                  (option) => option.id !== id && option.description !== id,
                ),
              );
            }}
            optionsLimitLabel={overLimitText}
          />
        );
      }}
      filterOptions={
        onInputChange
          ? (options: P[]) => (loading ? [] : options)
          : (options, { inputValue }) => {
              const filtered = options.filter(
                (option) =>
                  option.id !== SELECT_ALL &&
                  option.description
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()),
              );
              return filtered.length > 1
                ? ([SELECT_ALL_OPTION, ...filtered] as P[])
                : filtered;
            }
      }
      onInputChange={handleInputChange}
      inputValue={inputValue}
    />
  );
};

export default forwardRef(MultiSelect) as <P extends Option>(
  props: Props<P> & { ref?: ForwardedRef<unknown> },
) => ReactElement;

const checkHasDuplicateName = (options: Option[], title: string) => {
  const titleCount: { [key: string]: number } = {};

  options.forEach((option) => {
    titleCount[option.description] = (titleCount[option.description] || 0) + 1;
  });

  return titleCount[title] > 1;
};
