import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { type ModuleName } from '../types';
import { Mixpanel, MODULE_ENTERED } from '../utils/mixpanel';

import useGetUserIdFromToken from './useGetUserIdFromToken';
import useIntercom from './useIntercom';

const useAppSettings = (module: ModuleName) => {
  const { attributes } =
    // @ts-ignore TODO: need to figure out how to type this as store is only present in the apps
    (useSelector((state) => state.user) as {
      attributes: { name: string; email: string; 'custom:customer_id': string };
    }) || {};
  const userId = useGetUserIdFromToken();

  const { REACT_APP_INTERCOM_APP_ID } = process.env;
  const { handleIntercomBoot } = useIntercom(
    REACT_APP_INTERCOM_APP_ID,
    attributes,
  );

  useEffect(() => {
    if (attributes && userId && process.env.NODE_ENV !== 'development') {
      const { name, email } = attributes;
      const customerId = attributes['custom:customer_id'];

      Mixpanel.identify(userId);
      Sentry.setUser({ email, name, userId });

      setTimeout(
        () =>
          window.Fullview?.identify(
            userId, // OR anything unique in your organisation
            {
              name,
              email,
            },
          ),
        2000,
      );

      Mixpanel.track(MODULE_ENTERED, {
        module,
      });
      Mixpanel.people.set({
        $name: name,
        customer_id: customerId,
        $email: email,
      });
      handleIntercomBoot();
    }
  }, [attributes]);

  return {};
};

export default useAppSettings;
