'use client';

import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from './utils';

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Root
    ref={ref}
    className={cn('bg-inherit', className)}
    {...props}
  />
));
Tabs.displayName = TabsPrimitive.Root.displayName;

const tabListVariants = cva(
  'flex items-center justify-center w-fit text-text-subCopy',
  {
    variants: {
      variant: {
        default: 'gap-1 p-1 m-0 rounded-lg border border-grey-20',
        underline: 'rounded-none gap-8',
      },
      size: {
        auto: 'h-full',
        default: 'h-8',
        sm: 'h-6',
        lg: 'h-10',
        xl: 'h-12',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

const tabTriggerVariants = cva(
  'flex items-center w-full justify-center whitespace-nowrap px-3 py-1.5 transition-all disabled:pointer-events-none disabled:opacity-50 ',
  {
    variants: {
      variant: {
        default:
          'rounded-lg bg-paper h-full data-[state=active]:bg-primary-5 data-[state=active]:text-primary data-[state=active]:hover:bg-primary-10 hover:bg-grey-5 data-[state=active]:shadow-sm font-medium',
        underline:
          'h-full relative [&[data-state=active]>span]:opacity-100 font-bold data-[state=active]:text-grey-100',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface TabsListProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>,
    VariantProps<typeof tabListVariants> {}

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  TabsListProps
>(({ className, variant, size, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={tabListVariants({ variant, size, className })}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

export interface TabTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>,
    VariantProps<typeof tabTriggerVariants> {}

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  TabTriggerProps
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={tabTriggerVariants({ variant, className })}
    {...props}
  >
    {props.children}
    {variant === 'underline' && (
      <span className="absolute bottom-0 right-0 left-0 h-0.5 w-full opacity-0 bg-primary-100" />
    )}
  </TabsPrimitive.Trigger>
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
