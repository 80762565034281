import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { TableContext } from '../../table/contexts/TableContext';

// HARRY - can you add some comments here?
const CardGroup = ({ children }) => {
  const { data, cardProps } = useContext(TableContext);
  const { currentPage, itemsPerPage } = cardProps;

  return (
    <>
      {data &&
        data
          .slice(0, currentPage * itemsPerPage)
          .map((dataItem, i) =>
            React.Children.map(children, (child) =>
              React.cloneElement(child, { dataItem, i }),
            ),
          )}
    </>
  );
};

export default CardGroup;

CardGroup.propTypes = {
  children: PropTypes.node,
};
