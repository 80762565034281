import React from 'react';

import useLanguagePreferenceFeatureFlag from '../../hooks/useLanguagePreferenceFeatureFlag';
import { type ModuleName } from '../../types';

import { type LabelDict, type Language } from './LanguageSelectionPopover';

type WrappedComponentProps = {
  isCollapse: boolean;
  labelDict?: LabelDict;
  errorText?: string;
  languages?: Language[];
};

const withLanguagePreferenceFeatureFlag = (
  WrappedComponent: React.ComponentType<WrappedComponentProps>,
  moduleName: ModuleName,
  languages?: Language[],
) => {
  const LanguageSelectionWrapper: React.FC<WrappedComponentProps> = (props) => {
    const { isLanguageFeatureFlagOn } = useLanguagePreferenceFeatureFlag({
      moduleName,
    });

    if (isLanguageFeatureFlagOn) {
      return <WrappedComponent languages={languages} {...props} />;
    }

    return null;
  };

  return LanguageSelectionWrapper;
};

export default withLanguagePreferenceFeatureFlag;
