import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  type SxProps,
  TextField,
  type TextFieldProps,
} from '@mui/material';
import { type CustomTheme, useTheme } from '@mui/material/styles';
import React from 'react';

import LightTooltip from '../../LightTooltip';

const SearchTextField = ({
  value,
  onChange,
  sx,
  placeholder = 'Search...',
  onClear,
  size,
  textFieldSx,
  clearText = 'Clear text',
}: {
  value: string;

  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
  placeholder?: string;
  onClear?: () => void;
  size: TextFieldProps['size'];
  textFieldSx?: SxProps;
  clearText?: string;
}) => {
  const theme = useTheme() as CustomTheme;

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <TextField
        size={size}
        sx={{
          width: '200px',
          ...textFieldSx,
        }}
        placeholder={placeholder}
        value={value || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        style={{
          backgroundColor: 'white',
          borderRadius: `${theme.shape.borderRadius}px`,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  color: 'grey.400',
                  // @ts-ignore
                  fontSize: `${theme.icons?.md}px`,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: value && value.length > 0 && (
            <InputAdornment position="end">
              <LightTooltip title={clearText}>
                <IconButton
                  edge="end"
                  size="small"
                  aria-label="clear-text"
                  onClick={() => onClear && onClear()}
                  color="primary"
                >
                  <ClearIcon />
                </IconButton>
              </LightTooltip>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchTextField;
