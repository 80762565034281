import {
  Popover as MaterialPopover,
  type PopoverProps as MaterialPopoverProps,
} from '@mui/material';
import {
  alpha,
  type CustomTheme,
  experimentalStyled as styled,
  type SxProps,
} from '@mui/material/styles';

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

export default function Popover({
  children,
  anchorEl,
  onClose,
  open,
  sx,
  renderArrow = true,
  ...other
}: MaterialPopoverProps & { renderArrow?: boolean }) {
  return (
    <MaterialPopover
      keepMounted
      sx={
        { '.MuiModal-backdrop': { backgroundColor: 'transparent' } } as SxProps
      }
      anchorEl={anchorEl}
      onClose={onClose}
      disableScrollLock
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          zIndex: 1200,
          overflow: 'inherit',
          boxShadow: (theme: CustomTheme) => theme.customShadows.z20,
          border: (theme: CustomTheme) =>
            `solid 1px ${theme.palette.grey[100]}`,
          width: 'fit-content',
          p: 2,
          mt: 1.5,
          ml: 0.75,
          ...sx,
        } as SxProps,
      }}
      {...other}
    >
      {renderArrow && <ArrowStyle />}
      {children}
    </MaterialPopover>
  );
}
