import { createSlice } from '@reduxjs/toolkit';

type PriceTypeState = {
  subheader: string;
  priceType?: { id: string; title: string };
  priceTypeOptions?: { id: string; title: string }[];
};

const initialState: PriceTypeState = {
  subheader: 'metrics',
  priceType: undefined,
  priceTypeOptions: undefined,
};

export const priceTypeSlice = createSlice({
  name: 'priceType',
  initialState,
  reducers: {
    UPDATE_PRICE_TYPE_OPTIONS: (state, action) => ({
      ...state,
      priceTypeOptions: action.payload,
    }),
    SELECT_PRICE_TYPE: (state, action) => ({
      ...state,
      priceType: action.payload,
    }),
  },
});

export const { UPDATE_PRICE_TYPE_OPTIONS, SELECT_PRICE_TYPE } =
  priceTypeSlice.actions;

export default priceTypeSlice.reducer;
