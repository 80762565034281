import { titleCase } from '@autone/utils';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, Skeleton, Typography } from '@mui/material';
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import SelectionHeader from './SelectionHeader';

const SelectionChips = ({
  title,
  inactiveComponent,
  sx,
  children,
  renderCheckCircle = false,
  selectAllComponent,
}) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    mb: 2,
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    minWidth: 125,
    height: 350,
    backgroundColor: theme.palette.grey[100],
    padding: 15,
    borderRadius: theme.shape.borderRadiusMd,
    ...sx,
  }));

  const theme = useTheme();

  return (
    <>
      <StyledBox>
        {title ? (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SelectionHeader
              header={titleCase(title)}
              sx={{ textAlign: 'right' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h5"
                sx={{
                  marginLeft: 'auto',
                  ...(!renderCheckCircle && {
                    opacity: 0,
                  }),
                }}
              >
                <CheckCircleOutlineOutlinedIcon
                  style={{ fill: theme.palette.success.main }}
                />
              </Typography>
              {selectAllComponent}
            </Box>
          </Box>
        ) : (
          <Skeleton variant="text" height={40} />
        )}

        {inactiveComponent || (
          <Box sx={{ pt: 1, overflowY: 'scroll' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {children}
            </Box>
          </Box>
        )}
      </StyledBox>
    </>
  );
};

export default SelectionChips;

SelectionChips.propTypes = {
  title: PropTypes.string,
  inactiveComponent: PropTypes.node,
  sx: PropTypes.object,
  children: PropTypes.node,
  renderCheckCircle: PropTypes.bool,
  selectAllComponent: PropTypes.node,
};
