import { titleCase } from '@autone/utils';
import { Avatar, Box, Skeleton, Stack, Typography } from '@mui/material';
import {
  type CustomTheme,
  experimentalStyled as styled,
} from '@mui/material/styles';
import PropTypes from 'prop-types';

const AccountStyle = styled('div')(({ theme }) => {
  const customTheme = theme as unknown as CustomTheme;
  return {
    display: 'flex',
    alignItems: 'center',
    padding: customTheme.spacing(2, 2.5),
    margin: customTheme.spacing(1, 2.5, 4),
    borderRadius: customTheme.shape.borderRadiusSm,
    backgroundColor: customTheme.palette.grey[500_12],
    position: 'relative',
  };
});

AccountAvatar.propTypes = {
  userImage: PropTypes.string,
  customerDesc: PropTypes.string,
  name: PropTypes.string,
  username: PropTypes.string,
  isCollapse: PropTypes.bool,
  theme: PropTypes.object,
};

export default function AccountAvatar({
  customerDesc,
  userImage,
  name,
  username,
  isCollapse,
  theme,
}: {
  customerDesc?: string;
  userImage?: string;
  name?: string;
  username?: string;
  isCollapse: boolean;
  theme: CustomTheme;
}) {
  return (
    <>
      {isCollapse ? (
        <Stack
          alignItems="center"
          sx={{ margin: `${theme.spacing(1, 2.5, 4)}` }}
        >
          {customerDesc ? (
            <Avatar
              alt={name?.toUpperCase() || username?.toUpperCase()}
              src={userImage || 'https'}
            />
          ) : (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}
        </Stack>
      ) : (
        <AccountStyle>
          {customerDesc ? (
            <Avatar
              alt={name?.toUpperCase() || username?.toUpperCase()}
              src={userImage || 'https'}
            />
          ) : (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}

          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {titleCase(name || null) || titleCase(username || null) || (
                <Skeleton />
              )}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {customerDesc ? titleCase(customerDesc) : <Skeleton />}
            </Typography>
          </Box>
        </AccountStyle>
      )}
    </>
  );
}
