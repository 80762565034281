export default (
  intercomAppId: string | undefined,
  userAttributes:
    | {
        sub?: string;
        name: string;
        email: string;
        'custom:customer_id': string;
      }
    | undefined,
) => {
  const { sub: user_id, name, email } = userAttributes || {};
  const handleIntercomBoot = () => {
    if (intercomAppId && userAttributes) {
      // @ts-ignore TODO fix this type
      window.Intercom('boot', {
        app_id: intercomAppId,
        name,
        email,
        user_id,
      });
    }
  };

  return { handleIntercomBoot };
};
