import { type PrimitiveAtom } from 'jotai/index';
import { createContext, useContext } from 'react';

import { useSearchField } from '../../../hooks';
import { type OrderType } from '../../../types';
import usePagingTable from '../hooks/usePagingTable';
import { type SortObject, type SortOrder } from '../types';

type PagingTableContextProps = {
  tableProps: ReturnType<typeof usePagingTable>['tableProps'];
  resetPageToZero: () => void;
  searchProps: ReturnType<typeof useSearchField>;
};

const PagingTableContext = createContext<PagingTableContextProps | null>(null);

const PagingTableContextProvider = ({
  initialRowsPerPage,
  initialSortColumn,
  initialSortOrder,
  sortStorageAtom,
  children,
}: {
  initialRowsPerPage?: number;
  initialSortColumn: string;
  initialSortOrder: OrderType;
  sortStorageAtom?: PrimitiveAtom<{
    sortOrder: SortOrder;
    sortColumn: string;
    sortObject: SortObject | null;
  }>;
  children: React.ReactNode;
}) => {
  const { tableProps, resetPageToZero } = usePagingTable({
    initialRowsPerPage,
    initialSortColumn,
    initialSortOrder,
    sortStorageAtom,
  });

  const searchProps = useSearchField(resetPageToZero);

  return (
    <PagingTableContext.Provider
      value={{ tableProps, resetPageToZero, searchProps }}
    >
      {children}
    </PagingTableContext.Provider>
  );
};

export default PagingTableContextProvider;

export const usePagingTableContext = () => {
  const context = useContext(PagingTableContext);
  if (!context) {
    throw new Error(
      'usePagingTableContext must be used within a PagingTableContextProvider',
    );
  }
  return context;
};
