//@ts-nocheck
export const isNotUndefined = <T>(item: T | undefined): item is T =>
  item !== undefined;

export const isNotNull = <T>(item: T | null): item is T => item.id !== null;

export const filterUndefineds = <T>(data: (T | undefined)[]): T[] =>
  data.filter(isNotUndefined);

export const isOfType = <T>(value: unkown, key: string): value is T =>
  key in (value as T);

export const isKeyInType = <T>(value: T, key: unknown): key is keyof T =>
  (key as keyof T) in value;

export const isArrayOfType = <T>(arr: unknown, key: string): arr is T[] =>
  arr.every((item) => typeof item === 'object' && key in item);

export const isArrayOfStrings = (arr: unknown): arr is string[] =>
  Array.isArray(arr) && arr.every((item) => typeof item === 'string');

export const isArrayOfNumbers = (arr: unknown): arr is number[] =>
  Array.isArray(arr) && arr.every((item) => typeof item === 'number');

export const isObjectOfType = <T>(
  object: Record<string, any>,
  key: string,
): object is T => typeof object === 'object' && key in object;

export const isStringOrUndefined = (
  dataItemValue: unknown,
): dataItemValue is string | undefined | null => {
  return typeof dataItemValue === 'string' || !dataItemValue;
};

export const isNumberOrUndefined = (
  dataItemValue: unknown,
): dataItemValue is number | undefined | null => {
  return typeof dataItemValue === 'number' || !dataItemValue;
};

export const isStringOfType = <T>(
  value: string,
  validTypes: T[],
): value is T => {
  return validTypes.includes(value as T);
};
