export const UsFlag = () => (
  <div
    style={{
      borderRadius: '2px',
      overflow: 'hidden',
      width: '23px',
      height: '16px',
    }}
  >
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H9.85714V7.46667H0V0Z"
        fill="#1A47B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85714 0L9.85714 1.06667H23V0H9.85714ZM9.85714 2.13333V3.2H23V2.13333H9.85714ZM9.85714 4.26667V5.33333H23V4.26667H9.85714ZM9.85714 6.4L9.85714 7.46667H23V6.4H9.85714ZM0 8.53333V9.6H23V8.53333H0ZM0 10.6667V11.7333H23V10.6667H0ZM0 12.8V13.8667H23V12.8H0ZM0 14.9333V16H23V14.9333H0Z"
        fill="#F93939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09511 1.06665V2.13331H2.19035V1.06665H1.09511ZM3.28558 1.06665V2.13331H4.38082V1.06665H3.28558ZM5.47606 1.06665V2.13331H6.5713V1.06665H5.47606ZM7.66654 1.06665V2.13331H8.76177V1.06665H7.66654ZM6.5713 2.13331V3.19998H7.66654V2.13331H6.5713ZM4.38082 2.13331V3.19998H5.47606V2.13331H4.38082ZM2.19035 2.13331V3.19998H3.28558V2.13331H2.19035ZM1.09511 3.19998V4.26665H2.19035V3.19998H1.09511ZM3.28558 3.19998V4.26665H4.38082V3.19998H3.28558ZM5.47606 3.19998V4.26665H6.5713V3.19998H5.47606ZM7.66654 3.19998V4.26665H8.76177V3.19998H7.66654ZM1.09511 5.33331V6.39998H2.19035V5.33331H1.09511ZM3.28558 5.33331V6.39998H4.38082V5.33331H3.28558ZM5.47606 5.33331V6.39998H6.5713V5.33331H5.47606ZM7.66654 5.33331V6.39998H8.76177V5.33331H7.66654ZM6.5713 4.26665V5.33331H7.66654V4.26665H6.5713ZM4.38082 4.26665V5.33331H5.47606V4.26665H4.38082ZM2.19035 4.26665V5.33331H3.28558V4.26665H2.19035Z"
        fill="white"
      />
    </svg>
  </div>
);
