import { capitalizeFirstLetter, LEGACY_FILTER_APPS } from '@autone/utils';
import { filter as _filter } from 'lodash';
import PropTypes from 'prop-types';

import FilterChip from '../filter-panel/FilterChip';
import FilterTooltip from '../filter-panel/FilterTooltip';
import Label from '../label/Label';
import LightTooltip from '../LightTooltip';
import Panel from '../panel/Panel';

export default function Selections({
  filters,
  activePage,
  handleRemoval,
  appName,
}) {
  return (
    <>
      {filters &&
        filters[activePage] &&
        filters[activePage].map((filter) => {
          const {
            dimension_id: dimensionId,
            dimension,
            options,
            name,
            title,
          } = filter;
          const activeFilterOptions = options
            ? _filter(options, ['active', true])
            : [];

          const appDimension = LEGACY_FILTER_APPS.includes(appName)
            ? dimension
            : dimensionId;
          const appTitle = LEGACY_FILTER_APPS.includes(appName) ? title : name;

          const numberOfActiveOptions = activeFilterOptions?.length;
          const selectionLimit = 4;
          const optionChipsToRender = activeFilterOptions?.slice(
            0,
            selectionLimit,
          );
          const optionChipsForLabel =
            activeFilterOptions?.slice(selectionLimit);

          return (
            numberOfActiveOptions > 0 && (
              <Panel label={capitalizeFirstLetter(appTitle)} key={appDimension}>
                {optionChipsToRender.map(({ id, title }) => (
                  <FilterChip
                    key={id}
                    id={id}
                    title={title}
                    dimensionId={appDimension}
                    handleRemoval={handleRemoval}
                  />
                ))}
                {numberOfActiveOptions > selectionLimit && (
                  <LightTooltip
                    title={
                      <FilterTooltip
                        filters={optionChipsForLabel}
                        dimensionId={appDimension}
                        handleRemoval={handleRemoval}
                      />
                    }
                  >
                    <div>
                      <Label
                        color="primary"
                        sx={{
                          mr: 0.5,
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        {`+${numberOfActiveOptions - selectionLimit}`}
                      </Label>
                    </div>
                  </LightTooltip>
                )}
              </Panel>
            )
          );
        })}
    </>
  );
}

Selections.propTypes = {
  filters: PropTypes.object,
  activePage: PropTypes.string,
  handleRemoval: PropTypes.func,
  appName: PropTypes.string,
};
