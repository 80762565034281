import { capitalizeFirstLetter } from '@autone/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Divider, Typography } from '@mui/material';
import {
  type CustomTheme,
  experimentalStyled as styled,
} from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import pluralize from 'pluralize';

import { type ScopeWithoutNetwork } from '../../types';
import LightTooltip from '../LightTooltip';

type ScopeText = {
  header: string;
  rulesApplied?: string;
};

const BoxStyled = styled(Box)<{ theme?: CustomTheme }>(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 5,
  paddingBottom: 5,
  width: 'fit-content',
  flexWrap: 'wrap',
  borderRadius: `${theme.shape.borderRadiusXs}px`,
  border: `1 solid black`,
}));

interface ScopeTooltipContentProps {
  scope: ScopeWithoutNetwork[];
  scopeText?: ScopeText;
  getIsOrAre: (options: { optionsCount: number }) => string;
}

export const ScopeTooltipContent = ({
  scope,
  scopeText = {
    header: 'Your scope',
    rulesApplied: 'Rules applied',
  },
  getIsOrAre,
}: ScopeTooltipContentProps) => {
  return (
    <Box
      sx={{
        py: 1,
        maxHeight: '300px',
        overflowY: 'scroll',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', px: 2 }} variant="subtitle1">
        {scopeText.header}
      </Typography>
      <Divider sx={{ fontWeight: 'bold', mb: 1, mt: 2 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          rowGap: '10px',
          px: 2,
        }}
      >
        {scope
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(({ title, options, renderTitleOnly, isRule }) => {
            const isArray = Array.isArray(options);
            const isOrAre = getIsOrAre({
              optionsCount: isArray ? options.length : 1,
            });

            const formattedTitle = pluralize(
              title,
              isArray ? options.length : 1,
            );

            if (!title) {
              return null;
            }

            if (renderTitleOnly) {
              return (
                <BoxStyled key={title}>
                  <Typography
                    sx={{ fontWeight: 'bold', display: 'inline-block' }}
                    variant="body2"
                  >
                    {formattedTitle}
                  </Typography>
                </BoxStyled>
              );
            }

            if (isRule) {
              if (options === false) return null;

              return (
                <BoxStyled key={title}>
                  <Typography
                    sx={{ fontWeight: 'bold', display: 'inline-block' }}
                    variant="body2"
                  >
                    {scopeText.rulesApplied}
                  </Typography>
                  <Typography
                    sx={{ pl: 0.5, display: 'inline-block' }}
                    variant="body2"
                  >
                    {capitalizeFirstLetter(String(title))}
                  </Typography>
                </BoxStyled>
              );
            }

            return (
              <BoxStyled key={title}>
                <Typography
                  sx={{ fontWeight: 'bold', display: 'inline-block' }}
                  variant="body2"
                >
                  {`${formattedTitle} ${isOrAre}`}
                </Typography>
                <Typography
                  sx={{ pl: 0.5, display: 'inline-block' }}
                  variant="body2"
                >
                  {isArray
                    ? options
                        .map((option) => {
                          let label = option.id;
                          if ('description' in option && option.description) {
                            label = option.description;
                          }

                          return capitalizeFirstLetter(label);
                        })
                        .join(', ')
                    : capitalizeFirstLetter(String(options))}
                </Typography>
              </BoxStyled>
            );
          })}
      </Box>
    </Box>
  );
};

const ScopeInfoIcon = ({
  scope,
  scopeText = {
    header: 'Your scope',
    rulesApplied: 'Rules applied:',
  },
  getIsOrAre,
}: {
  scope: ScopeWithoutNetwork[];
  scopeText?: ScopeText;
  getIsOrAre: (options: { optionsCount: number }) => string;
}) => {
  return (
    <LightTooltip
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 400,
          px: 0,
        },
      }}
      title={
        <ScopeTooltipContent
          scope={scope}
          scopeText={scopeText}
          getIsOrAre={getIsOrAre}
        />
      }
      enterNextDelay={250}
    >
      <InfoOutlinedIcon sx={{ fontSize: '14px' }} />
    </LightTooltip>
  );
};

export default ScopeInfoIcon;
