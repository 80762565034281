import { orderBy } from 'lodash';
import { useEffect, useMemo, useReducer, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';

import {
  createListWithSubheaders,
  handleTableColumnReorder,
} from '../../../utils';
import { type TableConfigType } from '../../table/types';
import { customColumnReducer } from '../reducers/customColumnReducer';
import { type Metric } from '../types';

const useCustomColumns = ({
  customColumns,
  columnLimit,
  handleSetCustomColumns,
}: {
  customColumns: TableConfigType<any>[];
  columnLimit?: number;
  handleSetCustomColumns: (columns: TableConfigType<any>[]) => void;
}) => {
  // this state contains the list of selected custom columns after the user has finished editing
  // it is used to update the table columns once the user confirms their changes
  const [draggedCustomColumns, draggedCustomColumnsDispatch] = useReducer(
    customColumnReducer,
    customColumns,
  );

  useEffect(() => {
    if (customColumns)
      draggedCustomColumnsDispatch({
        type: 'load_columns',
        payload: customColumns,
      });
  }, [customColumns]);

  // open and closed state for the modal
  const [open, setOpen] = useState(false);
  // functions for opening and closing the modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // the metrics list is the list of metrics that are available to be added to the table
  // to build this list, we get all available custom columns and sort alphabetically
  const metricsList: Metric[] = useMemo(() => {
    // we add subheaders to the list
    const metricListWithSubHeaders = createListWithSubheaders(
      draggedCustomColumns,
      'columnGroupingHeader',
    );

    // we order the list by the subheader and then by the metric name
    // if subheader is 'other', we want it to be at the bottom of the list
    // we need the checks for undefined here as both columnGroupingHeader & header can be undefined
    return orderBy(
      metricListWithSubHeaders,
      [
        (column) => {
          const groupingHeader = column?.columnGroupingHeader?.toLowerCase();
          return [
            // order by grouping header then the column header names
            groupingHeader,
            column?.header?.toLowerCase(),
          ];
        },
      ],
      ['asc'],
    );
  }, [draggedCustomColumns]);

  const activeColumns = useMemo(
    () =>
      draggedCustomColumns?.filter(
        (column) => column?.active && !column?.locked && !column?.tableOnly,
      ).length,
    [draggedCustomColumns],
  );

  // we disable the ability to add more columns if the number of active columns is equal to the column limit
  const columnsAreDisabled = activeColumns === columnLimit;

  const handleOnDragEndCallback = (result: DropResult) => {
    // function to reorder the array of columns
    const newColumnOrder = handleTableColumnReorder(
      result,
      draggedCustomColumns,
    );

    draggedCustomColumnsDispatch({
      type: 'reorder_columns',
      payload: newColumnOrder,
    });
  };

  const handleConfirmColumnSettings = () => {
    // set the new column config
    handleSetCustomColumns(draggedCustomColumns);
    // close the modal
    handleClose();
  };

  const handleCancelColumnSettings = () => {
    // dispatch original column config
    draggedCustomColumnsDispatch({
      type: 'reorder_columns',
      payload: customColumns,
    });

    // close the modal
    handleClose();
  };

  const handleColumnSelect = (d: TableConfigType<any>) => {
    draggedCustomColumnsDispatch({ type: 'toggle_active', payload: d });
  };

  return {
    metricsList,
    draggedCustomColumns,
    open,
    handleOpen,
    handleClose,
    activeColumns,
    columnsAreDisabled,
    handleOnDragEndCallback,
    handleConfirmColumnSettings,
    handleCancelColumnSettings,
    handleColumnSelect,
  };
};

export default useCustomColumns;
