import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Fade, useTheme } from '@mui/material';

// @ts-ignore - not yet typed
import { IconButtonAnimate } from '../animate';

export default function CollapseButton({
  handleClickToggleCollapse,
  collapseClick,
  showCollapse,
}: {
  handleClickToggleCollapse: () => void;
  collapseClick: boolean;
  showCollapse: boolean;
}) {
  const theme = useTheme();
  return (
    <Fade in={showCollapse}>
      <IconButtonAnimate
        sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          zIndex: 100,
          position: 'fixed',
          transform: 'translateX(-50%)',
          backgroundColor: 'white',
          top: '35px',
          '&:hover': {
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
          },
        }}
        size="tiny"
        onClick={handleClickToggleCollapse}
      >
        <Box
          sx={{
            lineHeight: 0,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(collapseClick && {
              transform: 'rotate(180deg)',
            }),
          }}
        >
          <KeyboardArrowLeftIcon sx={{ fontSize: '20px' }} />
        </Box>
      </IconButtonAnimate>
    </Fade>
  );
}
