import { coreApi, userApi, userReducer } from '@autone/ui';
import { configureStore } from '@reduxjs/toolkit';

//@ts-ignore TODO: type this
import companyUsersReducer from './companyUsers';
//@ts-ignore TODO: type this
import moduleReducer from './modules';
import { budgetApi, hubApi, parametersApi } from './services';

export const createStore = () =>
  configureStore({
    reducer: {
      [hubApi.reducerPath]: hubApi.reducer,
      [budgetApi.reducerPath]: budgetApi.reducer,
      [parametersApi.reducerPath]: parametersApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [coreApi.reducerPath]: coreApi.reducer,
      user: userReducer,
      modules: moduleReducer,
      companyUsers: companyUsersReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(userApi.middleware)
        .concat(parametersApi.middleware)
        .concat(hubApi.middleware)
        .concat(budgetApi.middleware)
        .concat(coreApi.middleware),
  });

export const store = createStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
