import { Box, Hidden, Stack } from '@mui/material';

import CollapseButton from './CollapseButton';
import SidebarLogo from './sidebar/SidebarLogo';

const DashboardLogo = ({
  isCollapse,
  handleClickToggleCollapse,
  collapseClick,
  showCollapse,
  imageURLPrefix = 'https://customer-images.autone.io',
}: {
  isCollapse: boolean;
  handleClickToggleCollapse: () => void;
  collapseClick: boolean;
  showCollapse: boolean;
  imageURLPrefix?: string;
}) => (
  <>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {isCollapse ? (
        <Box
          sx={{
            width: '100%',
            px: 1,
            pt: 4,
            pb: '69px',
          }}
        >
          <SidebarLogo
            sx={{
              height: '28px',
              width: '30.65px',
            }}
            align="center"
            logo={`${imageURLPrefix}/autone-logos/autone-icon-new.svg`}
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            px: 3,
            pt: 4,
            pb: 3,
          }}
        >
          <SidebarLogo
            sx={{
              height: '28px',
              width: '188.84px',
            }}
            align={'center'}
            logo={`${imageURLPrefix}/autone-logos/autone-full-new.svg`}
          />
        </Box>
      )}
      <Hidden lgDown>
        <CollapseButton
          showCollapse={showCollapse}
          handleClickToggleCollapse={handleClickToggleCollapse}
          collapseClick={collapseClick}
        />
      </Hidden>
    </Stack>
  </>
);

export default DashboardLogo;
