import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { type CustomTheme } from '../../theme/theme.d';

export const StyledLoadingDropArea = styled(Stack)(
  ({ theme }: { theme?: CustomTheme }) => ({
    backgroundColor: theme?.palette.primary.lighter,
    borderColor: theme?.palette.primary.main,
    color: theme?.palette.primary.main,
    border: '1px dashed',
    borderRadius: theme?.shape.borderRadius,
    padding: theme?.spacing(3),
    gap: theme?.spacing(3),
    alignItems: 'center',
  }),
);

export const StyledDefaultDropArea = styled(Stack)(
  ({ theme }: { theme?: CustomTheme }) => ({
    backgroundColor: theme?.palette.primary.lighter,
    borderColor: theme?.palette.primary.main,
    color: theme?.palette.primary.main,
    border: '1px dashed',
    borderRadius: theme?.shape.borderRadius,
    padding: theme?.spacing(3),
    gap: theme?.spacing(3),
    alignItems: 'center',
    cursor: 'pointer',
  }),
);

export const StyledSuccessDropArea = styled(Stack)(
  ({ theme }: { theme?: CustomTheme }) => ({
    backgroundColor: theme?.palette.success.lighter,
    color: theme?.palette.success.dark,
    border: '1px dashed',
    borderRadius: theme?.shape.borderRadius,
    padding: theme?.spacing(3),
    gap: theme?.spacing(3),
    alignItems: 'center',
  }),
);

export const StyledErrorDropArea = styled(Stack)(
  ({ theme }: { theme?: CustomTheme }) => ({
    backgroundColor: theme?.palette.error.lighter,
    color: theme?.palette.error.main,
    borderColor: theme?.palette.error.dark,
    border: '1px dashed',
    borderRadius: theme?.shape.borderRadius,
    cursor: 'pointer',
    padding: theme?.spacing(3),
    gap: theme?.spacing(3),
    alignItems: 'center',
  }),
);

export const StyledDropAreaTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  lineHeight: '24px',
  fontSize: theme.typography.h5.fontSize,
  textAlign: 'center',
}));
