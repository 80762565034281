import { Skeleton, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const InputSkeleton = ({ width = 70, height = 35 }) => {
  const theme = useTheme();
  return (
    <Skeleton
      sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
      width={width}
      height={height}
      variant="rectangular"
    />
  );
};

export default InputSkeleton;

InputSkeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
