import { Box, type BoxProps } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

export default function Logo({
  src = 'https://customer-images.autone.io/autone-logos/autone-icon-new.svg',
  height = 40,
  ...other
}: {
  src: string;
  height: number;
  other: BoxProps;
}) {
  return (
    <Box component="img" alt="logo" src={src} height={height} {...other} />
  );
}

Logo.propTypes = {
  src: PropTypes.string,
  height: PropTypes.number,
  other: PropTypes.object,
};
