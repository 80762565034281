import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { ICON } from './config';
import { ListItemStyle } from './style';

const ListItem = forwardRef((props, ref) => (
  <ListItemStyle {...props} ref={ref}>
    {props.children}
  </ListItemStyle>
));

ListItem.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
};

const HorizontalNavItem = forwardRef(
  ({ item, active, open, onMouseEnter, onMouseLeave }, ref) => {
    const { title, href, icon, children, disabled, roles } = item;

    if (children) {
      return (
        <ListItem
          ref={ref}
          open={open}
          activeRoot={active}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          disabled={disabled}
          roles={roles}
        >
          <NavItemContent icon={icon} title={title} children={children} />
        </ListItem>
      );
    }

    return (
      <ListItem
        component={RouterLink}
        to={href}
        activeRoot={active}
        disabled={disabled}
        roles={roles}
      >
        <NavItemContent icon={icon} title={title} children={children} />
      </ListItem>
    );
  },
);

export default HorizontalNavItem;

HorizontalNavItem.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
};

function NavItemContent({ icon, title }) {
  return (
    <>
      {icon && (
        <Box
          component="span"
          sx={{
            mr: 1,
            width: ICON.NAVBAR_ITEM_HORIZONTAL,
            height: ICON.NAVBAR_ITEM_HORIZONTAL,
            '& svg': { width: '100%', height: '100%' },
          }}
        >
          {icon}
        </Box>
      )}

      {title}
    </>
  );
}

NavItemContent.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
};
