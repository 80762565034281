import { forwardRef, type HTMLAttributes } from 'react';

import { cn, TailwindCard, TailwindSkeleton } from '../../tailwind';

export const DatapointKPICard = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <TailwindCard
      className={cn(
        'flex flex-row px-6 py-5 justify-between text-lg',
        className,
      )}
      {...props}
      ref={ref}
    ></TailwindCard>
  );
});

DatapointKPICard.displayName = 'DatapointKPICard';

export const DatapointKPICardTitle = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <h2
      className={cn(
        'text-inherit font-medium leading-none text-grey-80 first-letter:uppercase',
        className,
      )}
      {...props}
      ref={ref}
    >
      {children}
    </h2>
  );
});

DatapointKPICardTitle.displayName = 'DatapointKPICardTitle';

export const DatapointKPICardValue = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <p
      className={cn('text-inherit font-bold leading-none', className)}
      {...props}
      ref={ref}
    >
      {children}
    </p>
  );
});

DatapointKPICardValue.displayName = 'DatapointKPICardValue';

export const DatapointKPICardSkeleton = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <DatapointKPICard
      className={cn('flex flex-row gap-4', className)}
      {...props}
      ref={ref}
    >
      <TailwindSkeleton className="self-stretch basis-0 grow" />
      <TailwindSkeleton className="h-full aspect-square min-h-4" />
    </DatapointKPICard>
  );
});

DatapointKPICardSkeleton.displayName = 'DatapointKPICardSkeleton';
