import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';
import * as z from 'zod';

const FetchBaseQueryErrorSchema = z.object({
  status: z.union([z.string(), z.number()]),
  data: z
    .object({
      error_code: z.string(),
      detail: z.string(),
    })
    .partial()
    .nullable(),
});

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return FetchBaseQueryErrorSchema.safeParse(error).success;
}
