const shape = {
  borderRadiusXs: 4,
  borderRadiusSm: 6,
  borderRadius: 8,
  borderRadiusMd: 16,
  borderRadiusLg: 24,
  borderRadiusXl: 32,
};

export default shape;
