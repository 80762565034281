import PropTypes from 'prop-types';

const ReplenishmentIcon = ({ size = 22, color = '#01172F' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2035 19.6903C24.8833 19.6903 25.4343 20.2413 25.4343 20.9211C25.4343 21.5442 24.9713 22.0591 24.3705 22.1406L24.2035 22.1518H7.79327C7.11353 22.1518 6.5625 21.6008 6.5625 20.9211C6.5625 20.298 7.02552 19.783 7.62626 19.7015L7.79327 19.6903H24.2035Z"
      fill={color}
    />
    <path
      d="M24.2035 24.6153C24.8833 24.6153 25.4343 25.1663 25.4343 25.8461C25.4343 26.4692 24.9713 26.9841 24.3705 27.0656L24.2035 27.0768H7.79327C7.11353 27.0768 6.5625 26.5258 6.5625 25.8461C6.5625 25.223 7.02552 24.708 7.62626 24.6265L7.79327 24.6153H24.2035Z"
      fill={color}
    />
    <path
      d="M24.2035 29.5388C24.8833 29.5388 25.4343 30.0898 25.4343 30.7695C25.4343 31.3926 24.9713 31.9076 24.3705 31.9891L24.2035 32.0003H7.79327C7.11353 32.0003 6.5625 31.4493 6.5625 30.7695C6.5625 30.1464 7.02552 29.6315 7.62626 29.55L7.79327 29.5388H24.2035Z"
      fill={color}
    />
    <path
      d="M24.2035 14.7684C24.8266 14.7684 25.3416 15.2315 25.4231 15.8322L25.4343 15.9992V30.7684C25.4343 31.4482 24.8833 31.9992 24.2035 31.9992C23.5804 31.9992 23.0655 31.5362 22.984 30.9354L22.9728 30.7684V17.23H9.02404V30.7684C9.02404 31.3915 8.56102 31.9065 7.96028 31.988L7.79327 31.9992C7.17018 31.9992 6.65523 31.5362 6.57374 30.9354L6.5625 30.7684V15.9992C6.5625 15.3761 7.02552 14.8612 7.62626 14.7797L7.79327 14.7684H24.2035Z"
      fill={color}
    />
    <path
      d="M19.2824 9.84503C19.9622 9.84503 20.5132 10.3961 20.5132 11.0758C20.5132 11.6989 20.0502 12.2138 19.4494 12.2953L19.2824 12.3066H12.7183C12.0386 12.3066 11.4875 11.7555 11.4875 11.0758C11.4875 10.4527 11.9506 9.93776 12.5513 9.85627L12.7183 9.84503H19.2824Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.633055 8.35971L15.4023 0.154578C15.774 -0.051933 16.226 -0.051933 16.5977 0.154578L31.3669 8.35971C31.7577 8.57678 32 8.98862 32 9.43559V30.7689C32 31.4487 31.449 31.9997 30.7692 31.9997H1.23077C0.551034 31.9997 0 31.4487 0 30.7689V9.43559C0 8.98862 0.242328 8.57678 0.633055 8.35971ZM29.5394 10.1596L16.001 2.63876L2.4625 10.1596V29.5385H29.5394V10.1596Z"
      fill={color}
    />
  </svg>
);

ReplenishmentIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default ReplenishmentIcon;
