import { capitalizeFirstLetter, capitalizeWords } from '@autone/utils';
import { Avatar, Box, Card, Skeleton, Stack, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useSelector } from 'react-redux';

import useMenuPopover from '../../hooks/useMenuPopover';
import MenuPopover from '../popover/Popover';
import ActionsPopover from '../table/components/table-actions-popover/table-row-actions-popover/ActionsPopover';

function stringAvatar(name?: string) {
  if (!name) return {};

  const nameParts = name.split(' ');
  const initials = nameParts.map((part) => part[0]).join('');

  return {
    children: initials,
  };
}

const POPOVER_STYLES = {
  paddingY: '8px',
  paddingX: '12px',
  margin: '0px',
  border: 0,
};

export const UserCardContent = ({
  handlePopoverClose,
  renderContent,
}: {
  handlePopoverClose?: () => void;
  renderContent: ({
    handlePopoverClose,
  }: {
    handlePopoverClose?: () => void;
  }) => React.ReactNode;
}) => {
  return (
    <Stack sx={{ p: 0.5, minWidth: '209px' }}>
      {renderContent && renderContent({ handlePopoverClose })}
    </Stack>
  );
};

const UserCard = ({
  isCollapse,
  settingsPopoverContent,
}: {
  isCollapse: boolean;
  settingsPopoverContent: React.ReactElement<any>;
}) => {
  const { open, anchorEl, handleOpen, handleClose } = useMenuPopover();
  // @ts-ignore  TODO: difficult to type this as the store is coming from the apps not the package
  const { config } = useSelector((state) => state.user);
  const { user_image_url: userImageUrl, profile } = config || {};
  const {
    forename: name,
    surname,
    username,
    email,
    company_title: title,
  } = profile || {};
  const userName = name
    ? `${capitalizeFirstLetter(name)} ${capitalizeFirstLetter(surname)}`
    : capitalizeWords(username);

  const POPOVER_PROPS = {
    anchorOrigin: {
      vertical: 'top' as const,
      horizontal: 'left' as const,
    },
    transformOrigin: {
      vertical: !isCollapse ? 200 : 175,
      horizontal: 0,
    },
    renderArrow: false,
  };

  const userImage = userName ? (
    <Avatar
      variant="rounded"
      alt={userName}
      src={userImageUrl}
      sx={{
        width: 48,
        height: 48,
        fontSize: 'h5.fontSize',
        fontWeight: 700,
        backgroundColor: 'primary.100',
        color: 'primary.main',
      }}
      {...stringAvatar(userName)}
    />
  ) : (
    <Skeleton variant="rounded">
      <Avatar
        sx={{
          width: 48,
          height: 48,
        }}
      />
    </Skeleton>
  );

  if (isCollapse) {
    return (
      <>
        <Card
          component={'button'}
          onClick={handleOpen}
          sx={{
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
            '&:hover': { cursor: 'pointer' },
          }}
        >
          {userImage}
        </Card>
        <MenuPopover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={POPOVER_STYLES}
          {...POPOVER_PROPS}
        >
          {React.cloneElement(settingsPopoverContent, {
            handlePopoverClose: handleClose,
          })}
        </MenuPopover>
      </>
    );
  }

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row" alignItems={'center'} spacing={2}>
        {userImage}
        <Stack sx={{ maxWidth: '105px' }}>
          {userName ? (
            <Typography variant="h5">{userName}</Typography>
          ) : (
            <>
              <Skeleton width="70px" />
              <Skeleton width="90px" />
            </>
          )}
          {email?.length > 0 && (
            <Tooltip
              title={title || email}
              enterDelay={1000}
              enterNextDelay={1000}
            >
              <Typography
                variant="body2"
                color={'grey.600'}
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {title || email}
              </Typography>
            </Tooltip>
          )}
        </Stack>
        <Box sx={{ marginLeft: 'auto !important' }}>
          <ActionsPopover
            popoverProps={POPOVER_PROPS}
            popoverStyles={POPOVER_STYLES}
          >
            {settingsPopoverContent}
          </ActionsPopover>
        </Box>
      </Stack>
    </Card>
  );
};

export default UserCard;
