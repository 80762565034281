import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

const NumericFormatInput = forwardRef(({ onChange, ...other }: any, ref) => (
  <NumericFormat
    {...other}
    getInputRef={ref}
    allowNegative={false}
    onValueChange={(values, sourceInfo) => {
      onChange({
        ...(sourceInfo.event || {}),
        target: {
          ...(sourceInfo.event?.target || {}),
          value: values.value,
          valueAsNumber: values.floatValue,
        },
      });
    }}
    thousandSeparator
  />
));

export default NumericFormatInput;

NumericFormatInput.propTypes = { onChange: PropTypes.func };
