import {
  type GetListUsersResponse,
  type ListUserProfile,
  type User,
} from '@autone/openapi-core';
import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQuery } from '../../utils';

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['User'],
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    return customBaseQuery(args, api, extraOptions, 'v2/core');
  },
  endpoints: (builder) => ({
    // ---------------------------------------------------------
    // getUserConfig
    // this is used later in as an extraReducer in the user store to populate the user config
    // ---------------------------------------------------------
    getUserConfig: builder.query<User, void>({
      query: () => `users/me`,
      transformResponse: (response: User) => response,
      providesTags: ['User'],
    }),
    getUsers: builder.query<ListUserProfile[], void>({
      query: () => `users`,
      transformResponse: (response: GetListUsersResponse) => response.users,
    }),
  }),
});

export const { useGetUserConfigQuery, useGetUsersQuery } = userApi;
