import { AUTONE_DEV, AUTONE_PROD } from '@autone/utils';
import { parseTemplate } from 'url-template';

const extractBatchId = (url: string): string => {
  const regex = /\/batch\/([a-zA-Z0-9-]+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return '';
  }
};

export const getBatchIdFromUrl = () => {
  const url = window.location.href;
  const batchId = extractBatchId(url);
  return {
    batchId,
  };
};

export const encodeUrl = ({
  url,
  variables,
}: {
  url: string;
  variables: Record<string, string>;
}) => {
  const urlTemplate = parseTemplate(url);
  return urlTemplate.expand(variables);
};

export const checkIfFilterIsActiveInUrl = ({
  urlFilters,
  dimensionId,
  optionId,
}: {
  urlFilters?: Record<string, (string | null)[]> | null;
  dimensionId: string;
  optionId: string | null;
}) => {
  if (
    !urlFilters ||
    !Object.keys(urlFilters).some((item) => item === dimensionId)
  )
    return false;
  const filters = urlFilters[dimensionId];
  return filters.some((item) => {
    const [groupingDimensionId] = item?.split('|') || [];
    return groupingDimensionId === optionId;
  });
};

export const getEnvironmentUrl = () => {
  const environment = process.env.REACT_APP_BUILD_STAGE || process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return AUTONE_DEV;
    case 'production':
      return AUTONE_PROD;
    default:
      return AUTONE_PROD;
  }
};
