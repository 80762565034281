import { DEBOUNCE_DELAY } from '@autone/utils';
import { debounce } from 'lodash';
import type React from 'react';
import { useCallback, useState } from 'react';

const useSearchField = (searchCallback = () => {}) => {
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearchInput, setDebouncedSearchInput] = useState('');

  const handleSetDebouncedSearchInput = useCallback(
    debounce((input: string) => setDebouncedSearchInput(input), DEBOUNCE_DELAY),
    [],
  );

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchCallback();
    const input = e.target.value;
    setSearchInput(input);
    handleSetDebouncedSearchInput(input);
  };

  const handleClearSearchInput = () => {
    searchCallback();
    setSearchInput('');
    handleSetDebouncedSearchInput('');
  };

  return {
    debouncedSearchInput,
    searchInput,
    handleSearchInputChange,
    handleClearSearchInput,
  };
};

export default useSearchField;
