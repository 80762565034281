import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

type ColorType = {
  lighter?: string;
  light?: string;
  main: string;
  dark?: string;
  darker?: string;
};
// TODO: tighten up the types when the theme work is completed
type PaletteColor = {
  10?: string;
  20?: string;
  40?: string;
  60?: string;
  80?: string;
  100?: string;
  120?: string;
  300?: string;
  500?: string;
  700?: string;
  900?: string;
} & ColorType;

function createGradient(color1: string, color2: string): string {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const GREEN: ColorType = {
  light: '#E9FCD4',
  main: '#229A16',
};

const PINK: ColorType = {
  light: '#FFEEF0',
  main: '#EE7E97',
};

const ORANGE: ColorType = {
  main: '#F28552',
};

const YELLOW: ColorType = {
  main: '#EFDD69',
};

const PURPLE: PaletteColor = {
  light: '#E9DDF8',
  main: '#A38BF8',
  dark: '#481380',
  100: '#E9DDF8',
  300: '#742dd266',
  500: '#A473E4',
  700: '#742DD2',
  900: '#481380',
};

const TURQUOISE: ColorType = {
  main: '#9bcac8',
};

const NAVY: ColorType = {
  main: '#40608d',
};

const PRIMARY: PaletteColor = {
  lighter: '#E6F4FF',
  light: '#74CAFF',
  main: '#0267FF',
  dark: '#0041A3',
  darker: '#001F4D',
  100: '#E6F4FF',
  300: '#74CAFF',
  500: '#0267FF',
  700: '#0041A3',
  900: '#001F4D',
};

const INFO: PaletteColor = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  100: '#D0F2FF',
  300: '#74CAFF',
  500: '#1890FF',
  700: '#0C53B7',
  900: '#04297A',
};

const SUCCESS: PaletteColor = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  100: '#E9FCD4',
  300: '#AAF27F',
  500: '#54D62C',
  700: '#229A16',
  900: '#08660D',
};
const WARNING: PaletteColor = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  100: '#FFF7CD',
  300: '#FFE16A',
  500: '#FFC107',
  700: '#B78103',
  900: '#7A4F01',
};
const ERROR: PaletteColor = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  10: '#FFEEEF',
  20: '#FFDEDF',
  40: '#FFBCBF',
  60: '#FF9B9E',
  80: '#FF797E',
  100: '#FF585E',
  120: '#D54449',
};

const GREY: { [key: number]: string } = {
  0: '#F9FAFB',
  100: '#EFF2F4',
  200: '#E1E5EA',
  300: '#CCCFD8',
  400: '#A8AEBD',
  500: '#637381',
  600: '#4D576F',
  700: '#2A3349',
  800: '#2D2F31',
  900: '#161C24',
  500_4: alpha('#A8AEBD', 0.04),
  500_12: alpha('#A8AEBD', 0.12),
  500_16: alpha('#A8AEBD', 0.16),
  500_24: alpha('#A8AEBD', 0.24),
  500_32: alpha('#A8AEBD', 0.32),
  500_48: alpha('#A8AEBD', 0.48),
  500_56: alpha('#A8AEBD', 0.56),
  500_80: alpha('#A8AEBD', 0.8),
};

const GRADIENTS: { [key: string]: string | undefined } = {
  primary:
    PRIMARY.light &&
    PRIMARY.main &&
    createGradient(PRIMARY.light, PRIMARY.main),
  info: INFO.light && INFO.main && createGradient(INFO.light, INFO.main),
  success:
    SUCCESS.light &&
    SUCCESS.main &&
    createGradient(SUCCESS.light, SUCCESS.main),
  warning:
    WARNING.light &&
    WARNING.main &&
    createGradient(WARNING.light, WARNING.main),
  error: ERROR.light && ERROR.main && createGradient(ERROR.light, ERROR.main),
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  green: GREEN,
  grey: GREY,
  pink: PINK,
  orange: ORANGE,
  yellow: YELLOW,
  purple: PURPLE,
  turquoise: TURQUOISE,
  navy: NAVY,
  gradients: GRADIENTS,
  divider: GREY[500_32],
  action: {
    hover: alpha(GREY[200], 0.24),
    selected: PRIMARY.lighter && alpha(PRIMARY.lighter, 0.5),
    disabled: GREY[400],
    disabledBackground: GREY[200],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const PRIMARY_NEW = {
  10: '#E6F0FF',
  20: '#CCE1FF',
  40: '#94C2FF',
  60: '#67A4FF',
  80: '#3585FF',
  100: '#0267FF',
  120: '#0044AB',
};

const SECONDARY_NEW = {
  10: '#F3F2EF',
  20: '#E8E6DE',
  40: '#D0CDB8',
  60: '#B9B39D',
  80: '#A19A7D',
  100: '#8A815C',
  120: '#635C3F',
};

const GREY_NEW = {
  5: '#F4F4F5',
  10: '#E9EAEB',
  20: '#D3D5D7',
  40: '#A6AAAF',
  60: '#7A8086',
  80: '#4D555E',
  100: '#212836',
};

const GREEN_NEW = {
  10: '#E8F9F3',
  20: '#D2F2E7',
  40: '#A5E5CE',
  60: '#77D9B6',
  80: '#4ACC9D',
  100: '#1DBF85',
  120: '#13875E',
};

const YELLOW_NEW = {
  10: '#FFF9EA',
  20: '#FFF3D6',
  40: '#FFE7AD',
  60: '#FFDB83',
  80: '#FFCF5A',
  100: '#FFC331',
  120: '#E1A32C',
};

const RED_NEW = {
  10: '#FFEEEF',
  20: '#FFDEDF',
  40: '#FFBCBF',
  60: '#FF9B9E',
  80: '#FF797E',
  100: '#FF585E',
  120: '#D54449',
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: GREY[800], secondary: GREY[500], disabled: GREY[500] },
    background: { paper: '#fff', default: GREY[0], neutral: GREY[100] },
    action: { active: GREY[600], ...COMMON.action },
    new: {
      primary: PRIMARY_NEW,
      secondary: SECONDARY_NEW,
      grey: GREY_NEW,
      green: GREEN_NEW,
      yellow: YELLOW_NEW,
      red: RED_NEW,
      text: {
        primary: GREY_NEW[100],
        subCopy: GREY_NEW[80],
        error: RED_NEW[120],
        disabled: GREY_NEW[40],
        success: GREEN_NEW[100],
        warning: YELLOW_NEW[120],
        info: PRIMARY_NEW[100],
      },
      background: {
        modules: GREY_NEW[5],
        containers: '#FFFFFF',
        success: GREEN_NEW[20],
        error: RED_NEW[20],
        info: PRIMARY_NEW[20],
        warning: YELLOW_NEW[20],
        disabled: GREY_NEW[5],
      },
      button: {
        primary: PRIMARY_NEW[100],
        success: GREEN_NEW[100],
        error: RED_NEW[100],
        disabled: GREY_NEW[80],
      },
      states: {
        hover: GREY_NEW[5],
      },
      divider: alpha('#919EAB', 0.24),
    },
  },
} as const;

export default palette;
