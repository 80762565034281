/* function to check which sidebar item is active,
takes in prop containing a string (e.g buying, reorder, hub)
and checks if it is found in the current pathname
*/
import { HUB_PATH_APP } from '../routes';

export const getIsActive = ({
  href,
  path,
  currentPathname,
}: {
  href?: string;
  path?: string;
  currentPathname?: string;
}) => {
  if (!href) {
    return false;
  }

  // keep the hub icon active when the user is on the budget page
  if (path === HUB_PATH_APP.root && currentPathname?.includes('/hub/budgets')) {
    return true;
  }

  const isActive = path && currentPathname?.startsWith(path);

  return !!isActive;
};
