import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef, type HTMLAttributes } from 'react';

import { cn, TailwindCard, TailwindSkeleton } from '../../tailwind';

export const CellKPICard = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <TailwindCard
    className={cn('p-6 flex flex-col gap-3 w-full h-full', className)}
    {...props}
    ref={ref}
  ></TailwindCard>
));

CellKPICard.displayName = 'CellKPICard';

export const CellKPICardHeader = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>(({ className, ...props }, ref) => (
  <span
    className={cn('text-sm text-text-subCopy whitespace-nowrap', className)}
    {...props}
    ref={ref}
  ></span>
));

CellKPICardHeader.displayName = 'CellKPICardHeader';

export const CellKPICardContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn('flex flex-col gap-2 self-stretch grow', className)}
    {...props}
    ref={ref}
  ></div>
));

CellKPICardContent.displayName = 'CellKPICardContent';

export const CellKPICardValue = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement>
>(({ className, ...props }, ref) => (
  <span
    className={cn(
      'text-xl font-medium leading-none whitespace-nowrap',
      className,
    )}
    {...props}
    ref={ref}
  ></span>
));

CellKPICardValue.displayName = 'CellKPICardValue';

const cellKPICardSubtitleVariants = cva(
  'text-sm font-normal leading-none whitespace-nowrap',
  {
    variants: {
      variant: {
        default: 'text-text-primary',
        red: 'text-text-error',
        green: 'text-text-success',
        yellow: 'text-text-warning',
      },
    },
  },
);

type CellKPICardSubtitleVariantProps = VariantProps<
  typeof cellKPICardSubtitleVariants
>;

export const CellKPICardSubtitle = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement> & CellKPICardSubtitleVariantProps
>(({ className, variant = 'default', ...props }, ref) => (
  <span
    className={cn(cellKPICardSubtitleVariants({ variant }), className)}
    {...props}
    ref={ref}
  ></span>
));

CellKPICardSubtitle.displayName = 'CellKPICardSubtitle';

export const CellKPICardSkeleton = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <CellKPICard className={className} {...props} ref={ref}>
    <TailwindSkeleton className="self-stretch h-3" />
    <CellKPICardContent>
      <TailwindSkeleton className="self-stretch basis-0 grow" />
      <TailwindSkeleton className="self-stretch h-3" />
    </CellKPICardContent>
  </CellKPICard>
));

CellKPICardSkeleton.displayName = 'CellKpiSkeleton';
