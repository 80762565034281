import { AUTONE_NAME, titleCase } from '@autone/utils';
import { Avatar, Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

export default function CreatedBy({ user }: { user: any }) {
  const { forename, name, profile_image_url: profileImageUrl } = user || {};
  const userName = forename || name;

  const theme = useTheme();

  const adjustedUserName = user ? userName : AUTONE_NAME;
  const { REACT_APP_CLOUDFRONT_IMAGE_DOMAIN } = process.env;
  const autoneIconImageURL =
    REACT_APP_CLOUDFRONT_IMAGE_DOMAIN &&
    `https://${REACT_APP_CLOUDFRONT_IMAGE_DOMAIN}/autone-logos/autone-icon-new.svg`;

  const image = user ? profileImageUrl : autoneIconImageURL;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        variant="rounded"
        alt={adjustedUserName?.toUpperCase()}
        src={image || '/'}
        sx={{
          ...(!user && {
            '.MuiAvatar-img': { objectFit: 'contain' },
          }),
          mr: 1,
          // @ts-ignore
          ...theme.avatar.sm,
        }}
      />
      <Box>
        <Box>{user ? titleCase(userName) : AUTONE_NAME}</Box>
      </Box>
    </Box>
  );
}

CreatedBy.propTypes = {
  user: PropTypes.object,
};
