import { AUTONE_LANGUAGE_STORAGE_KEY } from '@autone/utils';
import { changeLanguage as changeI18nLanguage } from 'i18next';
import { useSnackbar } from 'notistack';

const useLanguagePreferencePopover = ({ errorText }: { errorText: string }) => {
  const { enqueueSnackbar } = useSnackbar();

  const currentLanguage =
    localStorage.getItem(AUTONE_LANGUAGE_STORAGE_KEY) || 'en';

  const changeLanguage = (langKey: string) => {
    changeI18nLanguage(langKey, (err) => {
      if (err) {
        return enqueueSnackbar(errorText, {
          variant: 'error',
        });
      }
    });
    localStorage.setItem(AUTONE_LANGUAGE_STORAGE_KEY, langKey);
  };

  return {
    currentLanguage,
    changeLanguage,
  };
};

export default useLanguagePreferencePopover;
