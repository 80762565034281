import {
  type Activity,
  type GetGrossMargin,
  type GetSalesUplift,
  type GetStockOutReduction,
  type GetUnitsProcessed,
  type WatchlistItem,
} from '@autone/openapi-hub';

import { hubApi } from '../apis';

const hubApis = hubApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecentActivity: builder.query<Activity[], void>({
      query: () => `/activity`,
      transformResponse: (response: { activities: Activity[] }) =>
        response.activities,
    }),
    getStockOutReductionKPI: builder.query<GetStockOutReduction, void>({
      query: () => `/highlights/stock-out`,
    }),
    getUnitsProcessedKPI: builder.query<GetUnitsProcessed, void>({
      query: () => `/highlights/units-processed`,
    }),
    getSalesUpliftKPI: builder.query<GetSalesUplift, void>({
      query: () => `/highlights/sales-uplift`,
    }),
    getGrossMarginKPI: builder.query<GetGrossMargin, void>({
      query: () => `/highlights/gross-margin`,
    }),
    getWatchListItems: builder.query<WatchlistItem[], void>({
      query: () => `/watchlist`,
      transformResponse: (response: { items: WatchlistItem[] }) =>
        response.items,
    }),
  }),
});

export const {
  useGetRecentActivityQuery,
  useGetStockOutReductionKPIQuery,
  useGetUnitsProcessedKPIQuery,
  useGetSalesUpliftKPIQuery,
  useGetGrossMarginKPIQuery,
  useGetWatchListItemsQuery,
} = hubApis;
