import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker, useDayPicker } from 'react-day-picker';

import { Button, buttonVariants } from './Button';
import { type CalendarProps } from './Calendar';
import { cn } from './utils';

function MonthYearCaption({ ...props }) {
  const { goToMonth, nextMonth, previousMonth } = useDayPicker();

  return (
    <>
      <Button
        onClick={() => previousMonth && goToMonth(previousMonth)}
        variant="ghost"
        size="icon"
        color="secondary"
      >
        <ChevronLeft />
      </Button>

      <div className="text-base">
        <span className="first-letter:uppercase">{props.children}</span>
      </div>
      <Button
        onClick={() => nextMonth && goToMonth(nextMonth)}
        variant="ghost"
        size="icon"
        color="secondary"
      >
        <ChevronRight />
      </Button>
    </>
  );
}

function DatePickerCalendar({
  className,
  defaultMonth,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      hideNavigation
      className={cn('p-2', className)}
      classNames={{
        months:
          'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 space-x-4',
        month: 'space-y-4',
        month_caption: 'flex justify-between pb-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        month_grid: 'w-full h-[260px] border-collapse space-y-1',
        weekdays: 'flex',
        weekday: 'rounded-md w-8 font-normal text-[0.8rem]',
        week: 'flex w-full mt-2',
        day: cn(
          buttonVariants({
            variant: 'ghost',
            color: 'secondary',
            size: 'icon',
          }),
          'h-8 w-8 font-normal aria-selected:opacity-100',
        ),
        day_button: cn('h-[100%] w-[100%] font-normal'),
        selected: 'bg-primary text-white hover:bg-primary focus:bg-primary',
        today: 'bg-accent',
        outside: 'day-outside aria-selected:bg-accent/50',
        disabled: 'opacity-50',
        range_middle: 'aria-selected:bg-accent',
        hidden: 'invisible',
      }}
      components={{
        CaptionLabel: (props) => (
          <MonthYearCaption {...props}>{props.children}</MonthYearCaption>
        ),
      }}
      hidden={props.hidden}
      defaultMonth={defaultMonth}
      {...props}
    />
  );
}
DatePickerCalendar.displayName = 'Calendar';

export { DatePickerCalendar };
