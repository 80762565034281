import { type ChipProps, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { type FC, type PropsWithChildren } from 'react';

import { CrossIcon } from '../../icons/CrossIcon';
import LightTooltip from '../../LightTooltip';

const FilterChip: FC<PropsWithChildren<Omit<ChipProps, 'children'>>> = ({
  children,
  ...chipProps
}) => {
  const theme = useTheme();
  return (
    <LightTooltip enterDelay={1000} enterNextDelay={1000} title={children}>
      <Chip
        {...chipProps}
        variant="outlined"
        label={children}
        deleteIcon={
          <IconButton size="small">
            <CrossIcon />
          </IconButton>
        }
        sx={{
          p: 1,
          bgcolor: 'new.background.containers',
          color: 'new.text.primary',
          width: 'fit-content',
          maxWidth: '265px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          borderRadius: theme.shape.borderRadius,
          '&, &:hover': {
            '.MuiChip-deleteIcon': {
              color: '#637381',
            },
          },
        }}
      />
    </LightTooltip>
  );
};
export default FilterChip;
