import * as React from 'react';

import { cn } from './utils';

const PageHeader = React.forwardRef<
  HTMLElement,
  React.HTMLAttributes<HTMLElement> & {
    children: React.ReactNode;
  }
>(({ children, className, ...props }, ref) => {
  return (
    <header
      ref={ref}
      className={cn(
        'flex items-center justify-between w-full px-10 py-4 bg-white border-b border-grey-10',
        className,
      )}
      {...props}
    >
      {children}
    </header>
  );
});

const PageHeaderGroup = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-col', className)} {...props} />
));

const PageHeaderTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h1 ref={ref} className={cn('text-xl font-semibold', className)} {...props}>
    {children}
  </h1>
));

const PageHeaderDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-base text-text-subCopy leading-6', className)}
    {...props}
  />
));

const PageHeaderActions = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex items-center gap-2', className)}
    {...props}
  />
));

export {
  PageHeader as TailwindPageHeader,
  PageHeaderTitle as TailwindPageHeaderTitle,
  PageHeaderDescription as TailwindPageHeaderDescription,
  PageHeaderActions as TailwindPageHeaderActions,
  PageHeaderGroup as TailwindPageHeaderGroup,
};
