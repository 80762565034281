import {
  capitalizeFirstLetter,
  fCurrency,
  fISOToReadableNoTime,
  fNumberWithCommas,
} from '@autone/utils';
import {
  type CustomTheme,
  Skeleton,
  type SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { get, isEqualWith, isFunction } from 'lodash';
import { memo } from 'react';

import {
  isNumberOrUndefined,
  isOfType,
  isStringOrUndefined,
} from '../../../types/guards';
import {
  type DataItem,
  type DataKey,
  type FullScreenContainer,
  type TableConfigType,
} from '../types';

import TableCell from './TableCell';

type TableCellsWrapperType<
  TDataItem extends DataItem,
  TTableConfig extends TableConfigType<any>,
  TRestProps extends Record<string, any>,
> = {
  isLoading?: boolean;
  dataItem?: TDataItem;
  tableConfig: TTableConfig;
  align?: 'left' | 'right' | 'center';

  handleButtonClick?: (dataItem: TDataItem) => void;
  handleClickCallback?: (
    dataItem: TDataItem,

    dataKey: DataKey,

    isFullScreen?: boolean,

    fullScreenContainer?: FullScreenContainer,
  ) => void;
  sticky?: 'left' | 'right';
  sx?: SxProps;
  priceType?: string;
  selectedCurrency?: string;
  customCellFormat?: (
    dataItem: TDataItem,
  ) => Record<string, unknown> | undefined;
} & TRestProps;

const TableCellsWrapper = <
  TDataItem extends DataItem,
  TTableConfig extends TableConfigType<any>,
  TRestProps extends Record<string, any>,
>({
  isLoading,
  dataItem,
  tableConfig,
  align = 'left',
  handleButtonClick = () => {},
  handleClickCallback = () => {},
  sticky,
  sx,
  priceType,
  selectedCurrency,
  customCellFormat = () => ({}),
  // we use rest props here to enable any custom prop to be based to the custom component
  ...rest
}: TableCellsWrapperType<TDataItem, TTableConfig, TRestProps>) => {
  const theme = useTheme() as CustomTheme;

  const {
    id,
    type,
    value,
    selectKey,
    selectable,
    loaderComponent,
    customComponent,
    dataKey,
  } = tableConfig;

  const { isFullScreen, fullScreenContainer } = rest || {};

  const buttonClick = async (dataItem: TDataItem) => {
    handleButtonClick(dataItem);
  };

  const handleClick = (dataItem: TDataItem, id: string) => {
    if (selectable === false) return;
    handleClickCallback(dataItem, id, isFullScreen, fullScreenContainer);
  };

  const tableCellProps = {
    sx: {
      ...(dataItem && customCellFormat(dataItem)),
      backgroundColor: 'inherit',
      ...(selectKey && { cursor: 'pointer' }),
      ...sx,
    },
    // TODO - dataKey needed here for insights. Only remove once that app is migrated away from dataKey
    onClick: () => dataItem && handleClick(dataItem, dataKey || String(id)),
    align,
    sticky,
  };

  if (isLoading || !dataItem) {
    return (
      <TableCell {...tableCellProps}>
        {loaderComponent ? (
          loaderComponent({ theme })
        ) : (
          <Skeleton sx={{ transform: 'none' }} height={15} width={80} />
        )}
      </TableCell>
    );
  }

  if (type === 'custom') {
    if (!customComponent) {
      // eslint-disable-next-line prettier/prettier, no-console
      console.error("Custom component is not defined");
      return null;
    }

    return (
      <TableCell {...tableCellProps}>
        {customComponent({
          dataRow: dataItem,
          tableConfig,
          priceType,
          selectedCurrency,
          theme,
          buttonClick,
          // TODO - dataKey needed here for insights. Only remove once that app is migrated away from dataKey
          dataKey,
          ...rest,
        })}
      </TableCell>
    );
  }

  if (isFunction(value)) {
    return (
      <TableCell {...tableCellProps}>
        <Typography>{value({ ...dataItem })}</Typography>
      </TableCell>
    );
  }

  let dataItemValue = null;
  let currentIndex = 0;
  const paths = Array.isArray(value) ? value : [value];

  while (dataItemValue === null) {
    const path = paths[currentIndex];
    dataItemValue = get(dataItem, path, null);
    currentIndex++;

    if (!path || (currentIndex === path.length && dataItemValue === null)) {
      break;
    }
  }

  if (dataItemValue === null) {
    return <TableCell {...tableCellProps}>Not Defined</TableCell>;
  }

  if (type === 'price') {
    const currencyValue =
      priceType && isOfType<DataItem>(dataItemValue, priceType)
        ? dataItemValue[priceType]
        : undefined;

    if (isNumberOrUndefined(currencyValue)) {
      return (
        <TableCell {...tableCellProps}>
          {fCurrency(currencyValue, selectedCurrency)}
        </TableCell>
      );
    }
  }

  if (type === 'text') {
    if (isStringOrUndefined(dataItemValue)) {
      return (
        <TableCell {...tableCellProps}>
          {capitalizeFirstLetter(dataItemValue)}
        </TableCell>
      );
    }
  }

  if (type === 'date' && isStringOrUndefined(dataItemValue)) {
    return (
      <TableCell {...tableCellProps}>
        {fISOToReadableNoTime(dataItemValue)}
      </TableCell>
    );
  }

  if (type === 'quantity' && isNumberOrUndefined(dataItemValue)) {
    return (
      <TableCell {...tableCellProps}>
        {fNumberWithCommas(dataItemValue)}
      </TableCell>
    );
  }

  // eslint-disable-next-line no-console
  console.error(
    `Unsupported type and value combination. type: ${type}, value: ${value}`,
  );
  return null;
};

const arePropsEqual = (
  oldProps: TableCellsWrapperType<
    DataItem,
    TableConfigType<any>,
    Record<string, any>
  >,
  newProps: TableCellsWrapperType<
    DataItem,
    TableConfigType<any>,
    Record<string, any>
  >,
) => {
  return isEqualWith(oldProps, newProps, (property1, property2) =>
    // if `customizer` returns `undefined`, comparisons are handled by the method instead
    // we override the comparison of functions here
    isFunction(property1) && isFunction(property2)
      ? `${property1}` === `${property2}`
      : undefined,
  );
};

const MemoizedTableCellsWrapper = memo(TableCellsWrapper, arePropsEqual);

export default MemoizedTableCellsWrapper as typeof TableCellsWrapper;
