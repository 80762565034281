import { Box, Stack, useTheme } from '@mui/material';
import { forwardRef, type MouseEventHandler } from 'react';

interface DimensionItemProps {
  name: string;
  count: number;
  isActive: boolean;
  handleOnClick: MouseEventHandler<HTMLDivElement>;
}

const DimensionItem = forwardRef<HTMLDivElement | null, DimensionItemProps>(
  ({ name, count, isActive, handleOnClick }, ref) => {
    const theme = useTheme();
    return (
      <Stack
        ref={ref}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={handleOnClick}
        py={1}
        px="14px"
        minHeight={40}
        sx={{
          pointerEvents: isActive ? 'none' : 'initial',
          borderRadius: 1,
          bgcolor: isActive ? 'action.selected' : 'white',
          color: isActive ? 'primary.main' : 'black',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: theme.palette.action.hover,
          },
        }}
      >
        <Box
          sx={{
            fontWeight: isActive ? '500' : '400',
          }}
        >
          {name}
        </Box>
        {count > 0 && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: 0.5,
              width: '24px',
              height: '24px',
              color: 'white',
              bgcolor: 'primary.500',
            }}
          >
            {count}
          </Stack>
        )}
      </Stack>
    );
  },
);

export default DimensionItem;
