// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  // aws_cognito_identity_pool_id:
  //   process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
};

// const awsmobile = {
//   aws_project_region: 'eu-west-1',
//   aws_cognito_region: 'eu-west-1',
//   aws_cognito_identity_pool_id:
//     'eu-west-1:cfb48d16-bed1-4a5f-a073-09b1763b9da2',
//   aws_user_pools_id: 'eu-west-1_ziA6X1ocM',
//   aws_user_pools_web_client_id: '30gnqu0hae8708lqsg98fsoj4p',
//   oauth: {},
// }

export default awsmobile;
