import { uniqueId } from 'lodash';
import { type Reducer } from 'react';

import {
  advancedFilterConditionIsComplete,
  assignUuidToAdvancedFilterConditions,
} from './AdvancedFilters.utilities';
import {
  type AdvancedFiltersInternalState,
  type AdvancedFiltersReducerAction,
} from './types';

export const advancedFiltersReducer: Reducer<
  AdvancedFiltersInternalState,
  AdvancedFiltersReducerAction
> = (state, action) => {
  const { type } = action;

  switch (type) {
    case 'CHANGE_COMBINE_MODE': {
      const { payload } = action;
      return {
        ...state,
        conditionCombineMode: payload.condition,
      };
    }
    case 'ADD_CONDITION': {
      const { payload } = action;
      const rowId = uniqueId();
      return {
        ...state,
        conditions: {
          ...state.conditions,
          [rowId]: { ...payload },
        },
      };
    }
    case 'REMOVE_CONDITION': {
      const { payload } = action;
      const nextConditions = { ...state.conditions };
      delete nextConditions[payload.rowId];
      return {
        ...state,
        conditions: nextConditions,
      };
    }
    case 'PATCH_CONDITION': {
      const { payload } = action;
      const nextPatchedCondition = {
        ...state.conditions[payload.rowId],
        ...payload.condition,
      };
      return {
        ...state,
        conditions: {
          ...state.conditions,
          [payload.rowId]: nextPatchedCondition,
        },
      };
    }
    case 'FLUSH_NEW_ADVANCED_FILTERS': {
      const { payload } = action;
      return {
        conditionCombineMode: payload.conditionCombineMode,
        conditions: assignUuidToAdvancedFilterConditions(
          payload.conditions.filter((condition) =>
            advancedFilterConditionIsComplete(condition),
          ),
        ),
      };
    }
    case 'REMOVE_INCOMPLETE_ROWS': {
      const completeConditions = Object.fromEntries(
        Object.entries(state.conditions).filter(([_, condition]) =>
          advancedFilterConditionIsComplete(condition),
        ),
      );
      return {
        ...state,
        conditions:
          Object.keys(completeConditions).length > 0
            ? completeConditions
            : assignUuidToAdvancedFilterConditions([{}]),
      };
    }
    case 'RESET': {
      return {
        conditionCombineMode: 'and',
        conditions: assignUuidToAdvancedFilterConditions([{}]),
      };
    }
    default:
      return state;
  }
};
