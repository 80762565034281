import PropTypes from 'prop-types';

import SearchTextField from '../table/components/SearchTextField';

const FilterSearch = ({
  searchInput,
  placeholder,
  handleSearchInputChange,
  handleClearSearchInput,
  filterSearchSx,
}) => (
  <SearchTextField
    size="small"
    textFieldSx={{
      width: '100%',
    }}
    sx={{ width: '90%', ml: 1, mt: 0.5, ...filterSearchSx }}
    placeholder={placeholder}
    value={searchInput}
    onChange={handleSearchInputChange}
    onClear={handleClearSearchInput}
  />
);

export default FilterSearch;

FilterSearch.propTypes = {
  searchInput: PropTypes.string,
  placeholder: PropTypes.string,
  handleSearchInputChange: PropTypes.func,
  handleClearSearchInput: PropTypes.func,
  filterSearchSx: PropTypes.object,
};
