import { cva } from 'class-variance-authority';

const inputVariants = cva(
  'flex rounded-md border border-border-base bg-background leading-tight text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-grey-20 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'w-full py-4 pl-3 pr-12',
        inline: 'inline p-1 rounded-lg text-center w-[38px]',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export { inputVariants };
