import { useAutoneTranslation } from '@autone/translations';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

// finds and returns the default currency of the customer
const useDefaultCurrency = () => {
  //@ts-ignore - no store in the package so struggling to type this
  const { config } = useSelector((state) => state.user);
  const { default_currency: defaultCurrency } = config;

  // This is a request from sales to display the platform in dollars during their sales calls
  // The flag enableDollarCurrencyDemo is only enabled for the demo account
  const { lang } = useAutoneTranslation();
  const { enableDollarCurrencyDemo } = useFlags();
  const showCurrencyInDollars = enableDollarCurrencyDemo && lang === 'en-US';
  return { defaultCurrency: showCurrencyInDollars ? 'USD' : defaultCurrency };
};

export default useDefaultCurrency;
