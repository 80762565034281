import { type SxProps } from '@mui/material';
import {
  alpha,
  type CustomTheme,
  experimentalStyled as styled,
  type Theme,
} from '@mui/material/styles';
import { type PaletteOptions } from '@mui/material/styles/createPalette';
import * as React from 'react';

interface StyleProps {
  color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
  variant: 'filled' | 'outlined' | 'ghost' | 'status' | 'contained';
  size: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
  isRound: boolean;
}

type ColorPropType = keyof PaletteOptions;

const RootStyle = styled('span')<{
  styleProps: StyleProps;
}>(({ theme: tempTheme, styleProps }) => {
  const sizes = {
    tiny: 10,
    small: 12,
    medium: 14,
    large: 16,
    xlarge: 18,
  };

  const theme = tempTheme as CustomTheme & Theme;

  const isLight = theme.palette.mode === 'light';
  const { color, variant, size, isRound } = styleProps;
  const styleFilled = (color: ColorPropType) => ({
    color: theme.palette[color].dark,
    backgroundColor: theme.palette[color].lighter,
  });

  const styleContained = (color: ColorPropType) => ({
    color: 'white',
    backgroundColor: theme.palette[color].main,
  });

  const styleOutlined = (color: ColorPropType) => ({
    color: theme.palette[color].main,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette[color].main}`,
  });

  const styleGhost = (color: ColorPropType) => ({
    color: theme.palette[color][isLight ? 'dark' : 'light'],
    backgroundColor: alpha(theme.palette[color].main, 0.16),
  });

  const styleStatus = (color: ColorPropType) => ({
    color: theme.palette[color].main,
    backgroundColor: 'transparent',
  });

  return {
    height: 22,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: isRound ? `50%` : `${theme.shape.borderRadiusSm}px`,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: isRound ? 0 : theme.spacing(0, 1),
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(sizes[size]),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[200],
    fontWeight: theme.typography.fontWeightBold,

    ...(color !== 'default'
      ? {
          ...(variant === 'filled' && { ...styleFilled(color) }),
          ...(variant === 'outlined' && { ...styleOutlined(color) }),
          ...(variant === 'ghost' && { ...styleGhost(color) }),
          ...(variant === 'status' && { ...styleStatus(color) }),
          ...(variant === 'contained' && { ...styleContained(color) }),
        }
      : {
          ...(variant === 'outlined' && {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500_32]}`,
          }),
          ...(variant === 'ghost' && {
            color: isLight
              ? theme.palette.grey[600]
              : theme.palette.common.white,
            backgroundColor: theme.palette.grey[500_24],
          }),
        }),
  };
});

export type LabelProps = {
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
  variant?: 'filled' | 'outlined' | 'ghost' | 'status' | 'contained';
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
  isRound?: boolean;
  sx?: SxProps;
  children: React.ReactNode;
};
export default function Label({
  color = 'default',
  variant = 'filled',
  size = 'medium',
  isRound = false,
  children,
  ...other
}: LabelProps) {
  return (
    <RootStyle styleProps={{ color, variant, size, isRound }} {...other}>
      {children}
    </RootStyle>
  );
}
