import Highcharts from 'highcharts/highstock';
import borderRadius from 'highcharts-border-radius';
import { cloneElement, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { defaultOptions } from '../components/chart/defaultOptions';
import { deepMerge } from '../utils';

// plugin to add border radius just to top of columns
borderRadius(Highcharts);

const useChartOptions = (props) => {
  const {
    type,
    height,
    width,
    labels,
    margin,
    categories,
    series,
    xAxisTitle,
    yAxis,
    legend,
    events,
    zoomType,
    theme,
    plotOptions,
    tooltipComponent,
    labelComponent,
    chartOptions,
    xAxisLabelOptions,
    xAxisOptions,
    colors,
    tooltip,
    isFullScreen,
    dataLabelsFormatterCallback,
    scrollablePlotArea,
  } = props;

  // Object containing dynamic options of our chart
  const optionsObject = {
    chart: {
      type: type || 'scatter',
      ...(margin && { margin }),
      ...(height && { height }),
      ...(width && { width }),
      events: {
        render() {
          const labels = [];
          // if a label component is passed, we render this as a label
          // we clone and pass the tick prop to this element in order to attach the label components to the right place
          if (labelComponent) {
            Highcharts.objectEach(this.xAxis[0].ticks, (tick, i) => {
              labels.push(cloneElement(labelComponent, { tick, i }));
            });
            setCustomLabels(labels);
          }
          return undefined;
        },
        ...events,
      },
      zoomType,
      scrollablePlotArea,
      ...chartOptions,
    },
    xAxis: {
      categories,
      title: { text: xAxisTitle },
      labels: labels || {
        useHTML: true,
        formatter() {
          return `<div style="text-align:center;">${this.value}</div>`;
        },
        ...xAxisLabelOptions,
      },
      ...xAxisOptions,
    },
    yAxis,
    series,
    ...(legend && { legend }),
    plotOptions: {
      ...plotOptions,
      series: {
        ...plotOptions?.series,
        dataLabels: {
          ...plotOptions?.series?.dataLabels,
          enabled: true,
          formatter: function () {
            return dataLabelsFormatterCallback
              ? dataLabelsFormatterCallback(this)
              : undefined;
          },
        },
      },
    },
    ...(colors && { colors }),
    tooltip: tooltip || {
      formatter: (a) => {
        const point = a.chart.hoverPoint;
        // you can't use an arrow function here if you want to access the tooltip data off of 'this'
        // const { point } = this;
        return tooltipComponent
          ? renderToStaticMarkup(cloneElement(tooltipComponent, { point }))
          : undefined;
      },
    },
  };

  // this state will contain React nodes to be rendered as custom labels.
  // e.g. used in reorder to render inputs on the x-axis
  const [customLabels, setCustomLabels] = useState([]);

  // initial state of the chart set to the options object
  // default options contains static options, organised into a different file
  const [options, setOptions] = useState(
    deepMerge(defaultOptions(theme), optionsObject),
  );

  // options object updated when series or categories are changed
  // typically triggered by filters / selections on the chart
  useEffect(() => {
    setOptions(deepMerge(defaultOptions(theme), optionsObject));
  }, [series, categories, isFullScreen]);

  return { options, customLabels };
};

export default useChartOptions;
