import { useAutoneTranslation } from '@autone/translations';
import { forwardRef, type HTMLAttributes } from 'react';

import { SwitchArrowsSvg } from '../../../svgs';
import { Button, type ButtonProps, cn } from '../../../tailwind';

export type FilterPopoverContentProps = HTMLAttributes<HTMLDivElement>;

export const FilterPopoverContent = forwardRef<
  HTMLDivElement,
  FilterPopoverContentProps
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        'flex flex-col w-full h-full bg-white border border-grey-20 rounded-md shadow-sm',
        className,
      )}
      {...props}
      ref={ref}
    ></div>
  );
});

export type FilterPopoverHeaderProps = HTMLAttributes<HTMLDivElement>;

export const FilterPopoverHeader = forwardRef<
  HTMLDivElement,
  FilterPopoverHeaderProps
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        'flex flex-row gap-2 p-4 w-full justify-between items-center border-b border-grey-20 text-base',
        className,
      )}
      {...props}
      ref={ref}
    ></div>
  );
});

export type FilterPopoverHeaderTitleProps = HTMLAttributes<HTMLSpanElement>;

export const FilterPopoverHeaderTitle = forwardRef<
  HTMLSpanElement,
  FilterPopoverHeaderTitleProps
>(({ className, ...props }, ref) => {
  return (
    <span className={cn('font-medium', className)} {...props} ref={ref}></span>
  );
});

export type FilterPopoverFooterProps = HTMLAttributes<HTMLDivElement>;

export const FilterPopoverFooter = forwardRef<
  HTMLDivElement,
  FilterPopoverFooterProps
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn(
        'flex flex-row justify-end gap-2 p-3 border-t border-t-grey-20',
        className,
      )}
      {...props}
      ref={ref}
    ></div>
  );
});

interface FiltersPopoverSwitchFilterViewButtonProps extends ButtonProps {
  filterView: 'general' | 'advanced';
  onFilterViewChange: (filterView: 'general' | 'advanced') => void;
}

export const FiltersPopoverSwitchFilterViewButton = forwardRef<
  HTMLButtonElement,
  FiltersPopoverSwitchFilterViewButtonProps
>(({ filterView, onFilterViewChange, children, ...props }, ref) => {
  const { common } = useAutoneTranslation();

  const handleToggleFilterView = () => {
    onFilterViewChange(filterView === 'general' ? 'advanced' : 'general');
  };

  return (
    <Button
      className="flex flex-row gap-3"
      variant={'ghost'}
      size={'sm'}
      {...props}
      ref={ref}
      onClick={handleToggleFilterView}
    >
      <div className="-mx-1">
        <SwitchArrowsSvg />
      </div>
      {filterView === 'general'
        ? common('advanced-filters.advanced-filters-switch')
        : common('advanced-filters.general-filters-switch')}
      {children}
    </Button>
  );
});
