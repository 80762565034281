import { type User } from '@autone/openapi-core';
import { createSlice } from '@reduxjs/toolkit';

import { userApi } from './services';

export type UserTypes = {
  attributes: {
    'custom:customer_id': string;
    email: string;
    name: string;
    sub: string;
  };
  idToken: string;
  accessToken: string;
  config: User;
};

const initialState = {
  attributes: undefined,
  idToken: undefined,
  accessToken: undefined,
  config: { default_price_type: 'rrp', default_currency: 'EUR' } as User,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    UPDATE_USER: (state, action) => ({
      ...state,
      attributes: action.payload.attributes,
      idToken: action.payload.idToken,
      accessToken: action.payload.accessToken,
    }),
    UPDATE_CONFIG: (state, action) => ({
      ...state,
      config: action.payload,
    }),
    UPDATE_PROFILE: (state, action) => ({
      ...state,
      config: {
        ...state.config,
        profile: action.payload.profile,
      },
    }),
    UPDATE_PROFILE_IMAGE: (state, action) => ({
      ...state,
      config: {
        ...state.config,
        user_image_url: action.payload.profileImageUrl,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUserConfig.matchFulfilled,
      (state, { payload }) => ({ ...state, config: payload }),
    );
  },
});

export const {
  UPDATE_USER,
  UPDATE_CONFIG,
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE,
} = userSlice.actions;

export default userSlice.reducer;
