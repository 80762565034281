import { Tabs } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

const TabsMinimalStyled = styled(Tabs)(({ theme }) => ({
  zIndex: 9,
  paddingLeft: '15px',
  paddingRight: '15px',
  paddingTop: '0px',
  paddingBottom: '0px',
  borderTopLeftRadius: `${theme.shape.borderRadius}px`,
  borderTopRightRadius: `${theme.shape.borderRadius}px`,
  minHeight: '0px',
  width: '100%',
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));

export default TabsMinimalStyled;
