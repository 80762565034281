import type {
  Budget,
  GetBudgetDrilldownRequest,
  GetBudgetDrilldownResponse,
  GetBudgetKpisResponse,
} from '@autone/openapi-budget';
import { queryBuilder } from '@autone/utils';

import { budgetApi } from '../apis';

const budgetApis = budgetApi.injectEndpoints({
  endpoints: (builder) => ({
    getBudgetKPIs: builder.query<GetBudgetKpisResponse, { budgetId: string }>({
      query: ({ budgetId }) => {
        const query = queryBuilder<'GET', '/budgets/{budgetId}/kpis'>(
          'GET',
          '/budgets/{budgetId}/kpis',
        );
        return query({ path: { budgetId } });
      },
    }),
    getBudgetUploads: builder.query<Budget[], { currentDate: string }>({
      query: ({ currentDate }) => {
        const query = queryBuilder<
          'GET',
          '/budgets?current_date={currentDate}'
        >('GET', '/budgets?current_date={currentDate}');
        return query({ path: { currentDate } });
      },
      transformResponse: (res: { budgets: Budget[] }) => res.budgets,
    }),
    getBudgetLines: builder.query<
      GetBudgetDrilldownResponse,
      {
        budgetId: string;
        body: GetBudgetDrilldownRequest;
        filterBudgetLinesOnly?: boolean;
      }
    >({
      query: ({ budgetId, body, filterBudgetLinesOnly }) => {
        const query = queryBuilder<
          'POST',
          '/budgets/{budgetId}/drilldown',
          { filter_budget_lines_only?: boolean },
          GetBudgetDrilldownRequest
        >('POST', '/budgets/{budgetId}/drilldown');
        return query({
          path: { budgetId },
          body,
          query: { filter_budget_lines_only: filterBudgetLinesOnly ?? false },
        });
      },
    }),
  }),
});

export const {
  useGetBudgetKPIsQuery,
  useGetBudgetUploadsQuery,
  useGetBudgetLinesQuery,
} = budgetApis;
