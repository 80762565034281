import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { getTableCells } from '../../table-card/getTableCells';

const CardItem = ({ idx, dataItem, config, cardIdx, ...rest }) => {
  const theme = useTheme();
  const { selectKey, dataKey } = config || {};

  // HARRY - add comments
  const getCells = (dataItem) =>
    getTableCells({
      dataRow: dataItem,
      tableConfig: config,
      selectKey,
      dataKey,
      theme,
      i: cardIdx,
      ...rest,
    });

  return (
    <Box sx={{ marginLeft: idx > 0 ? 2 : 'auto' }}>{getCells(dataItem)}</Box>
  );
};

export default CardItem;

CardItem.propTypes = {
  idx: PropTypes.number,
  dataItem: PropTypes.object,
  config: PropTypes.object,
  sx: PropTypes.object,
  cardIdx: PropTypes.number,
};
