import { type OptionalProps } from '@autone/utils';
import {
  forwardRef,
  type HTMLAttributes,
  type MouseEvent,
  type PropsWithChildren,
  useState,
} from 'react';

import { type ButtonProps, cn, TailwindButton } from '../../../tailwind';

import {
  SavedFiltersList,
  SavedFilterSummary,
} from './SavedFilters.components';
import {
  SavedFiltersContextProvider,
  useSavedFiltersContext,
  useViewedSavedFilterContext,
  ViewedSavedFilterContextProvider,
} from './SavedFilters.context';
import {
  type SavedFiltersContextValue,
  type ViewedSavedFilterContextValue,
} from './types';

interface SavedFiltersViewControlledProps
  extends SavedFiltersContextValue,
    ViewedSavedFilterContextValue,
    PropsWithChildren {}

type SavedFiltersViewUncontrolledProps = OptionalProps<
  SavedFiltersViewControlledProps,
  'viewedSavedFilterId' | 'setViewedSavedFilterId'
>;

export const SavedFiltersView = ({
  viewedSavedFilterId,
  setViewedSavedFilterId,
  ...props
}: SavedFiltersViewControlledProps | SavedFiltersViewUncontrolledProps) => {
  if (
    viewedSavedFilterId === undefined ||
    setViewedSavedFilterId === undefined
  ) {
    return <SavedFiltersViewUncontrolled {...props} />;
  } else {
    return (
      <SavedFiltersViewControlled
        viewedSavedFilterId={viewedSavedFilterId}
        setViewedSavedFilterId={setViewedSavedFilterId}
        {...props}
      />
    );
  }
};

const SavedFiltersViewUncontrolled = (
  props: SavedFiltersViewUncontrolledProps,
) => {
  const [viewedSavedFilterId, setViewedSavedFilterId] = useState<string | null>(
    null,
  );

  return (
    <SavedFiltersViewControlled
      viewedSavedFilterId={viewedSavedFilterId}
      setViewedSavedFilterId={setViewedSavedFilterId}
      {...props}
    />
  );
};

const SavedFiltersViewControlled = ({
  savedFilterTemplates,
  viewedSavedFilterId,
  setViewedSavedFilterId,
  onApplySavedFilter,
  onDeleteSavedFilter,
  onEditSavedFilterName,
  children,
}: SavedFiltersViewControlledProps) => {
  return (
    <SavedFiltersContextProvider
      savedFilterTemplates={savedFilterTemplates}
      onApplySavedFilter={onApplySavedFilter}
      onDeleteSavedFilter={onDeleteSavedFilter}
      onEditSavedFilterName={onEditSavedFilterName}
    >
      <ViewedSavedFilterContextProvider
        viewedSavedFilterId={viewedSavedFilterId}
        setViewedSavedFilterId={setViewedSavedFilterId}
      >
        {children}
      </ViewedSavedFilterContextProvider>
    </SavedFiltersContextProvider>
  );
};

type SavedFiltersContentProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'children'
>;

export const SavedFiltersContent = forwardRef<
  HTMLDivElement,
  SavedFiltersContentProps
>(({ className, ...props }, ref) => {
  const { savedFilterTemplates } = useSavedFiltersContext();
  const { viewedSavedFilterId } = useViewedSavedFilterContext();
  const viewedSavedFilter = savedFilterTemplates.find(
    (filter) => filter.id === viewedSavedFilterId,
  );

  return (
    <div
      className={cn(
        'flex flex-col w-full grow h-full min-h-0 shadow-inner',
        className,
      )}
      {...props}
      ref={ref}
    >
      {viewedSavedFilter === undefined ? (
        <SavedFiltersList savedFilterTemplates={savedFilterTemplates} />
      ) : (
        <SavedFilterSummary savedFilterTemplate={viewedSavedFilter} />
      )}
    </div>
  );
});

export type SavedFilterApplyButtonProps = ButtonProps;

export const SavedFilterApplyButton = forwardRef<
  HTMLButtonElement,
  SavedFilterApplyButtonProps
>(({ className, onClick, ...props }, ref) => {
  const { viewedSavedFilterId } = useViewedSavedFilterContext();
  const { onApplySavedFilter } = useSavedFiltersContext();

  const handleOnClickApplyFilter = (e: MouseEvent<HTMLButtonElement>) => {
    if (viewedSavedFilterId) {
      onApplySavedFilter(viewedSavedFilterId);
    }
    onClick?.(e);
  };

  return (
    <TailwindButton
      onClick={handleOnClickApplyFilter}
      className={className}
      disabled={!viewedSavedFilterId}
      size={'sm'}
      {...props}
      ref={ref}
    ></TailwindButton>
  );
});
