import { createContext, useContext } from 'react';

import {
  type AdvancedFiltersContextValue,
  type AdvancedFiltersOptionsContextValue,
} from './types';

export const AdvancedFiltersContext =
  createContext<AdvancedFiltersContextValue | null>(null);

export const useAdvancedFiltersContext = () => {
  const context = useContext(AdvancedFiltersContext);

  if (!context) {
    throw new Error(
      'useAdvancedFiltersContext must be used within a AdvancedFiltersContextProvider',
    );
  }

  return context;
};

export const AdvancedFiltersOptionsContext =
  createContext<AdvancedFiltersOptionsContextValue | null>(null);

export const useAdvancedFiltersOptionsContext = () => {
  const context = useContext(AdvancedFiltersOptionsContext);

  if (!context) {
    throw new Error(
      'useAdvancedFiltersOptionsContext must be used within a AdvancedFiltersOptionsContextProvider',
    );
  }

  return context;
};
