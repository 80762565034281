// utility for partitioning an array into two arrays based on a condition
// there are more efficient ways to do this, but this is the most readable
export const partitionArray = (arr, condition) => {
  const trueItems = arr.filter((el) => condition(el));
  const falseItems = arr.filter((el) => !condition(el));
  return [trueItems, falseItems];
};

export const filterAndKeepLastN = (array, n) => {
  if (array.length <= n) {
    // Return the original array if it has fewer or equal items to n
    return array;
  }
  // Return the last n items of the filtered array
  return array.filter((item, index) => index >= array.length - n);
};

export const sumArrayExcludingOptionals = (acc, cur) => {
  if (cur) {
    return acc + cur;
  } else {
    return acc;
  }
};
