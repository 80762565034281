import { capitalizeFirstLetter } from '@autone/utils';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import { isOfType } from '../../../types/guards';
import { hideColumnCheck } from '../../../utils';
import Label from '../../label/Label';
import NoResultsMessage from '../../no-results-message/NoResultsMessage';
import SearchTextField from '../../table/components/SearchTextField';
import {
  type Metric,
  type Subheader,
  type TableConfigWithSubheader,
} from '../types';

import MetricItem from './MetricItem';

const AvailableMetrics = ({
  metricsList,
  handleSearchInputChange,
  handleClearSearchInput,
  searchInput,
  columnsAreDisabled,
  handleColumnSelect,
  metricsTitle,
  metricsErrorText,
  searchPlaceholder,
  clearText,
  noResultsMessage,
}: {
  metricsList: Metric[];
  handleSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearSearchInput: () => void;
  searchInput: string;
  columnsAreDisabled: boolean;
  handleColumnSelect: (metricItemData: TableConfigWithSubheader) => void;
  metricsTitle: string;
  metricsErrorText: string;
  searchPlaceholder: string;
  clearText: string;
  noResultsMessage: string;
}) => {
  const theme = useTheme();

  const metricsWithoutSubheaders = metricsList.filter(
    ({ isSubheader }) => !isSubheader,
  ) as TableConfigWithSubheader[];

  const filteredMetricList = metricsWithoutSubheaders.filter(({ header }) =>
    searchInput
      ? (header || '').toLowerCase().includes(searchInput.toLowerCase())
      : true,
  );

  // check if no results are returned when the column list is filtered
  const showNoResults = filteredMetricList.length === 0;

  // we check if the subheader has children by removing the subheaders and checking if there are any remaining grouping headers in the list
  const checkIfSubHeaderHasChildren = (columnGroupingHeader: string) =>
    filteredMetricList
      .filter((d) => !d.isSubheader)
      .some((d) => d.columnGroupingHeader === columnGroupingHeader);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Stack direction="row">
          <Typography variant="h4">
            {capitalizeFirstLetter(metricsTitle)}
          </Typography>
          {columnsAreDisabled && (
            <Typography sx={{ ml: 'auto' }} variant="body1" color="error">
              <Label color="error">
                {capitalizeFirstLetter(metricsErrorText)}
              </Label>
            </Typography>
          )}
        </Stack>
        <SearchTextField
          value={searchInput}
          onChange={handleSearchInputChange}
          onClear={handleClearSearchInput}
          size="small"
          sx={{ mt: 1, width: '100%' }}
          textFieldSx={{ width: '100%', mt: 0 }}
          placeholder={searchPlaceholder}
          clearText={clearText}
        />
      </Box>
      {showNoResults ? (
        <NoResultsMessage message={noResultsMessage} />
      ) : (
        <Box sx={{ width: '100%', overflow: 'scroll' }}>
          {metricsList
            .filter((d) => !hideColumnCheck(d))
            .map((d, i) => {
              const { isSubheader } = d;

              if (
                isSubheader &&
                isOfType<Subheader>(d, 'columnGroupingHeader')
              ) {
                const { columnGroupingHeader } = d;

                const showSubheader =
                  checkIfSubHeaderHasChildren(columnGroupingHeader);

                if (!showSubheader) {
                  return <React.Fragment key={i} />;
                }

                return (
                  <Typography
                    key={i}
                    sx={{ mt: 2, mb: 1, textAlign: 'start' }}
                    variant="body2"
                    color={theme.palette.grey[600]}
                  >
                    {capitalizeFirstLetter(columnGroupingHeader)}
                  </Typography>
                );
              }

              if (isOfType<TableConfigWithSubheader>(d, 'id')) {
                const { header, active } = d;
                const metricIsDisabled = columnsAreDisabled && !active;

                // we don't use filteredMetricList because of the additional logic needed around retaining the sub headers
                const isFiltered = searchInput
                  ? (header || '')
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  : true;

                if (!isFiltered) {
                  return <React.Fragment key={i} />;
                }

                return (
                  <MetricItem
                    key={i}
                    header={header}
                    active={active}
                    disabled={metricIsDisabled}
                    handleColumnSelect={handleColumnSelect}
                    metricItemData={d}
                  />
                );
              }

              return <React.Fragment key={i} />;
            })}
        </Box>
      )}
    </>
  );
};

export default AvailableMetrics;
