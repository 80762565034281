import { fISOToReadable } from '@autone/utils';
import { enGB } from 'date-fns/locale';
import React, { createContext, useContext } from 'react';
import {
  type CalendarDay as CalendarDayType,
  DayPicker,
  type Modifiers,
  useDayPicker,
} from 'react-day-picker';

import { ChevronLeftSvg, ChevronRightSvg } from '../svgs';

import { Button } from './Button';
import { cn } from './utils';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const DayContext = createContext<CalendarDayType | null>(null);
const useDayContext = () => {
  const context = useContext(DayContext);
  if (!context) {
    throw new Error('useDayContext must be used within a DayProvider');
  }
  return context;
};

function CalendarDay({
  day,
  modifiers,
  children,
  calendarDaySlot,
}: {
  day: CalendarDayType;
  modifiers: Modifiers;
  children: React.ReactNode;
  calendarDaySlot: React.ReactNode;
}) {
  const isOutside = modifiers.outside;
  return (
    <DayContext.Provider value={day}>
      {/* width of 14.2857% is 100% / 7 days of the week  */}
      <td className="w-[14.2857%] h-28 border border-grey-10 p-1 align-top">
        <div
          className={`flex justify-start text-xs ${
            isOutside ? `text-grey-20` : ``
          }`}
        >
          {calendarDaySlot === '1' && (
            <span>{fISOToReadable(day.date, 'MMMM')}</span>
          )}
          <span className="ml-auto">{calendarDaySlot}</span>
        </div>
        {children}
      </td>
    </DayContext.Provider>
  );
}

function MonthYearCaption() {
  const { months, goToMonth, nextMonth, previousMonth } = useDayPicker();
  const currentMonth = fISOToReadable(months[0].date, 'MMMM YYYY');
  const [month, year] = currentMonth?.split(' ') || [];

  return (
    <>
      <Button
        onClick={() => previousMonth && goToMonth(previousMonth)}
        variant="ghost"
        size="icon"
        color="secondary"
      >
        <ChevronLeftSvg />
      </Button>

      <div className="text-xl">
        <span className="font-bold first-letter:uppercase">{month}</span> {year}
      </div>
      <Button
        onClick={() => nextMonth && goToMonth(nextMonth)}
        variant="ghost"
        size="icon"
        color="secondary"
      >
        <ChevronRightSvg />
      </Button>
    </>
  );
}

function Calendar({
  classNames,
  showOutsideDays = true,
  locale,
  children,
  onMonthChange,
  ...props
}: CalendarProps & {
  children: React.ReactNode;
  locale?: Locale;
}) {
  const activeLocale = locale || enGB;
  return (
    <DayPicker
      hideNavigation
      locale={activeLocale}
      formatters={{
        formatWeekdayName: (weekday) =>
          weekday.toLocaleDateString(activeLocale.code, {
            weekday: 'short',
          }),
      }}
      showOutsideDays={showOutsideDays}
      onMonthChange={onMonthChange}
      className={cn('p-3')}
      classNames={{
        root: 'w-full border rounded-lg border-grey-10 p-5 w-full',
        month: 'flex items-center flex-col w-full gap-3.5',
        month_grid: 'w-full',
        month_caption: 'flex self-start items-center justify-between w-[250px]',
        weekdays: 'bg-grey-10  text-grey-80 text-xs',
        weekday: 'p-1 first-letter:uppercase',
        ...classNames,
      }}
      components={{
        Day: (props) => (
          <CalendarDay {...props} calendarDaySlot={props.children}>
            {children}
          </CalendarDay>
        ),
        CaptionLabel: (props) => (
          <MonthYearCaption {...props}>{props.children}</MonthYearCaption>
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar, useDayContext };
