import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick';

const Input = ({
  onChange = () => {},
  onClick = () => {},
  onKeyDown = () => {},
  columnNum,
  rowNum,
  value,
  autoFocus,
  style,
  outsideClickCallback,
  disabled,
  placeholder,
}) => {
  const focusRef = useRef();

  useOutsideClick(focusRef, () => {
    if (outsideClickCallback) {
      outsideClickCallback();
    }
  }, []);

  return (
    <input
      style={{ ...style }}
      autoFocus={autoFocus}
      onKeyDown={onKeyDown}
      onClick={onClick}
      onChange={onChange}
      id={`${rowNum}-${columnNum}`}
      ref={focusRef}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default Input;

Input.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  columnNum: PropTypes.number,
  rowNum: PropTypes.number,
  value: PropTypes.any,
  autoFocus: PropTypes.bool,
  style: PropTypes.object,
  outsideClickCallback: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.any,
};
