import { capitalizeFirstLetter } from '@autone/utils';
import {
  Box,
  type CustomTheme,
  type SxProps,
  TextField,
  useTheme,
} from '@mui/material';
import { type CSSProperties, forwardRef } from 'react';
import { type NumericFormatProps } from 'react-number-format';

import LightTooltip from '../../../../LightTooltip';
import NumericFormatInput from '../../../../numeric-format-input/NumericFormatInput';

type Props = {
  id?: string;
  value: number;
  disabled?: boolean;
  disabledText?: string;
  isError?: boolean;
  sx?: SxProps;
  containerSx?: SxProps;
  inputPropsStyle?: CSSProperties;
  numberFormatProps?: {
    isAllowed?: NumericFormatProps['isAllowed'];
  };
  placeholder?: string;
  onBlurCallback?: () => void;
  onFocusCallback?: () => void;
  onKeyDownCallback?: (e: React.KeyboardEvent<Ref>) => void;
  onClick?: (e: React.MouseEvent<Ref>) => void;
} & (
  | { onBlurCallback: () => void; onChange?: (data: number) => void }
  | { onBlurCallback?: () => void; onChange: (data: number) => void }
);
type Ref = HTMLInputElement;

const NumericInput = forwardRef<Ref, Props>(
  (
    {
      id,
      value,
      disabled,
      disabledText,
      isError,
      sx,
      containerSx,
      inputPropsStyle,
      numberFormatProps,
      placeholder,
      onBlurCallback = () => {},
      onFocusCallback = () => {},
      onKeyDownCallback = () => {},
      onClick = () => {},
      onChange,
    },
    ref,
  ) => {
    const theme = useTheme() as CustomTheme;

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: eventValue },
      } = e;
      const targetValue = parseInt(eventValue, 10) || 0;

      if (targetValue === value) return;
      onChange?.(targetValue);
    };

    return (
      <>
        <LightTooltip title={disabled && capitalizeFirstLetter(disabledText)}>
          <Box
            sx={{ marginLeft: 'auto', marginRight: 'auto', ...containerSx }}
            onClick={(e) => e.stopPropagation()}
          >
            <TextField
              id={id}
              sx={{
                ...sx,
              }}
              inputRef={ref}
              error={isError}
              disabled={disabled}
              value={value}
              onChange={handleChange}
              onFocus={() => {
                onFocusCallback();
              }}
              onBlur={() => {
                if (typeof ref !== 'function') {
                  ref?.current?.parentElement?.classList.remove('Mui-focused');
                }
                onBlurCallback();
              }}
              onClick={onClick}
              onKeyDown={onKeyDownCallback}
              placeholder={placeholder}
              InputProps={{
                inputComponent: NumericFormatInput,
                inputProps: {
                  ...numberFormatProps,
                  style: {
                    textAlign: 'center',
                    width: '40px',
                    backgroundColor: 'white',
                    padding: theme.spacing(0.2),
                    borderRadius: `${theme.shape.borderRadius}px`,
                    ...inputPropsStyle,
                  },
                },
              }}
            />
          </Box>
        </LightTooltip>
      </>
    );
  },
);

export default NumericInput;
