import {
  Box,
  Checkbox,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { type TableConfigWithSubheader } from '../types';

const MetricItem = ({
  header,
  active,
  handleColumnSelect,
  metricItemData,
  disabled,
}: {
  header?: string;
  active?: boolean;
  handleColumnSelect: (metricItemData: TableConfigWithSubheader) => void;
  metricItemData: any;
  disabled: boolean;
}) => {
  const overFlowStyles = {
    width: '100%',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  const theme = useTheme();
  return (
    <MenuItem
      sx={{
        py: 0,
        borderRadius: `${theme.shape.borderRadius}px`,
      }}
      disabled={disabled}
      onClick={() => handleColumnSelect(metricItemData)}
      component={Box}
      disableGutters
      dense
    >
      <Stack
        sx={{
          alignItems: 'center',
          flex: 1,
          width: '100%',
          textAlign: 'start',
        }}
        direction="row"
        spacing={0}
      >
        <Checkbox size="small" disabled={disabled} checked={active} />

        <Typography sx={{ ...overFlowStyles, ml: 0.5 }}>{header}</Typography>
      </Stack>
    </MenuItem>
  );
};

export default MetricItem;
