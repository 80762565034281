import { NO_DATA_SUBTITLE, NO_DATA_TITLE } from '@autone/utils';
import { Box, Button, Skeleton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Card from '../../card/Card';
import NoDataMessage from '../../table/components/NoDataMessage';
import { useTableContext } from '../../table/contexts/TableContext';

const CardContainer = ({ children }) => {
  const { data, noResultComponent, sharedStyles, cardProps, fullScreen } =
    useTableContext();

  // destructure card props for load more cards
  const { currentPage, setCurrentPage, itemsPerPage } = cardProps;

  return (
    <Box
      sx={{
        overflow: 'scroll',
        // fixed height to enable scroll container
        height: fullScreen ? '90vh' : '100vh',
        pb: 2,
        ...sharedStyles,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gap: 2.5,
          m: 1,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(3, 1fr)',
            md: 'repeat(4, 1fr)',
            lg: 'repeat(4, 1fr)',
            xl: 'repeat(5, 1fr)',
          },
        }}
      >
        {data
          ? data.length === 0
            ? noResultComponent || (
                <Box gridColumn="span 12">
                  <NoDataMessage
                    title={NO_DATA_TITLE}
                    subtitle={NO_DATA_SUBTITLE}
                  />
                </Box>
              )
            : children
          : Array(8)
              .fill(1)
              .map((_num, index) => (
                <Card key={index}>
                  <Skeleton variant="rectangular" sx={{ paddingTop: '100%' }} />
                  <Stack spacing={2} sx={{ p: 3 }}>
                    <Skeleton variant="text" sx={{ width: 0.5 }} />
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Skeleton variant="text" sx={{ width: 40 }} />
                    </Stack>
                  </Stack>
                </Card>
              ))}
      </Box>
      {data?.length > currentPage * itemsPerPage && (
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
          }}
        >
          <Button
            variant="contained"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CardContainer;

CardContainer.propTypes = {
  children: PropTypes.node,
};
