import { capitalizeFirstLetter } from '@autone/utils';
import { Chip as MaterialChip, Skeleton } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(1),
  listStyleType: 'none',
}));

const Chip = ({
  id,
  index,
  title,
  active,
  //  theme,
  handleClickCallback,
  clickCallbackData,
  chipSx,
}) => (
  <ListItem key={id || index}>
    {title ? (
      <MaterialChip
        label={typeof title === 'string' ? capitalizeFirstLetter(title) : title}
        onClick={() =>
          handleClickCallback(
            clickCallbackData ? { ...clickCallbackData, active } : id,
            active,
          )
        }
        variant={active ? 'outlined' : 'outlined'}
        color={active ? 'primary' : 'default'}
        sx={{
          //  boxShadow: theme.customShadows.z1,
          borderRadius: '6px',
          backgroundColor: !active && 'white',
          ...chipSx,
        }}
        onDelete={
          active
            ? () =>
                handleClickCallback(
                  clickCallbackData ? { ...clickCallbackData, active } : id,
                  active,
                )
            : undefined
        }
      />
    ) : (
      <Skeleton height={50} width={70} />
    )}
  </ListItem>
);

Chip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  active: PropTypes.bool,
  handleClickCallback: PropTypes.func,
  clickCallbackData: PropTypes.object,
  chipSx: PropTypes.object,
};

export default Chip;
