import jwtDecode from 'jwt-decode';

import { type JwtDecode } from '../../types';

// is a user in any of the groups passed into the group list
export const checkAccess = (
  currentUserPermissions: string[],
  groupList: string[],
): boolean => currentUserPermissions.some((group) => groupList.includes(group));

// pass in group list (permissions array) // 'BUYING_ADMIN,', 'REORDER_STANDARD'
export const CanAccess = (token: string, groupList: string[]) => {
  // if there is no token or group list then block permission
  if (!token || !groupList) {
    return false;
  }

  const decodedJWT = jwtDecode<JwtDecode>(token);
  const { permissions } = decodedJWT;

  // if user has no permissions then block permission
  if (!permissions) {
    return false;
  }

  // get current user permissions
  const currentUserPermissions = JSON.parse(permissions);
  return checkAccess(currentUserPermissions, groupList);
};
