import { isAutoneBackendError, isFetchBaseQueryError } from '@autone/utils';
import {
  fetchBaseQuery,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { enqueueSnackbar } from 'notistack';

import {
  type CustomBaseQueryType,
  type QueryReturnValue,
} from '../../types/rtk-query';
import { authRedirectCheckRtkQuery } from '../auth';
import { errorSnackBar } from '../error';

// base url host
const host = `https://${process.env.REACT_APP_BACKEND_ENDPOINT_HOST}`;

// this is our base query for rtk query
// we pass in url and check if the user is authenticated before making an api request
// we also add the snackbar to all requests
export const customBaseQuery: CustomBaseQueryType<unknown> = async (
  args,
  api,
  extraOptions,
  url,
) => {
  const baseUrl = `${host}/${url}`;
  const prepareHeaders = async (headers: Headers) => {
    const { idToken, accessToken } = (await authRedirectCheckRtkQuery()) || {};
    headers.set('x-api-key', `${idToken}`);
    headers.set('x-api-access-key', `${accessToken}`);
    return headers;
  };

  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  });
  // if args is a string we don't need to destructure extra args as it is not passed in
  if (typeof args === 'string') {
    const result = await baseQuery(args, api, extraOptions);

    // if there is an error and we don't want to hide the snackbar, show the snackbar
    if (result.error) {
      errorSnackBar(enqueueSnackbar, result.error);
    }
    return result;
  }

  const { extraArgs } = args;

  const { hideSnackbar, getErrors } = extraArgs || {};
  const result = await baseQuery(args, api, extraOptions);

  // if there is an error and we don't want to hide the snackbar, show the snackbar
  if (!hideSnackbar) {
    dispatchSnackbar(result, getErrors);
  }

  return result;
};

export const dispatchSnackbar = (
  result: QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>,
  getErrors?: () => Record<string, string>,
) => {
  if (
    getErrors &&
    isFetchBaseQueryError(result.error) &&
    isAutoneBackendError(result.error.data)
  ) {
    errorSnackBar(
      enqueueSnackbar,
      result.error,
      getErrors()[result.error.data.error_code],
    );
  } else if (result.error) {
    errorSnackBar(enqueueSnackbar, result.error);
  }
};
