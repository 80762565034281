export const amplifyCookieStorageConfig = {
  domain:
    process.env.NODE_ENV === 'development'
      ? 'localhost'
      : process.env.REACT_APP_BUILD_STAGE === 'development'
        ? 'development.autone.io'
        : 'autone.io',
  path: '/',
  expires: 365,
  sameSite: 'strict' as const,
  secure: process.env.NODE_ENV !== 'development',
};
