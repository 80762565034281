// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          fontSize: theme.typography.body1.fontSize,
          paddingTop: 8,
          paddingBottom: 8,
        },
        sizeSmall: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        head: {
          '& .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon':
            {
              color: theme.palette.text.primary,
            },
          backgroundColor: theme.palette.background.neutral,
          fontSize: theme.typography.body1.fontSize,
          '&:first-of-type': {
            paddingLeft: theme.spacing(2),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(2),
          },
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        body: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(2),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(2),
          },
        },
        footer: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(2),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(2),
          },
          paddingTop: 0,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          marginRight: theme.spacing(1.25),
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2,
        },
      },
    },
  };
}
