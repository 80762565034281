import {
  type CreateParameterUploadResponse,
  type ExportImportedParameterResponse,
  type ExportJobResponse,
  type ExportParameterRequest,
  type ExportParameterResponse,
  type GetParameterUploadsResponse,
  type ImportJobResponse,
  type ImportParameterResponse,
  type ParameterResponse,
  type UploadMetadata,
} from '@autone/openapi-parameters';
import { encodeUrl } from '@autone/ui';
import { kebabCase } from 'lodash';

import { parametersApi } from '../apis';

export interface EnhancedParameterResponse extends ParameterResponse {
  path: string;
}

export const parametersApis = parametersApi.injectEndpoints({
  endpoints: (builder) => ({
    getParameters: builder.query<EnhancedParameterResponse[], void>({
      query: () => `/parameters`,
      transformResponse: (response: { parameters: ParameterResponse[] }) =>
        response.parameters.map((parameter) => ({
          ...parameter,
          path: kebabCase(parameter.name),
        })),
    }),
    createExport: builder.mutation<
      ExportParameterResponse,
      {
        parameterId: string;
        granularityLevel?: string;
        filters: ExportParameterRequest['filters'];
      }
    >({
      query: ({ parameterId, granularityLevel, filters }) => ({
        url: '/v2/parameters/export',
        method: 'POST',
        body: {
          parameter_id: parameterId,
          aggregation_type: granularityLevel ?? null,
          filters,
        },
      }),
    }),
    createUpload: builder.mutation<
      CreateParameterUploadResponse,
      {
        parameterId: string;
        granularityLevel: string;
        uploadMetadata: UploadMetadata;
      }
    >({
      query: ({ parameterId, granularityLevel, uploadMetadata }) => ({
        url: encodeUrl({
          url: `/{parameterId}/upload`,
          variables: { parameterId },
        }),
        method: 'POST',
        body: {
          aggregation_type: granularityLevel,
          upload_metadata: uploadMetadata,
        },
      }),
      invalidatesTags: ['GetUploads'],
    }),
    updateUploadMetadata: builder.mutation<
      Record<string, never>,
      {
        parameterId: string;
        uploadId: string;
        uploadMetadata: UploadMetadata;
      }
    >({
      query: ({ parameterId, uploadId, uploadMetadata }) => ({
        url: encodeUrl({
          url: `/{parameterId}/upload/{uploadId}`,
          variables: { parameterId, uploadId },
        }),
        method: 'PATCH',
        body: {
          upload_metadata: uploadMetadata,
        },
      }),
      invalidatesTags: (_, error) => (error ? [] : ['GetUploads']),
    }),
    createImport: builder.mutation<
      ImportParameterResponse,
      { parameterId: string; uploadId: string }
    >({
      query: ({ parameterId, uploadId }) => ({
        url: encodeUrl({
          url: `/{parameterId}/uploads/{uploadId}/import`,
          variables: { parameterId, uploadId },
        }),
        method: 'POST',
      }),
    }),
    getUploads: builder.query<
      GetParameterUploadsResponse,
      { parameterId: string; granularityLevel: string }
    >({
      providesTags: ['GetUploads'],
      query: ({ parameterId, granularityLevel }) => ({
        url: encodeUrl({
          url: `/{parameterId}/uploads?aggregation_type={granularityLevel}`,
          variables: { parameterId, granularityLevel },
        }),
      }),
    }),
    getExportJob: builder.query<ExportJobResponse, { jobId: string }>({
      query: ({ jobId }) =>
        encodeUrl({
          url: `/parameters/jobs/{jobId}/export`,
          variables: { jobId },
        }),
    }),
    getImportJob: builder.query<ImportJobResponse, { jobId: string }>({
      query: ({ jobId }) =>
        encodeUrl({
          url: `/parameters/jobs/{jobId}/import`,
          variables: { jobId },
        }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { status },
          } = await queryFulfilled;
          if (status === 'completed' || status === 'failed') {
            dispatch(parametersApi.util.invalidateTags(['GetUploads']));
          }
        } catch (_) {
          // No error handling needed
        }
      },
    }),
    getExportImportedParameter: builder.query<
      ExportImportedParameterResponse,
      { parameterId: string; importJobId: string }
    >({
      query: ({ parameterId, importJobId }) =>
        encodeUrl({
          url: `/parameters/{parameterId}/import/{importJobId}/export`,
          variables: { parameterId, importJobId },
        }),
    }),
    deleteUpload: builder.mutation<
      null,
      {
        parameterId: string;
        uploadId: string;
      }
    >({
      query: ({ parameterId, uploadId }) => ({
        url: encodeUrl({
          url: `/{parameterId}/upload/{uploadId}`,
          variables: { parameterId, uploadId },
        }),
        method: 'DELETE',
      }),
      invalidatesTags: ['GetUploads'],
    }),
  }),
});

export const {
  useGetParametersQuery,
  useCreateExportMutation,
  useGetExportJobQuery,
  useCreateUploadMutation,
  useCreateImportMutation,
  useGetImportJobQuery,
  useLazyGetParametersQuery,
  useGetUploadsQuery,
  useLazyGetExportImportedParameterQuery,
  useDeleteUploadMutation,
  useUpdateUploadMetadataMutation,
} = parametersApis;
